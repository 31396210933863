import React, { useEffect, useState, useContext, Fragment } from 'react';
import API from '@sesame/web-api';

import { ILoadState, ILocation, IWarehouse } from 'src/types';

import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import { Spinner } from 'src/components/Spinner';
import { IFormData, fieldNames } from 'src/components/Form';
import ListHeading from 'src/components/ListHeading';
import { BaseObject, IAddListItemProps, IDeleteListItemProps } from 'src/types/BaseObject';
import { useAuth0 } from '@auth0/auth0-react';
import { config } from 'src/config';


interface ItemPageProps<T extends BaseObject<B>, B> {
  itemClass: T;
}

export const ItemPage = <T extends BaseObject<B>, B>({ itemClass }: ItemPageProps<T, B>) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW);
  const [dataList, setDataList] = useState<B[]>([]);
  //    const [showAddForm, setShowAddForm] = useState<boolean>(false);
  const [editData, setEditData] = useState<B | undefined>(undefined);

  const getDataList = () => {
    try {
      getAccessTokenSilently().then((accessToken) => {
        itemClass.get(accessToken)
          .then(async (data) => {
            setDataList(data);

            if (itemClass.loadDependentData) {
              const result = await itemClass.loadDependentData(accessToken)
                .catch(err => {
                  console.log('Error loading dependend data: ' + itemClass.listName);
                });
              console.log('item page loaded dependendent data: ' + itemClass.listName);
            }
            console.log('setting display state to ready');
            setDisplayState(ILoadState.READY);
          })
          .catch((err) => {
            setDisplayState(ILoadState.ERROR);
            Alert(AlertTypes.ERROR, err.message);
          });
      });
    } catch (e) {
      console.log(JSON.stringify(e));
    }

    setDisplayState(ILoadState.LOADING);
  }

  useEffect(() => {
    if (isAuthenticated) {
      getDataList()
    }
  }, [getAccessTokenSilently])

  if (isLoading || displayState == ILoadState.NEW) {
    return <Spinner />
  }

  return <div>
    <itemClass.AddEditContext.Provider value={{ setEditDataFn: setEditData }}>
      <ListHeading listName={itemClass.listName} iconName={itemClass.iconName}>
        <itemClass.AddEditContext.Consumer>
          {({ setEditDataFn }) => {
            return editData == undefined ?
              <button className="uk-button uk-button-default uk-margin-left uk-padding-left-large"
                onClick={() => setEditDataFn(itemClass.fromFormData(itemClass.emptyFormObject()))} >Add</button>
              : <Fragment />
          }}
        </itemClass.AddEditContext.Consumer>
      </ListHeading>
      <div className="uk-grid uk-child-width-1-2@m uk-flex uk-flex-wrap-reverse">
        <div className="uk-panel uk-border-left">
          {displayState == ILoadState.LOADING ?
            <Spinner /> : <Fragment />}
          {displayState == ILoadState.ERROR ?
            <div className="contentError"> Error loading data </div> : <Fragment />}
          {displayState == ILoadState.READY ?
            <ul className="uk-list">
              {dataList?.map(i => <DisplayListItem
                itemClass={itemClass}
                item={i}
                updateFn={getDataList}
                key={itemClass.primaryId(i)} />)}
            </ul> : <Fragment />
          }
        </div>
        <div className="uk-panel">
          <AddListItem
            itemClass={itemClass}
            item={editData}
            closeFn={() => setEditData(undefined)}
            updateFn={getDataList} />
        </div>
      </div>
    </itemClass.AddEditContext.Provider>
  </div>
}

function DisplayListItem<T extends BaseObject<B>, B>({ itemClass, item, updateFn }: { itemClass: T, item: B, updateFn: () => void }) {

  const { getAccessTokenSilently } = useAuth0();

  return <li key={itemClass.primaryId(item)}>
    <div className="uk-visible-toggle" tabIndex={-1} >
      <div className="uk-grid uk-card uk-card-hover">
        <div className="uk-width-expand">
          {itemClass.renderListItem(item)}
        </div>
        <div className="uk-width-auto">
          <itemClass.AddEditContext.Consumer>
            {({ setEditDataFn }) => (
              <ul className="uk-hidden-hover uk-iconnav">
                <li>
                  <a href="#" uk-icon="icon: pencil"
                    onClick={() => { setEditDataFn(item) }}>
                  </a>
                </li>
                <li>
                  <DeleteListItem itemClass={itemClass} item={item} setEditDataFn={setEditDataFn} updateFn={updateFn} />
                </li>
              </ul>
            )}
          </itemClass.AddEditContext.Consumer>
        </div>
      </div>
    </div>
  </li>
}


function DeleteListItem<T extends BaseObject<B>, B>({ itemClass, item, setEditDataFn, updateFn }: IDeleteListItemProps<T, B>) {

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const doDelete = (itemClass: T, item: B, updateFn: () => void) => {
    getAccessTokenSilently()
      .then(accessToken => {
        deleteItem(accessToken, itemClass, item, updateFn)
        setEditDataFn(undefined);
      })
  }

  if (isLoading) { return <Fragment>"..."</Fragment> }
  if (!isAuthenticated) { return <Fragment /> }
  return <a uk-icon="icon: trash"
    onClick={() => { setEditDataFn(undefined); doDelete(itemClass, item, updateFn) }}></a>
}

function AddListItem<T extends BaseObject<B>, B>({ itemClass, item, closeFn, updateFn }: IAddListItemProps<T, B>) {

  if (itemClass.AddEditForm) {
    return <itemClass.AddEditForm
      item={item}
      closeFn={closeFn}
      updateFn={updateFn}
    />
  }
  return <AddDefaultListItem
      itemClass={itemClass}
      item={item}
      closeFn={closeFn}
      updateFn={updateFn}
      />
}

function AddDefaultListItem<T extends BaseObject<B>, B>({ itemClass, item, closeFn, updateFn }: IAddListItemProps<T, B>) {
  const { getAccessTokenSilently } = useAuth0();
  const [formData, setFormData] = useState<IFormData<B>>(
    itemClass.emptyFormObject()
  );
  useEffect(() => {
    if (item && itemClass.primaryId(item)) {
      setFormData(itemClass.toFormData(item));
    } else {
      setFormData(itemClass.emptyFormObject());
    }
    setValidation([]);
  }, [item]);

  const [validation, setValidation] = useState<fieldNames<B>[]>([]);

  const formChange = (fieldName: fieldNames<B> & string,
    event: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const newFormData: IFormData<B> = { ...formData };
    if (event.currentTarget.type == 'checkbox') {
      newFormData[fieldName] = newFormData[fieldName] == 'true' ? '' : 'true';
    } else {
      newFormData[fieldName] = event.currentTarget.value;
    }
    setFormData(newFormData);
  }

  const validate = () => {
    const valid: fieldNames<B>[] = [];
    const reqFields: fieldNames<B>[] = itemClass.requiredFields;
    reqFields.forEach(d => { if (formData[d].trim() == '') { valid.push(d) } });

    setValidation(valid);
    return valid.length;
  }

  const saveItem = async (event: React.FormEvent<HTMLElement>, close: boolean = true) => {
    event.preventDefault();

    if (validate() > 0) {
      return;
    }
    const itemSave: B = itemClass.fromFormData(formData);

    let APIcall = API.post;

    if (itemSave[itemClass.primaryIdField as keyof B] as number > 0) {
      APIcall = API.patch;
      /* TODO:  Fix type so we don't have this extra cast */
      //            itemSave[itemClass.primaryIdField as keyof B] = item[itemClass.primaryIdField as keyof B];
    }
    getAccessTokenSilently().then(accessToken => {
      APIcall(getAccessTokenSilently(), itemClass.getURL(), itemSave as Object)
        .then(result => {
          updateFn();
          itemClass.setLoadState(ILoadState.NEW)
          itemClass.get(accessToken);
          if (close) {
            setFormData(itemClass.emptyFormObject());
            closeFn();
          } else {
            // get the update back from the server?
            if (itemSave[itemClass.primaryIdField as keyof B] == 0) {
              itemSave[itemClass.primaryIdField as keyof B] = result.data.partCategoryId;
              setFormData(itemClass.toFormData(itemSave));
            };
          }
        })
        .catch((err) => {
          Alert(AlertTypes.ERROR, err.message);
          return;
        })
    })

  }

  if (!item) {
    return <Fragment />
  }
  return <div>
    <div className="uk-align-right uk-dark uk-background-muted">
      <button
        className="uk-button uk-button-muted uk-button-small"
        onClick={() => { closeFn() }}
        data-uk-icon="close">
      </button>
    </div>
    <br />
    <div className="uk-card uk-card-default uk-padding-small">
      <form>
        {itemClass.renderForm(formData, formChange, validation, setValidation)}
        <div >
          <button
            className="uk-button uk-button-primary uk-button-small"
            onClick={(e) => saveItem(e, true)}>
            Save {itemClass.renderAdditional ? "and Close" : ""}
          </button>
          {itemClass.renderAdditional ?
            <button
              className="uk-button uk-button-secondary uk-button-small uk-margin-left"
              onClick={(e) => saveItem(e, false)}>
              Save
            </button>
            : <Fragment />
          }
        </div>
        {itemClass.renderAdditional ?
          itemClass.renderAdditional(formData, formChange, validation, setValidation)
          : <Fragment />}
      </form>
    </div>
  </div>
}

function deleteItem<T extends BaseObject<B>, B>(accessToken: string, itemClass: T, item: B, updateFn: () => void) {

  const id = itemClass.primaryId(item)
  API.delete(accessToken, itemClass.getURL() + `/${id}`)
    .then(result => {
      itemClass.setLoadState(ILoadState.NEW);
      itemClass.get(accessToken);
      updateFn();
    })
    .catch(err => {
      Alert(AlertTypes.ERROR, err.message);
    })
}