import React, { Fragment, useEffect, useState } from "react";
import { FormInput, FormSelect, IFormData, fieldNames } from "src/components/Form";
import { SectionIconName } from "src/components/SectionIcon";
import { BaseObject, IAddEditContext, IAddListItemOverrideProps,
  ILoadState, IManufacturer, IPart, IPartAttr,
  IPartAttributeCategoryDef, IPartAttributeDef, IPartCategory, _get } from "src/types";
import API from '@sesame/web-api';
import Alert, { AlertTypes } from "src/components/AlertDisplay";
import { ItemPage } from "src/pages/ItemPage";
import { Manufacturers } from "../Manufacturers/Manufacturers";
import { PartCategories } from "../PartCategories/PartCategories";
import PromiseText from "src/components/PromiseText";
import { useAuth0 } from "@auth0/auth0-react";


let p: Parts;

export const PartPage = () => {
  p = Parts.Instance();

  return <ItemPage<Parts, IPart> itemClass={p} />
}


export class Parts implements BaseObject<IPart> {

  private static _instance: Parts;
  private static _parts: IPart[];
  private static _loadStatus: ILoadState = ILoadState.NEW;

  private constructor() { };

  static Instance() {
    if (!Parts._instance) {
      Parts._instance = new Parts();
    }
    return Parts._instance;
  }

  setLoadState = (newState: ILoadState) => { Parts._loadStatus = newState }
  getLoadState = () => Parts._loadStatus;
  onDataLoad = (data: IPart[]) => { Parts._parts = Parts.Instance().sortFn(data) };

  public get = async (accessToken: string | Promise<string>) => {
    await _get(accessToken, Parts.Instance());
    return Parts._parts;
  }

  public getItem = async (accessToken: string | Promise<string>, partId: number) => {
    const token = await Promise.resolve(accessToken);
    const parts = await Parts.Instance().get(token);
    const part = parts.find(p => p.partId == partId);
    return part;
  }

  partCategories: Map<number, IPartCategory> = new Map<number, IPartCategory>;
  manufacturerList: IManufacturer[] = [];

  loadDependentData = async (accessToken: string) => {
    if (this.partCategories.keys.length && this.manufacturerList.length) {
      return;
    }
    const pCatData = await PartCategories.Instance().get(accessToken);
    pCatData.forEach((pc: IPartCategory) => { this.partCategories.set(pc.partCategoryId, pc) });

    this.manufacturerList = await Manufacturers.Instance().get(accessToken);
  }

  getURL = () => '/api/v1/part';
  emptyFormObject = () => {
    return {
      partId: '',
      name: '',
      partNumber: '',
      partCategoryId: '',
      serialized: 'False',
      revisionNumber: '',
      description: '',
      manufacturerPartNumber: '',
      attributes: '',
    }
  }
  primaryId = (part: IPart) => part.partId;
  renderListItem = (p: IPart) => {
    // TODO: Fix for locaiton/warehouse
    return <Fragment>
      {p.partNumber} {p.revisionNumber ? ` : ${p.revisionNumber}` : ``}
      <br />
      <span className="uk-text-small uk-text-muted"> {p.name} [{this.partCategories.get(p.partCategoryId)?.name}] {p.description} </span>
    </Fragment>
  }
  renderForm = (formData: IFormData<IPart>,
    formChange: any,
    validation: fieldNames<IPart>[],
    setValidation: any) => {

    return <Fragment>
      <FormInput
        label="Part Number"
        name="partNumber"
        data={formData}
        changeFn={formChange}
        validation={validation}
      />
      <FormInput
        label="Revision Number"
        name="revisionNumber"
        data={formData}
        changeFn={formChange}
        validation={validation}
      />
      <FormSelect
        label="Category"
        name="partCategoryId"
        data={formData}
        blankChoice="(Select Category)"
        displayFn={() => Array.from(this.partCategories.values()).map(p => {
          return {
            value: `${p.partCategoryId}`, key: `${p.partCategoryId}`,
            text: `${p.name}`
          }
        })}
        changeFn={formChange}
        validation={validation} />
      <FormInput
        label="Name"
        name="name"
        data={formData}
        changeFn={formChange}
        validation={validation}
      />
      <FormSelect
        label="Manufacturer (optional)"
        name="manufacturerId"
        blankChoice="(Select Manufacturer)"
        data={formData}
        displayFn={() => this.manufacturerList.map(m => {
          return {
            value: `${m.manufacturerId}`, key: `${m.manufacturerId}`,
            text: `${m.name}`
          }
        })}
        changeFn={formChange}
        validation={validation}
      />
      <FormInput
        label="Mfg. Part Number"
        name="manufacturerPartNumber"
        data={formData}
        changeFn={formChange}
        validation={validation}
      />
      <FormSelect
        label="Serialized"
        name="serialized"
        data={formData}
        displayFn={() => ["False", "True"].map(s => {
          return {
            value: `${s}`, key: `${s}`,
            text: s
          }
        })}
        changeFn={formChange}
        validation={validation}
      />
      <FormInput
        label="Description"
        name="description"
        data={formData}
        changeFn={formChange}
        validation={validation}
      />
    </Fragment>
  };

  sortFn = (d: IPart[]) => {
    return d.sort((a, b) => {
      if (a.partNumber < b.partNumber) { return -1 }
      if (a.partNumber > b.partNumber) { return 1 }
      if (a.revisionNumber < b.revisionNumber) { return -1 }
      return 1;
    })
  }

  toFormData = (p: IPart) => {
    return {
      partId: `${p.partId}`,
      name: p.name,
      partNumber: p.partNumber,
      revisionNumber: p.revisionNumber,
      partCategoryId: `${p.partCategoryId}`,
      serialized: p.serialized ? 'True' : 'False',
      description: p.description,
      manufacturerId: `${p.manufacturerId}`,
      manufacturerPartNumber: p.manufacturerPartNumber,
      attributes: '',
    }
  }

  fromFormData = (p: IFormData<IPart>) => {
    const part: IPart = {
      partId: +p.partId,
      name: p.name,
      partNumber: p.partNumber,
      revisionNumber: p.revisionNumber,
      partCategoryId: +p.partCategoryId,
      description: p.description,
      manufacturerId: p.manufacturerId ? +p.manufacturerId : undefined,
      manufacturerPartNumber: p.manufacturerPartNumber,
      serialized: p.serialized == "True",
      attributes: [],
    }
    return part;
  }

  AddEditForm = ({ item, closeFn, updateFn }: IAddListItemOverrideProps<IPart>) => {

    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [formData, setFormData] = useState<IFormData<IPart>>(this.emptyFormObject());
    const [catAttrDefs, setCatAttrDefs] = useState<IPartAttributeCategoryDef[]>([]);
    const [attrFormData, setAttrFormData] = useState<IFormData<IPartAttr>[]>([]);

    const getCategoryAttributes = (partCategoryId?: number) => {
      if (!partCategoryId) {
        setCatAttrDefs([]);
        return;
      }
      getAccessTokenSilently().then(accessToken => {

        API.get(accessToken, `/api/v1/partAttrDef?category=${partCategoryId}`)
          .then(result => {
            const newAttrDefs = result.data as IPartAttributeCategoryDef[];
            // TODO: get all the attr defs for the part if not in this list; flag attrs that will be removed
            setCatAttrDefs(newAttrDefs);
            const attrs = item?.attributes ?? [];
            const attrForm: IFormData<IPartAttr>[] = attrs.map(a => {
              return {
                partAttrId: `${a.partAttrId}`,
                partAttrDefId: `${a.partAttrDefId}`,
                name: a.name,
                type: a.type,
                unitName: a.unitName,
                value: `${a.value}`,
              }
            });
            const missing: IPartAttributeDef[] = newAttrDefs.filter(ad => attrs.findIndex(a => a.partAttrDefId == ad.partAttrDefId) < 0);
            attrForm.push(...missing.map(m => {
              let curVal = '';
              if (item) {
                const attr = item.attributes.find(a => a.partAttrDefId == m.partAttrDefId);
                if (attr) {
                  curVal = `${attr.value}`;
                }
              }
              return {
                partAttrId: '',
                partAttrDefId: `${m.partAttrDefId}`,
                name: m.name,
                type: m.dataType,
                unitName: m.unitName,
                value: curVal,
              }
            }))
            setAttrFormData(attrForm);
          })
      })
    }

    useEffect(() => {
      if (item && item.partId) {
        setFormData(this.toFormData(item));
      } else {
        setFormData(this.emptyFormObject());
      }
      getCategoryAttributes(item?.partCategoryId);
      setValidation([]);
    }, [item]);

    const [validation, setValidation] = useState<fieldNames<IPart>[]>([]);

    const formChange = (fieldName: fieldNames<IPart> & string, event: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
      const newFormData: IFormData<IPart> = { ...formData };
      newFormData[fieldName] = event.currentTarget.value;
      setFormData(newFormData);
      if (fieldName == 'partCategoryId') {
        getCategoryAttributes(+newFormData.partCategoryId);
      }
    }

    const validate = () => {
      const valid: fieldNames<IPart>[] = [];
      const reqFields: fieldNames<IPart>[] = this.requiredFields;
      reqFields.forEach(d => { if (formData[d].trim() == '') { valid.push(d) } });
      attrFormData.forEach(afd => {
        if (afd.value.trim() == '' && catAttrDefs.find(ca => ca.partAttrDefId == +afd.partAttrDefId)?.required) { valid.push(afd.name) }
      }); // TODO: ensure doesn't overlap with part fields

      setValidation(valid);
      return valid.length;
    }

    const attrFormChange = (fieldName: fieldNames<IPartAttr>, event: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
      partAttrDefId: number) => {
      const fieldIndex = attrFormData.findIndex(afd => +afd.partAttrDefId == partAttrDefId);
      if (fieldIndex >= 0) {
        attrFormData[fieldIndex] = { ...attrFormData[fieldIndex] }
        attrFormData[fieldIndex].value = event.currentTarget.value;
      }
      setAttrFormData([...attrFormData]);
    }

    const saveItem = async (event: React.FormEvent<HTMLElement>) => {
      event.preventDefault();

      if (validate() > 0) {
        return;
      }
      const itemSave: IPart = this.fromFormData(formData);
      itemSave.attributes = attrFormData.map(afd => {
        return {
          partAttrId: +afd.partAttrId,
          partAttrDefId: +afd.partAttrDefId,
          name: afd.name,
          type: afd.type,
          unitName: afd.unitName,
          value: afd.value,
        }
      })

      let APIcall = API.post;

      if (item && item.partId > 0) {
        APIcall = API.patch;
        itemSave.partId = item.partId;
      }
//      getAccessTokenSilently().then(accessToken => {

        const result = APIcall(getAccessTokenSilently(), this.getURL(), itemSave as Object)
          .then(result => {
            this.setLoadState(ILoadState.REFRESH);
            updateFn();
            setFormData(this.emptyFormObject());
            closeFn();
          })
          .catch((err) => {
            Alert(AlertTypes.ERROR, err.message);
            return;
          })
 //     })
    }

    if (!item) {
      return <Fragment />
    }
    return <div>
      <div className="uk-align-right uk-dark uk-background-muted">
        <button
          className="uk-button uk-button-muted uk-button-small"
          onClick={() => { closeFn() }}
          data-uk-icon="close">
        </button>
      </div>
      <br />
      <div className="uk-card uk-card-default uk-padding-small">
        <form>
          {this.renderForm(formData, formChange, validation, setValidation)}
          <h2>Attributes</h2>
          {formData.partCategoryId ?
            catAttrDefs.map(a => <Fragment><FormInput<IPartAttr>
              label={a.required ? `${a.name} - ${a.unitName}` : `${a.name} - ${a.unitName} (optional)`}
              name="value"
              data={attrFormData.find(af => +af.partAttrDefId == +a.partAttrDefId)!}
              changeFn={(f, e) => attrFormChange(f, e, +a.partAttrDefId)}
              validation={validation}
            />
            </Fragment>
            )
            : <div className="uk-padding uk-padding-remove-top">Select a category first</div>
          }
          <div >
            <button
              className="uk-button uk-button-primary uk-button-small uk-padding-left"
              onClick={(e) => saveItem(e)}>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  }
  initialAddEditContext: IAddEditContext<IPart> = { setEditDataFn: () => { } };
  AddEditContext = React.createContext(this.initialAddEditContext);

  listName = "Parts";
  iconName = "part" as SectionIconName;
  primaryIdField = "partId";
  requiredFields = ['name', 'partNumber', 'partCategoryId', 'serialized'];

}

interface PartNumberFromIdProps {
  accessToken: string | Promise<string>;
  partId: number;
}

export const PartNumberFromId = ({ accessToken, partId }: PartNumberFromIdProps) => {
  return <PromiseText field="partNumber">{Parts.Instance().getItem(accessToken, partId)}</PromiseText>
}


/*
function deleteItem<T extends BaseObject<B>, B>(accessToken: string, itemClass: T, item: B, updateFn: () => void) {

    const id = itemClass.primaryId(item)
    API.delete(accessToken, itemClass.getURL() + `/${id}`)
        .then(result => {
            updateFn();
        })
        .catch(err => {
            Alert(AlertTypes.ERROR, err.message);
        })
}
*/