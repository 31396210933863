import { useAuth0 } from "@auth0/auth0-react";
import API from "@sesame/web-api";
import { ifError } from "assert";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { Fragment, useEffect, useState } from "react";
import Alert, { AlertTypes } from "src/components/AlertDisplay";
import { FormInput } from "src/components/FormInput";
import { IFormData, fieldNames } from "src/components/FormTypes";
import ListHeading from "src/components/ListHeading";
import ModalDialog, {modalClose} from "src/components/ModalDialog";
import { Spinner } from "src/components/Spinner";
import { IDeleteListItemProps, ILoadState } from "src/types";

const INVITE_MODAL_ID="newInvite";

dayjs.extend(relativeTime);

interface IInvitation {
  email: string;
  sent: Date;
  fulfilled: Date;

}

interface INewInvitation {
  email: string;
}

export const InvitationList = () => {

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [invitationList, setInvitationList] = useState<IInvitation[]>([]);
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW);


  const getInvitationList = () => {
    getAccessTokenSilently()
      .then((accessToken) => {
        return API.get(accessToken, `/api/v1/users/invitation`,{timeout: 6000})
      })
      .then(result => {
        setInvitationList(result.data);
        setDisplayState(ILoadState.READY)
      })
      .catch(err => {
        Alert(AlertTypes.ERROR, err.message);
        setDisplayState(ILoadState.ERROR);
      })
  }

  useEffect(() => {
    getInvitationList()
    setDisplayState(ILoadState.LOADING)
  }, [isAuthenticated, getAccessTokenSilently]);

  if (isLoading || (displayState == ILoadState.NEW || displayState == ILoadState.LOADING)) {
    return <div><h2>Invitations</h2>
      <Spinner />
    </div>
  }
  if (!isAuthenticated) {
    return <Fragment />
  }
  return <div>
    <h2>Invitations <ModalDialog title="New Invitation" id={INVITE_MODAL_ID} buttonText={"New Invitation"}>
      <NewInvitationForm />
    </ModalDialog>
    </h2>
    <ul className="uk-list">
      {invitationList.sort((a, b) => a.sent > b.sent ? -1 : 1).map(i => (
        <li key={i.email}>
          <div className="uk-grid uk-card uk-card-hover">
            <div className="uk-width-expand">
              {i.email}
            </div>
             <div className="uk-text-meta">
               {dayjs(i.sent).fromNow()}
            </div>
            <div className="uk-width-auto">
              <ul className="uk-iconnav">
                <li><a data-uk-icon="mail" data-uk-tooltip="Resend" /></li>
                <li><a data-uk-icon="trash" data-uk-tooltip="Delete" /></li>
              </ul>
            </div>
          </div>
        </li>
      )
      )}
    </ul>
  </div>
}

const NewInvitationForm = () => {
  const [validation, setValidation] = useState<fieldNames<INewInvitation>[]>([]);
  const [formData, setFormData] = useState<IFormData<INewInvitation>>({ email: '' });
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.READY);
  const { getAccessTokenSilently } = useAuth0();


  const newInvite = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (validate()) {
      return;
    }
    setDisplayState(ILoadState.LOADING);

    getAccessTokenSilently()
      .then((accessToken) => {
        return API.post(accessToken, '/api/v1/users/invitation', formData)
      })
      .then(result => {
        modalClose(INVITE_MODAL_ID);
        setFormData({email: ''});
        setDisplayState(ILoadState.READY);
      })
      .catch(err => {
        Alert(AlertTypes.ERROR, err.message);
        console.log(JSON.stringify(err));
        setDisplayState(ILoadState.READY)
      })
  }

  const formChange = (fieldName: fieldNames<INewInvitation>,
    event: React.FormEvent<HTMLInputElement>) => {
    const newFormData: IFormData<INewInvitation> = { ...formData };
    if (event.currentTarget.type == 'checkbox') {
      newFormData[fieldName] = newFormData[fieldName] == 'true' ? '' : 'true';
    } else {
      newFormData[fieldName] = event.currentTarget.value;
    }
    setFormData(newFormData);
  }

  const validate = () => {
    const valid: fieldNames<INewInvitation>[] = [];

    if (!formData['email'].match(/.+@.+\..+$/)) {
      valid.push('email');
      setValidation(valid);
    }
    return valid.length;
  }

  return <div className="uk-card">
    <form>
      <FormInput
        label="Invite User"
        name="email"
        data={formData}
        changeFn={formChange}
        validation={validation} />
      {displayState == ILoadState.LOADING && <Spinner />}
      {displayState == ILoadState.READY && <button
        className="uk-button uk-button-primary uk-button-small"
        disabled={formData.email.match(/.+@.*\..*/) == null}
        onClick={e => newInvite(e)} >Invite</button>
      }
    </form>
  </div>
}