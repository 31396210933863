
import React, { useEffect, useState, } from 'react';
import Downshift from 'downshift';

import { ILocation } from 'src/types';

import { Locations } from 'src/pages/Locations';
import { useAuth0 } from '@auth0/auth0-react';


interface ISelectLocationProps {
    warehouseId: number;
    location?: ILocation;
    excludeLocationId?: number;
    label?: string;
    onSet: (bin: ILocation | undefined) => void;
}

export const SelectLocation = ({
  warehouseId,
  location,
  excludeLocationId,
  onSet,
  label='Location Identifier'}: ISelectLocationProps) => {


    const {isLoading, isAuthenticated, getAccessTokenSilently} = useAuth0();
    const [description, setDescription] = useState('');
    const [validLocation, setValidLocation] = useState(true);
    const [locations, setLocations] = useState<ILocation[]>([]);

    const onInputChange = (inputValue: string) => {
        const value = inputValue.trim();
        setDescription(value);
        setValidLocation(true);
        const match = locations.find( l => l.description == value);
        if (match) {
//            onSet(match);
        }
    }

    const onLocationChange = (selection: ILocation) => {
        setDescription(selection.description.trim());
        setValidLocation(true);
        onSet(selection);
    }

    /*
    const selectLocation = async (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const result = await API.get(getAccessTokenSilently(), `/api/v1/bin?identifier=${binIdentifier}`)
            .catch(err => {
                setValidLocation(false);
                return;
            });
        const b = (result.data as ILocation[]).find(b => b.binIdentifier == binIdentifier);
        if (b) {
            onSet(b);
        } else {
            setValidLocation(false);
        }
    }
    */
    

    useEffect(() => {
      if (isLoading || !isAuthenticated) { return} 
        setDescription(location?.description ?? '');
        onSet(location);
        Locations.Instance().get(getAccessTokenSilently()).then( locations => {
          setLocations(locations.filter( l => l.warehouseId == warehouseId && l.locationId != excludeLocationId));
        });
        setValidLocation(true);
    }, [warehouseId, isLoading, isAuthenticated, getAccessTokenSilently])
    

    let labelClass = "";
    if (!validLocation) {
        labelClass = 'uk-text-danger';
    }
 
    return <Downshift
        selectedItem={location}
        onInputValueChange={onInputChange}
        onChange={selection => onLocationChange(selection!)}
        inputValue={description}
        itemToString={item => (item ? `${item.description}`
            : '')}
    >
        {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            isOpen,
            inputValue,
            highlightedIndex,
            selectedItem,
            getRootProps,
        }) => (
            <div>
                <label {...getLabelProps()}>{label}</label>
                <br />
                <div
                    style={{ display: 'inline-block' }}
                    {...getRootProps({}, { suppressRefError: true })}
                >
                    <input {...getInputProps()}  />
                </div>
                <ul className="autocomplete-list" {...getMenuProps()}>
                    {isOpen ? locations
                        //.filter(bin => !inputValue || `${bin.binIdentifier}`.toLowerCase().includes(inputValue.toLowerCase()))
                        .filter(loc => `${loc.description}`.toLowerCase().includes((inputValue ?? '').toLowerCase()))
                        .map((item, index) => (
                            <li
                                {...getItemProps({
                                    key: item.locationId,
                                    index,
                                    item,
                                    style: {
                                        backgroundColor:
                                            highlightedIndex === index ? 'lightgray' : 'white',
                                        fontWeight: selectedItem === item ? 'bold' : 'normal',
                                    },
                                })}
                            >
                                {item.description}
                            </li>
                        ))
                        : null}
                </ul>
            </div>
        )}
    </Downshift>
}