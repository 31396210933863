import React, { useEffect, useState, useRef } from 'react';
import API from '@sesame/web-api';

import { ILoadState, IPart } from 'src/types';

import { OverviewCard } from 'src/components/OverviewCard';
import { Link } from 'react-router-dom';
import { Spinner } from 'src/components/Spinner';
import { useAuth0 } from '@auth0/auth0-react';

import {config} from  '../config';

interface ISummary {
  name: string;
  value: number;
}

export default function Overview() {
  const { isLoading, isAuthenticated, user, getAccessTokenSilently }  = useAuth0();
  
  const [locationSummary, setLocationSummary] = useState<ISummary[]>([]);
  const [locationDisplay, setLocationDisplay] = useState<ILoadState>(ILoadState.NEW);
  const [inventorySummary, setInventorySummary] = useState<ISummary[]>([]);
  const [inventoryDisplay, setInventoryDisplay] = useState<ILoadState>(ILoadState.NEW);

  
  const getLocationSummary = (accessToken: string) => {
    API.get(accessToken, '/api/v1/report/summary/location')
    .then( result => {
      setLocationSummary(result.data as ISummary[]);
      setLocationDisplay(ILoadState.READY);
    })
    .catch( err => {
      setLocationDisplay(ILoadState.ERROR);
    });
  }

  const getInventorySummary = (accessToken: string) => {
    API.get(accessToken, '/api/v1/report/summary/inventory')
    .then( result => {
      setInventorySummary(result.data as ISummary[]);
      setInventoryDisplay(ILoadState.READY);
    })
    .catch( err => {
      setInventoryDisplay(ILoadState.ERROR);
    });
  }
  
  useEffect( () => {
    const getInventoryData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        getLocationSummary(accessToken);
        getInventorySummary(accessToken);
      } catch (e) {
        console.log(JSON.stringify(e));
      }
    }
    getInventoryData();
  }, [getAccessTokenSilently]) 
  
  const getSummaryValue = (summary: ISummary[], key: string) => {
    const s = summary.find( k => k.name == key);
    if (s) { return s.value}
    return '.'
  }

  return <div className="uk-grid-match uk-child-width-1-2@m uk-flex-center" data-uk-grid>
     <OverviewCard iconName='location'>
         {locationDisplay == ILoadState.READY ?
         <ul className="uk-list">
             <li><Link to={{pathname: "/bins"}}>
               {getSummaryValue(locationSummary, 'bins')} bins
              </Link></li>
             <li><Link to={{pathname: "/locations"}}>
             {getSummaryValue(locationSummary, 'locations')} locations
              </Link></li>
             <li><Link to={{pathname: "/warehouses"}}>
             {getSummaryValue(locationSummary, 'warehouses')} warehouses
             </Link></li>
         </ul>
         :
          locationDisplay == ILoadState.ERROR ? <span>Not available</span> : <Spinner />
         }
     </OverviewCard>
     <OverviewCard iconName='part'>
         {inventoryDisplay == ILoadState.READY ?
         <ul className="uk-list">
             <li><Link to={{pathname: "/inventory"}}>
                <span>{getSummaryValue(inventorySummary, 'items')} items in stock</span>
              </Link></li>
             <li><Link to={{pathname: "/parts"}}>
               {getSummaryValue(inventorySummary, 'parts')} parts
              </Link></li>
             <li><Link to={{pathname: "/manufacturers"}}>
               {getSummaryValue(inventorySummary, 'manufacturers')} manufacturers
              </Link></li>
         </ul>
         :
          inventoryDisplay == ILoadState.ERROR ? <span>Not available</span> : <Spinner />
         }
     </OverviewCard>
     <OverviewCard iconName='report'>
         <ul className="uk-list">
            <li><Link to={{pathname: "/reports", search: "?report=ioh"}}>Inventory On Hand</Link></li>
            <li><Link to={{pathname: "/reports", search: "?report=rm"}}>Recent Movements</Link></li>
            <li><Link to={{pathname: "/reports"}}>All Reports</Link></li>
         </ul>
     </OverviewCard>
     <OverviewCard iconName='settings'>
         <ul className="uk-list">
              <li><Link to={{pathname: "/PartCategories"}}>Categories</Link></li>
             <li><Link to={{pathname: "/Users"}}>Users</Link></li>
             <li>General</li>
         </ul>
     </OverviewCard>
  </div>

}