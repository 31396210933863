import React, { Fragment, useEffect, useState } from 'react';
import API from '@sesame/web-api';

import { Bins } from '../Bins/Bin';
import { PartNumberFromId, Parts } from '../Parts/Parts';
import { IBin, IInventoryLocation, ILoadState, IPart } from 'src/types';
import { Spinner } from 'src/components/Spinner';
import ListHeading from 'src/components/ListHeading';
import { SelectBin } from './widgets/SelectBin';
import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import { Locations } from '../Locations';
import PromiseText from 'src/components/PromiseText';
import { useLocation } from 'react-router-dom';
import ModalDialog from 'src/components/ModalDialog';
import { Warehouses } from '../Warehouses/Warehouses';

import { IInventoryExist, IInventoryRecon } from './Reconcile/types';
import { ReconItemList } from './Reconcile/ReconItemList';
import { useAuth0 } from '@auth0/auth0-react';


export function ReconcileBin() {

  const [bin, setBin] = useState<IBin>();
  const [existInventory, setExistInventory] = useState<IInventoryExist[]>([])
  const [qtyParts, setQtyParts] = useState<IInventoryRecon[]>([]);
  const [serParts, setSerParts] = useState<IInventoryRecon[]>([]);
  //const [checkInventory, setCheckInventory] = useState<IInventoryRecon[]>([])
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW);

  const location = useLocation();
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  if (isLoading || !isAuthenticated) { return <Fragment /> }

  const setReconcileBin = (e: React.MouseEvent<HTMLButtonElement | HTMLInputElement>,
      bin: IBin | undefined) => {

    e.preventDefault();
    if (!bin) {
      return;
    }
    setBin(bin);
    getInventory(bin.binIdentifier);
  }

  const getInventory = (binIdentifier: string) => {

    API.get(getAccessTokenSilently(), `/api/v1/inventory?binIdentifier=${binIdentifier}`, { timeout: 10000 })
      .then(result => {
        const inv = result.data.inventory as IInventoryLocation[];
        setExistInventory(inv.map(i => { const n: IInventoryExist = { ...i, matchId: 0 }; return n }));

        let qparts: IInventoryRecon[] = [];
        let sparts: IInventoryRecon[] = [];

        inv.forEach(i => {
          if (!i.serialNumber) {
            qparts.push({ ...i, reconQty: 0, state: 'unmatched' });
          } else {
            sparts.push({ ...i, reconQty: 0, state: 'unmatched' })
          }

        })
        setQtyParts(qparts);
        setSerParts(sparts);
        setDisplayState(ILoadState.READY);
      })
      .catch(err => {
        Alert(AlertTypes.ERROR, 'Error loading bin')
      })
    setDisplayState(ILoadState.LOADING);

  }

  // Clear page when use commits.  Full = commit successful, wipe page ask for nex bin
  // !full = incomplete/error, update existing quantities with progress but preserve user
  // entry to continue
  const reset = (full: boolean) => {
    if (full) {
      setBin(undefined);
      setExistInventory([]);
      setDisplayState(ILoadState.NEW);
    } else {
      //TODO:  Partial refresh of inventory

    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const binIdentifier = queryParams.get('binIdentifier');
    if (binIdentifier) {
      Bins.Instance().getBinByIdentifier(getAccessTokenSilently(), binIdentifier)
        .then(bin => {
          if (bin) {
            setBin(bin);
            getInventory(bin.binIdentifier);
          }
        })
    }
  }, [])

  const invChanged = (qtyParts.findIndex(qp => qp.reconQty > 0) >= 0 ||
    serParts.findIndex(sp => sp.reconQty > 0) >= 0);

  return <div>
    <ListHeading listName={`Inventory: Reconcile Bin`} iconName={'part'} />

    <h2>
      {bin && <span style={{ float: "right" }}>
        <ModalDialog
          id="confirmBinChange"
          buttonText="Change Bin"
          title={'Change Bin'}
        >
          <ChangeBinForm
            invChanged={invChanged}
            bin={bin}
            setReconcileBin={setReconcileBin} />

        </ModalDialog>
      </span>}
      {bin ? <span>{bin.binIdentifier}<span className="uk-text-muted uk-text-small"><br />
        <PromiseText>
          {Locations.Instance().getItem(getAccessTokenSilently(), bin.locationId)
            .then(l => l?.warehouseId ? Warehouses.Instance().getItem(getAccessTokenSilently(), l.warehouseId)
              .then(w => w?.warehouseAbbrev ?? "(no warehouse)") : "(whs loc err)")
          }
        </PromiseText>
        :
        <PromiseText field="description">
          {Locations.Instance().getItem(getAccessTokenSilently(), bin.locationId)}
        </PromiseText>
        <br />
        {bin.partId ? <Fragment>
          <PartNumberFromId accessToken={getAccessTokenSilently()} partId={bin.partId} />
          <span className="uk-text-small">{bin.serialized ? " - Serialized" : " - Not Serialized"}</span>

        </Fragment>
          :
          <Fragment> Any Part</Fragment>}
      </span>
      </span>
        : <span />}

    </h2>
    {!bin && <div>
      <ChangeBinForm
        invChanged={false}
        bin={undefined}
        setReconcileBin={setReconcileBin}
        />
    </div>}
    <br />
    {displayState == ILoadState.LOADING && <Spinner />}
    {displayState == ILoadState.READY && bin && <ReconItemList
      bin={bin}
      binParts={existInventory}
      qtyParts={qtyParts}
      serParts={serParts}
      setQtyParts={setQtyParts}
      setSerParts={setSerParts}
      onComplete={reset}
    />
    }
  </div>
}


interface IChangeBinFormProps {
  invChanged: boolean,
  bin: IBin | undefined,
  setReconcileBin: (e: React.MouseEvent<HTMLButtonElement | HTMLInputElement>, bin: IBin|undefined) => void,
}

const ChangeBinForm = ({ invChanged, bin, setReconcileBin }: IChangeBinFormProps) => {
  const [selectBin, setSelectBin] = useState<IBin>();

  return <form className="inventory-form"
  >
    {invChanged && <p className="uk-text-danger">
      Changing the Bin will loose all progress
    </p>}
    <div id="binchangeform">
      <SelectBin
        bin={bin}
        label="Bin to Reconcile"
        onSet={setSelectBin}
      />
    </div>
    <div>
      <p className="uk-text-right">
        <button className="uk-button-primary"
          data-uk-toggle="target: #confirmBinChange-div"
          onClick={e => setReconcileBin(e, selectBin)}>Set Bin</button>
      </p>
    </div>
  </form>
}