import React, { useState, Fragment } from 'react';

import ListHeading from 'src/components/ListHeading';
import { Link, useSearchParams } from 'react-router-dom';

import { InvOnHand } from './InventoryOnHand2';
import { RecentMovements } from './RecentMovments';


export const ReportsPage: React.FC = ({ ...props }) => {

  const [searchParams] = useSearchParams();
  const [report, setReport] = useState(-1);

  const reportList = [
    {
      token: 'ioh',
      name: 'Inventory On Hand',
      report: () => InvOnHand(props),
    },
    {
      token: 'rm',
      name: 'Recent Activity',
      report: () => RecentMovements(props),
    }
  ]


  const onSetReport = (event: React.MouseEvent, reportName: string) => {
    event.preventDefault();
    setReport(reportList.findIndex(r => r.name == reportName));
  }

  const navReport = searchParams.get('report');
  const selectedReport = reportList.find(r => r.token == navReport);

  return <div>
    <ListHeading listName="Reports" iconName={'report'} />

    <ul className="uk-list">
      <li><h3>Inventory</h3>
        <ul className="uk-list">
          {reportList.filter(r => r.token != navReport).map(r => (
            <li><Link to={{ search: `?report=${r.token}` }}>{r.name}</Link></li>
          ))}
        </ul>
      </li>
    </ul>
    <br />
    {selectedReport && <section>
      <h2>{selectedReport.name}</h2>
      {selectedReport.token == 'ioh' ? <InvOnHand /> : <Fragment />}
      {selectedReport.token == 'rm' ? <RecentMovements /> : <Fragment />}
    </section>
    }
  </div>
}


/*
<RunReport reports={reportList} selected={report} />
const RunReport = ( {reports, selected}: {reports: any[], selected: number}) => {
if (selected < 0) {
return <h2>Select a Report</h2>
}
return reports[selected].report();

}
*/
