
// limit data types to Integer, Number, Char, or String
export enum MovementReasonType  {
    Add       = 'A',
    Transfer  = 'T',
    Remove    = 'R',
}

export interface INewMovementReason {
  type:    MovementReasonType;
  reason:  string;
};

export interface IMovementReason {
  movementReasonId: number;
  type:             MovementReasonType;
  reason:           string;
};

export interface IMovementReasonId {
  movementReasonId: number;
}
