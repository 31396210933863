import React, { useEffect, useState, useContext, Fragment } from 'react';
import API from '@sesame/web-api';

import { IAddEditContext, ILoadState, IMovementReason, MovementReasonType, _get } from 'src/types';

import { IFormData, FormInput, fieldNames, FormSelect } from 'src/components/Form';
import { BaseObject } from 'src/types';
import { SectionIcon, SectionIconName } from 'src/components/SectionIcon';
import { ItemPage } from '../ItemPage';


export const MovementReasonsPage = () => {
  const w = MovementReasons.Instance();

  return <ItemPage<MovementReasons, IMovementReason> itemClass={w} />

}

export class MovementReasons implements BaseObject<IMovementReason> {
  private static _instance: MovementReasons;
  private static _movementReasons: IMovementReason[];
  private static _loadStatus: ILoadState = ILoadState.NEW;

  private constructor() { };

  static Instance() {
    if (!MovementReasons._instance) {
      MovementReasons._instance = new MovementReasons();
    }
    return MovementReasons._instance;
  }

  setLoadState = (newState: ILoadState) => { MovementReasons._loadStatus = newState }
  getLoadState = () => MovementReasons._loadStatus;
  onDataLoad = (data: IMovementReason[]) => { MovementReasons._movementReasons = data };

  public get = async (accessToken: string | Promise<string>) => {
    await _get(accessToken, MovementReasons.Instance());
    return MovementReasons._movementReasons;
  }
  public getItem = async (accessToken: string | Promise<string>, movementReasonId: number) => {
    const token = await Promise.resolve(accessToken)
    const movementReasons = await MovementReasons.Instance().get(token);
    const movementReason = movementReasons.find(m => m.movementReasonId == movementReasonId);
    return movementReason;
  }

  getURL = () => '/api/v1/movementReason';
  emptyFormObject = () => {
    return {
      movementReasonId: '',
      type: '',
      reason: '',
    }
  }
  primaryId = (movementReason: IMovementReason) => movementReason.movementReasonId;
  renderListItem = (r: IMovementReason) => {
    const reasonType = Object.entries(MovementReasonType).find(([k, v]) => v == r.type ? k : undefined) ?? ['', undefined];
    return <Fragment>
      {r.reason}
      <br />
      <span className="uk-text-small uk-text-muted">{reasonType[0]}</span>
    </Fragment>
  };
  renderForm = (formData: IFormData<IMovementReason>,
    formChange: any, // TODO tighten this
    validation: fieldNames<IMovementReason>[],
    setValidation: any) => {
      return <Fragment>
        <FormSelect
          label={"Action Type"}
          name={"type"}
          data={formData}
          blankChoice="(Select Action for Reason)"
          displayFn={() => Object.entries(MovementReasonType).map(([k, v]) => {
            return {
              value: `${v}`, key: `${v}`,
              text: `${k}`
            }
          })}
          changeFn={formChange}
          validation={validation}
        />
        <FormInput
          label="Reason"
          name="reason"
          data={formData}
          changeFn={formChange}
          validation={validation} />
      </Fragment>
  };
  sortFn = (data: IMovementReason[]) => {
    return data.sort((a, b) => {
      if (a.type == b.type) { return a.reason < b.reason ? -1 : 1 }
      return a.type < b.type ? -1 : 1
    })
  };

  toFormData = (r: IMovementReason) => {
    return {
      movementReasonId: `${r.movementReasonId}`,
      type: r.type,
      reason: r.reason,
    }
  }

  fromFormData = (f: IFormData<IMovementReason>) => {
    return {
      movementReasonId: +f.movementReasonId,
      type: f.type as MovementReasonType, // , //MovementReasonType[f.type as keyof typeof MovementReasonType],
      reason: f.reason,
    }
  }

  initialAddEditContext: IAddEditContext<IMovementReason> = { setEditDataFn: () => { } };
  AddEditContext = React.createContext(this.initialAddEditContext);

  listName = "Movement Reasons";
  iconName = "settings" as SectionIconName;
  primaryIdField = "movementReasonId"; // as keyof IMovementReason;
  requiredFields = ['type', 'reason'];
}
