import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import { config } from './config';

// import Icons from '../js/uikit-icons.min.js';
// import UIkit from '../js/uikit.min.js';

import App from './App';
import './assets/uikit/css/uikit.theme.css';
import * as serviceWorker from './serviceWorker';

console.log('config: ' + JSON.stringify(config))
ReactDOM.createRoot(
  document.getElementById('root')!).render(
    <div>
      <React.StrictMode>
        <BrowserRouter>
          <Auth0Provider
            domain={config.AUTH0_DOMAIN}
            clientId={config.AUTH0_CLIENTID}
            cacheLocation='localstorage'
            authorizationParams={{
              redirect_uri: window.location.origin,
              audience: config.AUTH0_AUDIENCE,
              scope: 'read:parts openid profile name picture app_metadata',
            }}
          >
            <App />
          </Auth0Provider>
        </BrowserRouter>
      </React.StrictMode>
    </div>
  );


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
