import React, { Fragment } from 'react';

import { IFormData } from './FormTypes';
import { fieldNames } from './FormTypes';

interface FormCheckboxProps<D> {
  label: string;
  name: fieldNames<D>;
  data: IFormData<D>;
  inline?: boolean;
  changeFn: (d: fieldNames<D>, a: any) => void
  validation: fieldNames<D>[];
  disabled?: boolean;
}


export function FormCheckbox<D>({ label, name, data, inline, changeFn, validation, disabled = false }: FormCheckboxProps<D>) {
  if (inline) {
    return <Fragment>
      <input type="checkbox"
        checked={data[name] == 'true'}
        className={"uk-checkbox uk-margin-right" + (validation.includes(name) ? " uk-form-danger" : "")}
        onChange={(e) => changeFn(name, e)}
        disabled={disabled}
      >
      </input>
      {label && <label className={'uk-margin-right' + (validation.includes(name) ? ' uk-text-danger' : '')}>{label}</label>}
    </Fragment>
  }
  return <p>
    <input type="checkbox"
      className={"uk-checkbox uk-margin-right" + (validation.includes(name) ? " uk-form-danger" : "")}
      checked={data[name] == 'true'}
      onChange={(e) => changeFn(name, e)}
      disabled={disabled}
    >
    </input>
    {label && <label className={'uk-margin-right' + (validation.includes(name) ? ' uk-text-danger' : '')}>{label}</label>}
  </p>
}
