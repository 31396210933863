import React, { useEffect, useState, Fragment } from 'react';

import { IAddEditContext, ILoadState, IPartAttributeCategoryDef, IPartCategory, _get } from 'src/types';

import { IFormData, FormInput, fieldNames } from 'src/components/Form';
import { BaseObject } from 'src/types';
import { SectionIconName } from 'src/components/SectionIcon';
import { ItemPage } from '../ItemPage';
import API from '@sesame/web-api';
import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import { Spinner } from 'src/components/Spinner';
import { useAuth0 } from '@auth0/auth0-react';


export const PartCategoriesPage = () => {
  const pc = PartCategories.Instance();

  return <ItemPage<PartCategories, IPartCategory> itemClass={pc} />
}

export class PartCategories implements BaseObject<IPartCategory> {

  private static _instance: PartCategories;
  private static _partCategories: IPartCategory[];
  private static _loadStatus: ILoadState = ILoadState.NEW;

  private constructor() { };

  static Instance() {
    if (!PartCategories._instance) {
      PartCategories._instance = new PartCategories();
    }
    return PartCategories._instance;
  }

  setLoadState = (newState: ILoadState) => { PartCategories._loadStatus = newState }
  getLoadState = () => PartCategories._loadStatus;
  onDataLoad = (data: IPartCategory[]) => { PartCategories._partCategories = data };

  public get = async (accessToken: string) => {
    await _get(accessToken, PartCategories.Instance());
    return PartCategories._partCategories;
  }

  public getItem = async(accessToken: string | Promise<string>, partCategoryId: number) => {
    const token = await Promise.resolve(accessToken);
    const categories = await PartCategories.Instance().get(token);
    const category = categories.find( p => p.partCategoryId == partCategoryId);
    return category;
  }

  getURL = () => '/api/v1/partcategory';
  emptyFormObject = () => {
    return {
      partCategoryId: '',
      name: '',
      description: '',
    }
  }
  primaryId = (partCategory: IPartCategory) => partCategory.partCategoryId;
  renderListItem = (pc: IPartCategory) => {
    return <Fragment>
      {pc.name}
      <br />
      <span className="uk-text-small uk-text-muted">{pc.description}</span>
    </Fragment>
  };
  renderForm = (formData: IFormData<IPartCategory>,
    formChange: any, // TODO tighten this
    validation: fieldNames<IPartCategory>[],
    setValidation: any) => {
    return <Fragment>
      <FormInput
        label="Name"
        name="name"
        data={formData}
        changeFn={formChange}
        validation={validation} />
      <FormInput
        label="Description"
        name="description"
        data={formData}
        changeFn={formChange}
        validation={validation} />
    </Fragment>
  };

  renderAdditional = (formData: IFormData<IPartCategory>,
    formChange: any, // TODO tighten this
    validation: fieldNames<IPartCategory>[],
    setValidation: any) => {

    if (formData.partCategoryId) {
      return <PartCategoryAttrs categoryId={+formData.partCategoryId} />
    }
    return <div className="uk-margin"><span className="uk-text-italic uk-text-muted">Save to assign attributes</span></div>
  }

  toFormData = (pc: IPartCategory) => {
    return {
      partCategoryId: `${pc.partCategoryId}`,
      name: pc.name,
      description: pc.description,
    }
  }

  fromFormData = (f: IFormData<IPartCategory>) => {
    return {
      partCategoryId: +f.partCategoryId,
      name: f.name,
      description: f.description,
    }
  }

  initialAddEditContext: IAddEditContext<IPartCategory> = { setEditDataFn: () => { } };
  AddEditContext = React.createContext(this.initialAddEditContext);

  listName = "Part Categories";
  iconName = "settings" as SectionIconName;
  primaryIdField = "partCategoryId"; // as keyof IPartCategory;
  requiredFields = ['name', 'description'];
}

interface IPartCategoriesAttrsProps {
  categoryId: number;
}

const PartCategoryAttrs = ({ categoryId }: IPartCategoriesAttrsProps) => {
  const {isLoading, isAuthenticated, getAccessTokenSilently} = useAuth0();

  const [attributes, setAttributes] = useState<IPartAttributeCategoryDef[]>([]);
  const [assignedAttributes, setAssignedAttributes] = useState<IPartAttributeCategoryDef[]>([]);
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW);

  const getAttributes = () => {
    if (!categoryId || isLoading) {
      return;
    }
    let attrs: IPartAttributeCategoryDef[] = [];
    API.get(getAccessTokenSilently(), `/api/v1/partAttrDef?category=${categoryId}`)
      .then(result => {
        attrs = result.data as IPartAttributeCategoryDef[];
        setAssignedAttributes(attrs);
        return API.get(getAccessTokenSilently(), `/api/v1/partAttrDef`)
      }).then(res2 => {
        const unassigned = (res2.data as IPartAttributeCategoryDef[]).filter(ua => 0 > attrs.findIndex(a => a.partAttrDefId == ua.partAttrDefId));
        setAttributes(unassigned);
        setDisplayState(ILoadState.READY);
      })
      .catch(err => {
        console.log('Error loading attribute definitons: ' + err.message);
        Alert(AlertTypes.ERROR, 'Error loading attributes')
        setDisplayState(ILoadState.ERROR);
      });
    setDisplayState(ILoadState.LOADING);
  }

  const linkAttribute = (attributeId: number, required: boolean) => {
  
    API.post(getAccessTokenSilently(), `/api/v1/partAttrDef/${attributeId}/category/${categoryId}?required=${required}`, // ?required
      { partAttrDefId: attributeId })
      .then(result => {
        assignedAttributes.push(attributes.find(a => a.partAttrDefId == attributeId)!)
        setAssignedAttributes([...assignedAttributes]);
        setAttributes(attributes.filter(ua => ua.partAttrDefId != attributeId))
      })
      .catch(err => {
        console.log('Error updating attirute def for category')
        Alert(AlertTypes.ERROR, 'Error updating attribute');
      })
  }
  const unlinkAttribute = (attributeId: number) => {
    API.delete(getAccessTokenSilently(), `/api/v1/partAttrDef/${attributeId}/category/${categoryId}`)
      .then(result => {
        attributes.push(assignedAttributes.find(a => a.partAttrDefId == attributeId)!)
        setAttributes([...attributes]);
        setAssignedAttributes(assignedAttributes.filter(a => a.partAttrDefId != attributeId))
      })
      .catch(err => {
        console.log('Error updating attribute def for category')
        Alert(AlertTypes.ERROR, 'Error updating attribute');
      })
  }
  const updateAttributeRequired = (attributeId: number, required: boolean) => {
    API.patch(getAccessTokenSilently(), `/api/v1/partAttrDef/${attributeId}/category/${categoryId}?required=${required}`, {})
      .then(result => {
        const attr = assignedAttributes.find(a => a.partAttrDefId == attributeId);
        if (attr) {
          attr.required = required;
        }
        setAssignedAttributes([...assignedAttributes]);
      })
      .catch(err => {
        console.log('Error attribute required')
        Alert(AlertTypes.ERROR, 'Error updating attribute required');
      })
  }

  useEffect(() => getAttributes(), [categoryId, isLoading]);

  if (displayState == ILoadState.ERROR) {
    return <div>Error loading attributes</div>
  }
  if (displayState == ILoadState.NEW || displayState == ILoadState.LOADING) {
    return <Spinner />
  }

  const assigned = attributes.filter(a => a.categoryId == categoryId);
  return <Fragment>
    <h3>Attributes</h3>
    <ul className="uk-list">
      {assignedAttributes.map(aa => <li>
        <div className="uk-grid uk-card">
          <div className="uk-width-expand">
            {aa.name}
          </div>
          <div className="uk-width-auto">
            <ul className="uk-hidden-hover uk-iconnav">
              <li>
                <a href="#" uk-icon={aa.required ? "icon: plus-circle" : "icon: minus-circle"}
                  onClick={() => updateAttributeRequired(aa.partAttrDefId, !aa.required)} />
              </li>
              <li>
                <a href="#" uk-icon="icon: close"
                  onClick={() => unlinkAttribute(aa.partAttrDefId)} />
              </li>
            </ul>
          </div>
        </div>
      </li>
      )}
    </ul>
    <h3>Available Attributes</h3>
    <ul className="uk-list">
      {attributes.map(ua => <li>
        <div className="uk-grid uk-card">
          <div className="uk-width-expand">
            {ua.name}
          </div>
          <div className="uk-width-auto">
            <ul className="uk-hidden-hover uk-iconnav">
              <li>
                <a href="#" uk-icon="icon: minus-circle"
                  onClick={() => linkAttribute(ua.partAttrDefId, false)} />
              </li>
              <li>
                <a href="#" uk-icon="icon: plus-circle"
                  onClick={() => linkAttribute(ua.partAttrDefId, true)} />
              </li>
            </ul>
          </div>
        </div>
      </li>)}
    </ul>

  </Fragment>

}