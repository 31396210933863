import React, { Fragment } from "react"
import { FC } from "react"


interface ScanActionPanelProps {
  id: string;
  label?: string;
  danger?: boolean;
  onConfirm: (e: React.MouseEvent<HTMLButtonElement>) => void;
  okConfirm?: () => boolean;
  children: React.ReactNode;
}

export const ScanActionPanel: FC<ScanActionPanelProps> = ({ onConfirm, okConfirm, id, label, danger, children }: ScanActionPanelProps) => {
  const displayLabel = label ?? id;
  return <Fragment>
    <button type="button" className="scan-action-button">{displayLabel}</button>
    <div className="inventory-action-panel uk-card uk-card-body uk-card-default medium-drop inventory-action-panel"
      data-uk-drop="mode: click"
      id={`${id}Pane`}>
      <form className="inventory-form">
        {children}
        <button
          className={danger ? "uk-button-danger": ""}
          onClick={(e) => onConfirm(e)}
          disabled={okConfirm && !okConfirm() }>{displayLabel}</button>
      </form>
    </div>
  </Fragment>
}