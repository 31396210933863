import API from '@sesame/web-api';
import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import { IBin, IInventoryAction, IInventoryLocation } from 'src/types';

export * from './Inventory';
export * from './AddRemoveItems';
export * from './MoveItems';


interface MoveBinContentsResult {
  status: 'SUCCESS' | 'PARTIAL' | 'FAILED' | 'NOACTION',
  total: number,
  successCount: number,
}

export const moveBinContents = async (accessToken: string | Promise<string>, sourceBin: IBin, targetBin: IBin, invPartList: number[]): Promise<MoveBinContentsResult> => {

  if (invPartList.length == 0) { return { status: 'NOACTION', total: 0, successCount: 0 } }

  const result = await API.get(accessToken, `/api/v1/inventory?binIdentifier=${sourceBin.binIdentifier}`)

  const invResult = result.data.inventory as IInventoryLocation[];
  const calls: any[] = [];
  invResult.forEach(i => {
    const partId = invPartList.find(pid => pid == i.partId)
    if (partId) {
      const payload: IInventoryAction = {
        fromBin: sourceBin.binId,
        toBin: targetBin.binId,
        partId,
      }
      if (i.serialNumber) {
        payload.serialNumber = i.serialNumber;
      } else {
        payload.quantity = i.quantity;
      }
      calls.push(API.post(accessToken, '/api/v1/inventory/transfer', payload))
    }
  });

  let failed = 0;
  const results = await Promise.allSettled(calls);
  results.forEach( r => {
   if (r.status == 'rejected') {failed ++} 
  });
  if (results.length == calls.length) {
    if (failed == 0) {
      return { status: 'SUCCESS', total: calls.length, successCount: results.length }
   } else if (failed < calls.length) {
      return { status: 'PARTIAL', total: calls.length, successCount: results.length - failed }
    } else {
       return { status: 'FAILED', total: calls.length, successCount: results.length - failed }
    }
  } else {
       return { status: 'FAILED', total: calls.length, successCount: results.length - failed }
  }
  /*
    .then(results => {
      if (results[results.length - 1].status == 'rejected') { failed++ }
      if (results.length == calls.length) {
        if (failed == 0) {
          return { status: 'SUCCESS', totalCalls: calls.length, successCount: results.length }
        } else if (failed < calls.length) {
          return { status: 'PARTIAL', totalCalls: calls.length, successCount: results.length - failed }
        } else {
          return { status: 'FAILED', totalCalls: calls.length, successCount: results.length - failed }
        }
      }
    }) 
    .catch( err => {
       return { status: 'FAILED', totalCalls: calls.length, successCount: 0 }
    });
    */
}