
import { ReactElement } from 'react';
import { IFormData, fieldNames } from 'src/components/Form';
import { SectionIconName } from 'src/components/SectionIcon';
import { ILoadState } from './IUtil';
import API from '@sesame/web-api';
import { wait } from 'src/util/UIUtil';
import Alert, { AlertTypes } from 'src/components/AlertDisplay';

export interface BaseObject<B> {

    setLoadState: (newState: ILoadState) => void;
    getLoadState:  () => ILoadState;
    onDataLoad: (data: B[]) => void;
    get: (accessToken: string) => Promise<B[]>;
    getItem: (accessToken: string | Promise<string>, itemId: number) => Promise<B|undefined>;
    getURL: () => string;
    loadDependentData?: (accessToken: string) => Promise<void>;
    emptyFormObject: () =>  IFormData<B>;
    primaryId: (b: B) => number;
    renderListItem: (b: B) => React.ReactNode;
    renderForm: (b: IFormData<B>,
            formChange: any,
            validation: fieldNames<B>[],
            setValidation: any) => React.ReactNode;
    renderAdditional?: (b: IFormData<B>,
            formChange: any,
            validation: fieldNames<B>[],
            setValidation: any) => React.ReactNode;
    sortFn?: (data: B[]) => B[];
    toFormData: (b: B) => IFormData<B>;
    fromFormData: (f: IFormData<B>) => B;
    AddEditForm?: ( p: IAddListItemOverrideProps<B>) =>ReactElement;

    requiredFields: fieldNames<B>[];

    listName: string;
    iconName: SectionIconName;
    primaryIdField: fieldNames<B>;
    initialAddEditContext: IAddEditContext<B>;
    AddEditContext: React.Context<IAddEditContext<B>>;
}

export interface IAddListItemProps<T extends BaseObject<B>, B> {
    itemClass: T;
    item?: B;
    closeFn: () => void;
    updateFn: () => void;
}

export interface IDeleteListItemProps<T extends BaseObject<B>, B> {
    itemClass: T;
    item: B;
    setEditDataFn: (item?: B) => void;
    updateFn: () => void;
}

export interface IAddListItemOverrideProps<B> {
    item?: B;
    closeFn: () => void;
    updateFn: () => void;
}

export interface IAddEditContext<B> {
    setEditDataFn: React.Dispatch<React.SetStateAction<B | undefined>>;
}

export async function _loadData<T>(accessToken: string | Promise<string>, instance: BaseObject<T>): Promise<void> {
  const result = await API.get(accessToken, instance.getURL(),{timeout: 10000})
    .catch(err => {
      const errMsg = `Error loading ${instance.listName}: ${err.message}`
      console.log(errMsg);
      Alert(AlertTypes.ERROR, errMsg);
      instance.setLoadState(ILoadState.ERROR);
      return [];
    })
  const data = result.data as T[];
  instance.onDataLoad(data);
  instance.setLoadState(ILoadState.READY);
}

export async function _get<T>(accessToken: string | Promise<string>, instance: BaseObject<T>): Promise<void> {

  if (instance.getLoadState() == ILoadState.NEW || instance.getLoadState() == ILoadState.REFRESH) {
    if (instance.getLoadState() == ILoadState.NEW) {
      instance.setLoadState(ILoadState.LOADING);
    } else {
      instance.setLoadState(ILoadState.REFRESHING);
    }
    const token = Promise.resolve(accessToken);
    await _loadData(token, instance);
  } else if (instance.getLoadState() == ILoadState.LOADING) {
    await wait(500);
    return _get(accessToken, instance);
  } else {
    return;
  }
}