import React, { Fragment } from "react";
import { FormInput, FormSelect, IFormData, fieldNames } from "src/components/Form";
import { SectionIconName } from "src/components/SectionIcon";
import { BaseObject, IAddEditContext, ILoadState, ILocation, IWarehouse, _get } from "src/types";
import { ItemPage } from "./ItemPage";
import { Warehouses } from "./Warehouses/Warehouses";


export const LocationPage = () => {
    const w = Locations.Instance();

    return <ItemPage<Locations, ILocation> itemClass={w} />
}

export class Locations implements BaseObject<ILocation> {

  private static _instance: Locations;
  private static _locations: ILocation[];
  private static _loadStatus: ILoadState = ILoadState.NEW;

  private constructor() { };

  static Instance() {
    if (!Locations._instance) {
      Locations._instance = new Locations();
    }
    return Locations._instance;
  }

  setLoadState = (newState: ILoadState) => { Locations._loadStatus = newState }
  getLoadState = () => Locations._loadStatus;
  onDataLoad = (data: ILocation[]) => { Locations._locations = data };

  public get = async (accessToken: string | Promise<string>) => {
    await _get(accessToken, Locations.Instance());
    return Locations._locations;
  }
  public getItem = async(accessToken: string | Promise<string>, locationId: number) => {
    const token = await Promise.resolve(accessToken)
    const locations = await Locations.Instance().get(token);
    const location = locations.find( l => l.locationId == locationId);
    return location;
  }
  loadDependentData = async (accessToken: string) => {
    if (this.warehouses.length) {
      return;
    }
   this.warehouses = await Warehouses.Instance().get(accessToken);
  }

  warehouses: IWarehouse[] = [];

  getURL = () => '/api/v1/location';
  emptyFormObject = () => {
    return {
      locationId: '',
      warehouseId: '',
      description: '',
    }
  }
  primaryId = (location: ILocation) => location.locationId;
  renderListItem = (l: ILocation) => {
    const w = this.warehouses.find(w => {return w.warehouseId == l.warehouseId})
    return <Fragment>
      {l.description}
      <br />
      <span className="uk-text-small uk-text-muted"> {w?.warehouseAbbrev}: {w?.name} </span>
    </Fragment>
  }
  renderForm = (formData: IFormData<ILocation>,
    formChange: any,
    validation: fieldNames<ILocation>[],
    setValidation: any) => {

    return <form>
      <FormSelect
        label="Warehouse"
        name="warehouseId"
        data={formData}
        blankChoice="(Select Warehouse)"
        displayFn={() => this.warehouses.map(w => {
          return {
            value: `${w.warehouseId}`, key: `${w.warehouseId}`,
            text: `${w.warehouseAbbrev}: ${w.name}`
          }
        })}
        changeFn={formChange}
        validation={validation} />
      <FormInput
        label="Description"
        name="description"
        data={formData}
        changeFn={formChange}
        validation={validation} />
    </form>
  };

  toFormData = (l: ILocation) => {
    return {
      locationId: `${l.locationId}`,
      warehouseId: `${l.warehouseId}`,
      description: l.description,
    }
  }
  fromFormData = (f: IFormData<ILocation>) => {
    return {
      locationId: +f.locationId,
      warehouseId: +f.warehouseId,
      description: f.description,
    }
  }
  initialAddEditContext: IAddEditContext<ILocation> = { setEditDataFn: () => { } };
  AddEditContext = React.createContext(this.initialAddEditContext);

  listName = "Locations";
  iconName = "location" as SectionIconName;
  primaryIdField = "locationId";
  requiredFields = ['description', 'warehouseId'];
}
