import * as React from 'react';
import { config } from '../config';
import { useAuth0 } from '@auth0/auth0-react';

interface LoginParams {
  create?: boolean;
  token?: string;
  children: React.ReactNode;
}
const LoginLink = (
  { create = false, token, children }: LoginParams,
  //  props: React.HTMLAttributes<HTMLImageElement>,
) => {
  const { loginWithRedirect } = useAuth0();

  const doLogin = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (create) {
      const authConfig =
      {
        appState: {
          returnTo: '/NewUser'
        },
        authorizationParams: {
          screen_hint: "signup",
          ...token && {invToken: token},
        },
      }
      loginWithRedirect(authConfig);
    } else {
      loginWithRedirect();
    }
  }
  /*
  return (<a className="loginLink" href={`${config.AUTH_SERVER_URL}?callBackUrl=${window.location.origin}/loginConfirm`}>
    {props.children}
  </a>);
  */
  return <a className="loginlink" onClick={(e) => doLogin(e)}>{children}</a>

};

export default LoginLink;
//return <a className="loginLink" onClick={() => loginWithRedirect()}>{props.children}</a>
