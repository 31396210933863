import React from "react";
import { ReconState } from "./types";

interface ReconStateIconProps {
  state: ReconState;
}

const reconStateIcon = (state: ReconState) => {
  switch (state) {
    case 'unmatched': return 'question';
    case 'matched': return 'check';
    case 'new': return 'star';
  }
}

export const ReconStateIcon = ({ state }: ReconStateIconProps) => {
  //TODO move to CSS
  let color = "uk-text-primary";
  if (state == 'new') { color = "uk-text-warning" }
  if (state == 'matched') { color = "uk-text-success" }
  
  return <span className={`${color} uk-text-bolder`} data-uk-icon={reconStateIcon(state)}></span>
}
