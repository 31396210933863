import React, { useEffect, useState, Fragment } from 'react';

import { IMovementReason, MovementReasonType } from 'src/types';

import { MovementReasons } from '../../Movements/MovementReason';
import { useAuth0 } from '@auth0/auth0-react';


interface ISelectMovementReasonProps {
  movementReasonId: number;
  type: MovementReasonType;
onSet: (movementId: number) => void;
}

export const SelectMovementReason = ({ movementReasonId, type, onSet }: ISelectMovementReasonProps) => {

  const {isLoading, isAuthenticated, getAccessTokenSilently} = useAuth0();
  const [movementReasons, setMovementReasons] = useState<IMovementReason[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onSet(+e.target.value);
    
  }

  useEffect(() => {
    if (isLoading || !isAuthenticated) { return }
    MovementReasons.Instance().get(getAccessTokenSilently())
      .then(reasons => setMovementReasons(reasons))
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  return <Fragment>
    <label>Reason: (optional)</label>
    <br />
    <select value={movementReasonId} onChange={(e) => handleChange(e)}>
    <option>(select)</option>
    {movementReasons.filter(r => r.type == type)
      .map(r => { return (<option key={r.movementReasonId} value={r.movementReasonId}>{r.reason}</option>) })
    }
  </select>
  </Fragment>
}