import React, { useEffect, useState, useContext, Fragment } from 'react';
import API from '@sesame/web-api';

import { IAddEditContext, ILoadState, IManufacturer, _get } from 'src/types';

import { IFormData, FormInput, fieldNames } from 'src/components/Form';
import { BaseObject } from 'src/types';
import { SectionIcon, SectionIconName } from 'src/components/SectionIcon';
import { ItemPage } from '../ItemPage';


export const ManufacturerPage = () => {
  const w = Manufacturers.Instance();

  return <ItemPage<Manufacturers, IManufacturer> itemClass={w} />

}

export class Manufacturers implements BaseObject<IManufacturer> {

  private static _instance: Manufacturers;
  private static _manufacturers: IManufacturer[];
  private static _loadStatus: ILoadState = ILoadState.NEW;

  private constructor() { };

  static Instance() {
    if (!Manufacturers._instance) {
      Manufacturers._instance = new Manufacturers();
    }
    return Manufacturers._instance;
  }

  setLoadState = (newState: ILoadState) => { Manufacturers._loadStatus = newState }
  getLoadState = () => Manufacturers._loadStatus;
  onDataLoad = (data: IManufacturer[]) => { Manufacturers._manufacturers = data };

  public get = async (accessToken: string) => {
    await _get(accessToken, Manufacturers.Instance());
    return Manufacturers._manufacturers;
  }
  public getItem = async (accessToken: string | Promise<string>, manufacturerId: number) => {
    const token = await Promise.resolve(accessToken);
    const manufacturers = await Manufacturers.Instance().get(token);
    const manufacturer = manufacturers.find(i => i.manufacturerId == manufacturerId);
    return manufacturer;
  }

  getURL = () => '/api/v1/manufacturer';
  emptyFormObject = () => {
    return {
      manufacturerId: '',
      name: '',
      description: '',
    }
  }
  primaryId = (manufacturer: IManufacturer) => manufacturer.manufacturerId;
  renderListItem = (w: IManufacturer) => {
    return <Fragment>
      {w.name}
      <br />
      <span className="uk-text-small uk-text-muted">{w.description}</span>
    </Fragment>
  };
  renderForm = (formData: IFormData<IManufacturer>,
    formChange: any, // TODO tighten this
    validation: fieldNames<IManufacturer>[],
    setValidation: any) => {
      return <Fragment>
        <FormInput
          label="Name"
          name="name"
          data={formData}
          changeFn={formChange}
          validation={validation} />
        <FormInput
          label="Description"
          name="description"
          data={formData}
          changeFn={formChange}
          validation={validation} />
      </Fragment>
  };

  toFormData = (w: IManufacturer) => {
    return {
      manufacturerId: `${w.manufacturerId}`,
      name: w.name,
      description: w.description,
    }
  }

  fromFormData = (f: IFormData<IManufacturer>) => {
    return {
      manufacturerId: +f.manufacturerId,
      name: f.name,
      description: f.description,
    }
  }

  initialAddEditContext: IAddEditContext<IManufacturer> = { setEditDataFn: () => { } };
  AddEditContext = React.createContext(this.initialAddEditContext);

  listName = "Manufacturers";
  iconName = "part" as SectionIconName;
  primaryIdField = "manufacturerId"; // as keyof IManufacturer;
  requiredFields = ['name', 'description'];
}
