import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router";
import ListHeading from "src/components/ListHeading";
import { Spinner } from "src/components/Spinner";
import { UserList } from "./UserList";
import { InvitationList } from "./InvitationList";


export const UsersPage = () => {

  const {isLoading, isAuthenticated} = useAuth0();
  const nav = useNavigate();

  if (isLoading) {
    return <Spinner />
  }
  if (!isAuthenticated) {
    return <h1>Not Authorized</h1>
  }
  return <div id="settingsPage">

    <ListHeading listName="Users" iconName={'settings'} />

    <UserList role="adminUser" />
    <UserList role="inventoryUser" />
    <InvitationList />

  </div>
}


