import React, { useState } from 'react';

import { IBin } from 'src/types';
import { SelectBin } from './widgets/SelectBin';

import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import ListHeading from 'src/components/ListHeading';
import { InventoryList } from './widgets/InventoryList';
import { moveBinContents } from '.';
import { useAuth0 } from '@auth0/auth0-react';


export function MoveBinContentsPage() {

  const {isLoading, isAuthenticated, getAccessTokenSilently} = useAuth0();
  const [bin, setBin] = useState<IBin>();
  const [targetBin, setTargetBin] = useState<IBin>();
  const [invPart, setInvPart] = useState<number[]>([]);
  const [reloadKey, setReloadKey] = useState(1);
  
  const handleMoveContents = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!bin || !targetBin) { return }
    if (isLoading || !isAuthenticated) {return}
    moveBinContents(getAccessTokenSilently(), bin, targetBin, invPart)
      .then(result => {
        if (result.status == 'SUCCESS') {
          Alert(AlertTypes.SUCCESS, 'Bin Contents moved');
          setReloadKey(reloadKey + 1);
        } else if (result.status == 'PARTIAL') {
          setReloadKey(reloadKey + 1);
          Alert(AlertTypes.WARNING, `${result.successCount} of ${result.total} items moved`);
        } else {
          Alert(AlertTypes.ERROR, 'Failed to move items');
        }
      })
  }

  return <div>
    <ListHeading listName={`Inventory: Move Bin`} iconName={'part'} />

    <form className="inventory-form">
        <SelectBin bin={bin}
          filter="possible"
          label="From Bin"
          onSet={setBin}
        />
        <SelectBin
          bin={targetBin}
          excludeBinId={bin?.binId}
          filter="possible"
          label="To Bin"
          onSet={setTargetBin}
        />
        <InventoryList
          binIdentifier={bin?.binIdentifier}
          setParts={setInvPart}
          reloadKey={reloadKey}
        />

      <br />
      <br />
      <button
        disabled={!(bin && targetBin) || bin.binId == targetBin.binId || invPart.length == 0 }
        onClick={(e) => handleMoveContents(e)}>Move Selected Items</button>

    </form>
  </div>
}

