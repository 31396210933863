import * as React from 'react';

import ImgLocationIcon from '../assets/images/warehouse-circle.svg';
import ImgReportIcon from '../assets/images/report-circle.svg';
import ImgSettingsIcon from '../assets/images/gear-circle.svg';
import ImgPartIcon from '../assets/images/screw-circle.svg';

export type SectionIconName =  'location' | 'report' | 'settings' | 'part';

interface ISectionIconProps {
    height: number;
    width: number;
    iconName: SectionIconName;
    padding?: boolean
}
    const iconMap = {
        location: ImgLocationIcon,
        report: ImgReportIcon,
        settings: ImgSettingsIcon,
        part: ImgPartIcon,
    }

export function SectionIcon ({iconName, width, height, padding}: ISectionIconProps) {
    const paddingClass = padding ? "uk-margin-left uk-margin-right" : "";
    return <img
        src={iconMap[iconName]}
        height={`${height}px`} width={`${width}px`}
        className={paddingClass}/>
}