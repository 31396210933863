import React, { useEffect, useState, useRef, Fragment } from 'react';
import API from '@sesame/web-api';

import { ILoadState } from 'src/types';

import { useNavigate } from 'react-router-dom';
import { Spinner } from 'src/components/Spinner';
import { useAuth0 } from '@auth0/auth0-react';
import LoginLink from 'src/components/LoginLink';
import Alert, { AlertTypes } from 'src/components/AlertDisplay';

export function NewUser() {
  const { isAuthenticated, isLoading, user, getAccessTokenSilently } = useAuth0();
  const [invites, setInvites] = useState<string[]>([]);
  const [selectedInvite, setSelectedInvite] = useState(-1);
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.READY)
  const navigate = useNavigate();

  const params = new URLSearchParams(document.location.search);
  const inviteToken = params.get("invitationId");

  const fulfillInvite = () => {
    if (!user) { return }
    setDisplayState(ILoadState.LOADING);
    getAccessTokenSilently()
    .then( auth => {
      API.get(auth, `/api/v1/users/invitation/redeem/${user.invToken}`)
      .then( res => {
        getAccessTokenSilently({cacheMode: 'off'})
        .then( r => {
          setDisplayState(ILoadState.READY);
        });
      })
      .catch( err => {
        Alert(AlertTypes.ERROR, 'Error completing login: ' + err.message);
      })
    })

  }

  const selectInvite = (e: React.MouseEvent<HTMLInputElement>, i: number) => {
    setSelectedInvite(i);
  }

  const createOrg = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate('/NewOrganization')
  }

  const joinOrg = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    alert('Not ready yet');
  }

  useEffect(() => {
    if (user?.invToken) {
      fulfillInvite()
    }
  }, [getAccessTokenSilently])

  if (isLoading) {
    return <Spinner />
  }


  if (isAuthenticated) {
    const assignedOrg = JSON.parse((user!["https://inventory.work/organization_id"]));
    if (assignedOrg) {
      if (assignedOrg.organizationId > 0) {
        return <OnboardComplete />
      }
    }
    return (
      <div className="uk-container">
        <h2>Get Started</h2>
        <div data-uk-grid className="uk-child-width-1-2@m uk-grid-match">
          <div>
            <div className="uk-card uk-card-default uk-card-body">
              <h3 className="uk-card-title">Create a new Organization</h3>
              <p>
                Create a new organization, no payment required.
              </p>
              <p>Your organization tracks all your items, warehouses and users.  You can start with just yourself and add other users
                later.
              </p>
              <p>
                Your free account allows you access to all functions with limits on the amount of
                data and users.  Upgrade your plan any time to increase limits.
              </p>
              <form className="inventory-form">
                <button className="uk-button-primary" onClick={e => createOrg(e)}>
                  Create Organization
                </button>
              </form>
            </div>
          </div>
          {invites.length > 0 &&
            <div>
              <div className="uk-card uk-card-default uk-card-body">
                <h3 className="uk-card-title">Join an organization</h3>
                <p>
                  The following organizations have issued an invite to your an account.  Select the organization you
                  wish to join.
                </p>
                <form className="inventory-form">
                  <p>
                    {invites.map((i, index) => {
                      return <div key={index}>
                        <input type="radio"
                          className="uk-radio"
                          checked={index == selectedInvite}
                          onClick={(e) => selectInvite(e, index)} />
                        {i}
                      </div>
                    })}
                  </p>
                  <button className="uk-button-primary" onClick={e => joinOrg(e)} disabled={selectedInvite < 0}>Join</button>
                </form>
              </div>
            </div>

          }
          {invites.length == 0 &&
            <div>
              <div className="uk-card uk-card-default uk-card-body">
                <h3 className="uk-card-title">Join an organization</h3>
                <p>
                  There are no invitations for this account.  If you expect to join an organization,
                  check with your organization adminsitrator for a current invitation.
                </p>
              </div>
            </div>
          }
        </div>
      </div>
    )
  } else if (inviteToken) {
    return <OnboardUnauthToken token={inviteToken} />
  } else {
    return <OnboardUnauthNoToken />
  }
}


interface IOnboardUnauthTokenProps {
  token: string;
}
const OnboardUnauthToken = ({ token }: IOnboardUnauthTokenProps) => {
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW);
  const [tokenError, setTokenError] = useState<string>();

  useEffect(() => {
    API.get('', `/api/v1/users/invitation/validate/${token}`)
      .then(result => {
        setDisplayState(ILoadState.READY);
      })
      .catch(err => {
        if (err.statusCode == 404 || err.statusCode == 422) {
          setTokenError(err.message);
          setDisplayState(ILoadState.NOTFOUND);
        } else {
          Alert(AlertTypes.ERROR, 'Error validating invitation: ' + err.message);
          setDisplayState(ILoadState.ERROR);
        }
      })
    setDisplayState(ILoadState.LOADING);

  }, [])

  if (displayState == ILoadState.NOTFOUND) {
    return <div className="uk-container">
      <h2>Invalid Invitation Code: {tokenError}</h2>
      <p>This invitation is not valid.  Either it expired or the link is in error.  Verify the link in your email
        or ask your adminstrator to send you a new invitation.
      </p>
    </div>
  }
  return <div className="uk-container">
    <h2>Welcome to Flax Inventory</h2>
    {(displayState == ILoadState.NEW || displayState == ILoadState.LOADING) && (
      <p>Checking invitation...</p>
    )
    }
    {displayState == ILoadState.READY && (
      <div>
        <p>
          To complete your account registration, please create your login.
        </p>
        <p>
          <LoginLink create={true} token={token}>
            <button className="uk-button uk-button-primary">Create Account</button>
          </LoginLink>
        </p>
      </div>
    )}
    <p>
      Once you've created your account you will be able to access the system.
    </p>
  </div>
}

const OnboardUnauthNoToken = () => {
  return <div className="uk-container">
    <h2>Welcome to Flax Inventory</h2>
    <p>
      If you have been invited to join your organization, please locate your invitation in email and click on the link provided.  Or, enter the code here:
      <form className="inventory-form">
        <input className="uk-input" width={40}></input>
        <button className="uk-button uk-button-primary"></button>
      </form>
    </p>
    <p>
      Othewise, start by <LoginLink create={true}><button className="uk-button uk-button-primary">Create Account</button></LoginLink>
    </p>
  </div>

}

const OnboardComplete= () => {
  const navigate = useNavigate();
  return <div className="uk-container">
    <h2>Welcome to Flax Inventory</h2>
    <p>
      Account successfully created!
    </p>
    <p>
      <button onClick={() => navigate("/")} className="uk-button uk-button-primary">Continue to Login</button>
    </p>
  </div>

}