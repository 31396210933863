
import React, { useEffect, useState, } from 'react';
import Downshift from 'downshift';

import { IWarehouse } from 'src/types';

import { Warehouses } from 'src/pages/Warehouses/Warehouses';
import { useAuth0 } from '@auth0/auth0-react';


interface ISelectWarehouseProps {
    warehouse?: IWarehouse;
    label?: string;
    filter?: 'contains' | 'possible';
    onSet: (bin: IWarehouse | undefined) => void;
}

export const SelectWarehouse = ({ warehouse, onSet, label='Warehouse'}: ISelectWarehouseProps) => {

    const {isLoading, isAuthenticated, getAccessTokenSilently} = useAuth0();
    const [warehouseAbbrev, setWarehouseAbbrev] = useState('');
    const [validWarehouse, setValidWarehouse] = useState(true);
    const [warehouses, setWarehouses] = useState<IWarehouse[]>([]);

//    const onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const onInputChange = (inputValue: string) => {
//        const value = event.currentTarget.value.trim();
        const value = inputValue.trim();
        setWarehouseAbbrev(value);
        setValidWarehouse(true);
        const match = warehouses.find( w => w.warehouseAbbrev == value);
        if (match) {
//            onSet(match);
        }
    }

    const onBinChange = (selection: IWarehouse) => {
        setWarehouseAbbrev(selection.warehouseAbbrev.trim());
        setValidWarehouse(true);
        onSet(selection);
    }

    /*
    const selectBin = async (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const result = await API.get(getAccessTokenSilently(), `/api/v1/bin?identifier=${binIdentifier}`)
            .catch(err => {
                setValidBin(false);
                return;
            });
        const b = (result.data as IBin[]).find(b => b.binIdentifier == binIdentifier);
        if (b) {
            onSet(b);
        } else {
            setValidBin(false);
        }
    }
    */
    
    useEffect( () => {
        setWarehouseAbbrev('');
        onSet(undefined);
        if (isLoading || !isAuthenticated) {
          return
        }
        Warehouses.Instance().get(getAccessTokenSilently())
          .then( warehouses => setWarehouses(warehouses))
        setValidWarehouse(true);
    }, [])
    
    useEffect(() => {
    }, []);

    let labelClass = "";
    if (!validWarehouse) {
        labelClass = 'uk-text-danger';
    }
 
    return <Downshift
        selectedItem={warehouse}
        onInputValueChange={onInputChange}
        onChange={selection => onBinChange(selection!)}
        inputValue={warehouseAbbrev}
        itemToString={item => (item ? `${item.warehouseAbbrev}`
            : '')}
    >
        {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            isOpen,
            inputValue,
            highlightedIndex,
            selectedItem,
            getRootProps,
        }) => (
            <div>
                <label {...getLabelProps()}>{label}</label>
                <br />
                <div
                    style={{ display: 'inline-block' }}
                    {...getRootProps({}, { suppressRefError: true })}
                >
                    <input {...getInputProps()}  />
                </div>
                <ul className="autocomplete-list" {...getMenuProps()}>
                    {isOpen ? warehouses
                        .filter(w => `${w.warehouseAbbrev}`.toLowerCase().includes((inputValue ?? '').toLowerCase()))
                        .map((item, index) => (
                            <li
                                {...getItemProps({
                                    key: item.warehouseId,
                                    index,
                                    item,
                                    style: {
                                        backgroundColor:
                                            highlightedIndex === index ? 'lightgray' : 'white',
                                        fontWeight: selectedItem === item ? 'bold' : 'normal',
                                    },
                                })}
                            >
                                {item.warehouseAbbrev}
                            </li>
                        ))
                        : null}
                </ul>
            </div>
        )}
    </Downshift>
}