
import React, { ChangeEvent, useEffect, useState, } from 'react';
import API from '@sesame/web-api';
import Downshift, { DownshiftState, StateChangeOptions, StateChangeTypes, UseComboboxStateChange, useCombobox } from 'downshift';

import { IBin } from 'src/types';

import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import { Bins } from 'src/pages/Bins/Bin';
import { useAuth0 } from '@auth0/auth0-react';


interface ISelectBinProps {
  partId?: number;
  bin?: IBin;
  excludeBinId?: number;
  label?: string;
  filter?: 'contains' | 'multiple' | 'possible';
  disabled?: boolean;
  onSet: (bin: IBin | undefined) => void;
}

export const SelectBin = ({ partId,
  bin,
  excludeBinId,
  onSet,
  label = 'Bin Identifier',
  filter = 'possible',
  disabled = false,
}: ISelectBinProps) => {

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [binIdentifier, setBinIdentifier] = useState('');
  const [validBin, setValidBin] = useState(true);
  const [bins, setBins] = useState<IBin[]>([]);
  const [filteredItems, setFilteredItems] = useState(bins);

  const filterFuncs = {
    // any bin that can take this part
    possible: (b: IBin) => (b.partId == partId || !b.partId || !partId) && b.binId != excludeBinId,
    // only bins w/o specific part
    multiple: (b: IBin) => !b.partId && b.binId != excludeBinId,
    contains: (b: IBin) => b.partId == partId && b.binId != excludeBinId,// TODO: get bins with qty of part.
  }

  const selectedChanged = ((changes: UseComboboxStateChange<IBin>) => {
    if (changes.selectedItem) {
      const newBinIdentifier = changes.selectedItem.binIdentifier;
      setBinIdentifier(newBinIdentifier);
      setValidBin(true);
      setFilteredItems(bins.filter(bin => `${bin.binIdentifier}`.toLowerCase().includes(newBinIdentifier.toLowerCase())));
      onSet(changes.selectedItem);
    }
  });


  const stateReducer = (state: DownshiftState<IBin>, changes: StateChangeOptions<IBin>): Partial<StateChangeOptions<IBin>> => {
    if (changes.type == Downshift.stateChangeTypes.blurInput && state.highlightedIndex != undefined) {
      return {
        ...changes,
        selectedItem: filteredItems[state.highlightedIndex],
        inputValue: filteredItems[state.highlightedIndex]?.binIdentifier ?? '',
        highlightedIndex: state.highlightedIndex,
        isOpen: false
      }

    }
    return changes;
  }


  const getBins = async () => {

    if (isLoading || !isAuthenticated) { return }

    if (partId) {
      const result = await API.get(getAccessTokenSilently(), `/api/v1/bin?partId=${partId}`)
        .catch(err => {
          Alert(AlertTypes.ERROR, 'Failed to load bins')
          return;
        });
      const bins = result.data as IBin[];
      setBins(bins.filter(filterFuncs[filter]));
    } else {
      const bins = await Bins.Instance().get(getAccessTokenSilently())
      setBins(bins.filter(filterFuncs[filter]));
    }
  }
  useEffect(() => {
    setBinIdentifier('');
    onSet(bin)
    getBins();
    setValidBin(true);
  }, [partId, bin])

  useEffect(() => {
    getBins();
  }, [excludeBinId])


  let labelClass = "";
  if (!validBin) {
    labelClass = 'uk-text-danger';
  } else if (disabled) {
    labelClass = 'uk-text-muted';
  }

  const {
    closeMenu,
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    selectItem,
  } = useCombobox({
    items: filteredItems,
    onInputValueChange: ({ inputValue }) => {
      setFilteredItems(bins.filter(bin => `${bin.binIdentifier}`.toLowerCase().includes((inputValue ?? '').toLowerCase())));
    },
    itemToString: item => item?.binIdentifier ?? '',
    onSelectedItemChange: selectedChanged,
  });

  return <div id="shiftsurround">
    <label {...getLabelProps()}>{label}</label>
    <br />
    <div
      style={{ display: 'inline-block' }}
    >
      <input id="selectbin" className="form-inline-input" {...getInputProps({
        /*
          onClick: event => {
                (event as any).preventDownshiftDefault = true;
                (event as any).nativeEvent.preventDownshiftDefault = true;
                (event as any).preventDefault();
                (event as any).stopPropagation();
          },
          onBlur: event => {
             (event as any).nativeEvent.preventDownshiftDefault = true;
             closeMenu();
             selectItem(bins[highlightedIndex]);
          },
          onKeyDown: event => {
            switch (event.key) {
              case 'Enter': {
                //    event?.preventDefault();
                (event as any).nativeEvent.preventDownshiftDefault = true;
              }
              case 'Tab': {
                (event as any).nativeEvent.preventDownshiftDefault = true;
                event.preventDefault();
                event.stopPropagation()
                event.nativeEvent.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
                closeMenu();
                selectItem(bins[highlightedIndex]);
                break;
              }
              default:
                break;
            }
          },
          */
      })} disabled={disabled} />
    </div>
    <ul id="bin-select-list" className="autocomplete-list" {...getMenuProps()}>
      {isOpen ? filteredItems
        //.filter(bin => !inputValue || `${bin.binIdentifier}`.toLowerCase().includes(inputValue.toLowerCase()))
        //            .filter(bin => `${bin.binIdentifier}`.toLowerCase().includes((inputValue ?? '').toLowerCase()))
        .map((item, index) => (
          <li
            {...getItemProps({
              key: item.binId,
              index,
              item,
              style: {
                backgroundColor:
                  highlightedIndex === index ? 'lightgray' : 'white',
                fontWeight: selectedItem === item ? 'bold' : 'normal',
              },
            })}
          >
            {item.binIdentifier}
          </li>
        ))
        : null}
    </ul>
  </div>
}