import React from 'react';

import {IFormData} from './FormTypes';
import {fieldNames} from './FormTypes';


type FormInputProps<D> = {
  label: string;
  name: fieldNames<D>;
  maxLen?: number;
  inline?: boolean;
  data: IFormData<D>;// & { [key: string]: string };
  changeFn: (d: fieldNames<D>, a: React.FormEvent<HTMLInputElement>) => void;
  blurFn?: (e: React.FocusEvent) => void;
  validation: (fieldNames<D>)[];
  disabled?: boolean;
}

export function FormInput<D>({ label, name, maxLen = 255, data, inline, changeFn, blurFn, validation, disabled = false }: FormInputProps<D>) {

  if (inline) {
    return <input type="text"
      className={"uk-input uk-form-small" + (validation.includes(name) ? " uk-form-danger" : "")}
      width="6"
      value={data[name]}
      maxLength={maxLen}
      placeholder={label}
      onBlur={blurFn ? (e) => blurFn(e) : undefined}
      onChange={(e) => changeFn(name, e)}
      disabled={disabled} />

  }
  return <p>
    <label className={validation.includes(name) ? 'uk-text-danger' : ''}>{label}:</label>
    <input type="text"
      className={"uk-input" + (validation.includes(name) ? " uk-form-danger" : "")}
      width="10"
      value={data[name]}
      maxLength={maxLen}
      onChange={(e) => changeFn(name, e)}
      onBlur={blurFn ? (e) => blurFn(e) : undefined}
      disabled={disabled}
    />
  </p>
}
