import React, { Fragment } from 'react';

type PopoverProps = {
  anchorEl?: Element;
  id: string;
  onClose: () => void;
  open: boolean;
  children?: React.ReactNode;
}
type BoxProps = {
  sx?: any;
  className?: string;
  children?: React.ReactNode;
}

type CheckboxProps = {
  checked?: boolean;
  label?: string;
  
}

type TableSortLabelProps = {
  active: boolean;
  direction: 'desc' | 'asc';
  icon: 'group' | 'sort';
  children?: React.ReactNode;
  style?: React.CSSProperties | undefined;
  onClick?: ((e: React.MouseEvent<Element, MouseEvent>) => void) | undefined;
}

export const Box: React.FC<BoxProps> = ({ sx, children }) => {
  return <div data-uk-panel>{children}</div>
}

/*
export const Popover: React.FC<PopoverProps> = ({ anchorEl, id, onClose, open, children }) => {
  
//  if (open) {
    return <div >{children}</div>
//  } else {
//    return <div className="filterPageX"/>
//  }
}
*/

export const Typography: React.FC<BoxProps> = ({ children }) => {
  return <span className="uk-text-muted">{children}</span>
}

export const Toolbar: React.FC<BoxProps> = ({ children }) => {
  return <nav className="uk-navbar-container" data-uk-navbar>
      {children}
  </nav>
}

export const Checkbox: React.FC<CheckboxProps> = ({...props}) => {
  return <input  className="uk-checkbox" type="checkbox" {...props} />
//      {children}
}

export const TableSortLabel: React.FC<TableSortLabelProps> = ( {
  active, direction, icon, onClick, children
}) => {
  const icons = {
    sort: {
      asc: 'triangle-up',
      desc: 'triangle-down',
    },
    group: {
      asc: 'triangle-left',
      desc: 'triangle-right',
    }
  }
  const ukIcon = icons[icon][direction];

  return (<div>
    {children}
    <span
    className={"columnSortIcon " + (active ? "columnSortActive" : "")}
    onClick={onClick}
    data-uk-icon={`icon: ${ukIcon}` }>
    </span>
    </div>
  );
}
  