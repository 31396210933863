//import { Box, Checkbox, FormControlLabel, Popover, Typography } from '@mui/material'
import React, { ReactElement } from 'react'
import type { TableInstance } from 'react-table'
import {Box, Typography} from '../utils/mui-shims'

interface ColumnHidePageProps<T extends Record<string, unknown>> {
  instance: TableInstance<T>
  anchorEl?: Element
  onClose: () => void
  show: boolean
}

const id = 'popover-column-hide'

export function ColumnHidePage<T extends Record<string, unknown>>({
  instance,
  anchorEl,
  onClose,
  show,
}: ColumnHidePageProps<T>): ReactElement | null {
  const { allColumns, toggleHideColumn } = instance
  const hideableColumns = allColumns.filter((column) => !(column.id === '_selector'))
  const checkedCount = hideableColumns.reduce((acc, val) => acc + (val.isVisible ? 0 : 1), 0)

  const onlyOneOptionLeft = checkedCount + 1 >= hideableColumns.length

  return hideableColumns.length > 1 ? (
    <div>
          <h3>Visible Columns</h3>
            {hideableColumns.map((column) => (
               <div
                  className={`columnSelect ${column.isVisible ? "" : "hidden"}  ${ (column.isVisible && onlyOneOptionLeft) ? " disabled" : ""}`}
                    onClick={() => !(column.isVisible && onlyOneOptionLeft) && toggleHideColumn(column.id, column.isVisible)}
                     >
                   { column.render('Header') }
                </div>

            ))}
    </div>
  ) : null
}
/*
              {hideableColumns.map(column => (<div>{column.render('Header')}</div>))}

              <FormControlLabel
                key={column.id}
                control={<Checkbox value={`${column.id}`} disabled={column.isVisible && onlyOneOptionLeft} />}
                label={column.render('Header') as ReactElement}
                checked={column.isVisible}
                onChange={() => toggleHideColumn(column.id, column.isVisible)}
              />
              */