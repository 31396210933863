
export enum PartAttrDataType  {
    Integer   = 'I',
    Number    = 'N',
    Character = 'C',
    String    = 'S'
}

export interface IPartAttributeDef {
  partAttrDefId: number;
  name:          string;
  description:   string;
  dataType:      PartAttrDataType;
  unitName:      string;
};

export interface IPartAttributeCategoryDef extends IPartAttributeDef {
  categoryId: number;
  required: boolean;
};





