import React, { Fragment, useContext, useEffect, useState } from "react";
import { FormInput, FormSelect, IFormData, fieldNames } from "src/components/Form";
import { SectionIconName } from "src/components/SectionIcon";
import { BaseObject, IAddEditContext, IBin, ILoadState, ILocation, IPart, IWarehouse, _get } from "src/types";
import API from '@sesame/web-api';
import { ItemPage } from "src/pages/ItemPage";
import { Parts } from "../Parts/Parts";
import { Warehouses } from "../Warehouses/Warehouses";
import { Locations } from "../Locations";
import PromiseText from "src/components/PromiseText";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "src/components/Spinner";


export const BinPage = () => {
  const b = Bins.Instance();

  return <ItemPage<Bins, IBin> itemClass={b} />
}

interface ILocationList {
  locationId: number;
  warehouseId: number;
  locationDescription: string;
  warehouseAbbrev: string;
}

export class Bins implements BaseObject<IBin> {

  private static _instance: Bins;
  private static _partAttributes: IBin[];
  private static _loadStatus: ILoadState = ILoadState.NEW;

  warehouses: IWarehouse[] = [];
  locations: ILocation[] = [];
  locationList: ILocationList[] = [];
  parts: IPart[] = []

  private constructor() { };

  static Instance() {
    if (!Bins._instance) {
      Bins._instance = new Bins();
    }
    return Bins._instance;
  }

  setLoadState = (newState: ILoadState) => { Bins._loadStatus = newState }
  getLoadState = () => Bins._loadStatus;
  onDataLoad = (data: IBin[]) => { Bins._partAttributes = data };

  public get = async (accessToken: string | Promise<string>) => {
    await _get(accessToken, Bins.Instance());
    return Bins._partAttributes;
  }

  public getItem = async (accessToken: string | Promise<string>, binId: number) => {
    const token = await Promise.resolve(accessToken);
    const bins = await Bins.Instance().get(token);
    const bin = bins.find(p => p.binId == binId);
    return bin;
  }

  public getBinByIdentifier = async (accessToken: string | Promise<string>, binIdentifier: string) => {
    const bins = await Bins.Instance().get(accessToken);
    const bin = bins.find(p => p.binIdentifier == binIdentifier);
    return bin;
  }

  loadDependentData = async (accessToken: string) => {
    if (this.parts.length) {
      return;
    }
    this.warehouses = await Warehouses.Instance().get(accessToken);
    this.locations = await Locations.Instance().get(accessToken);
    this.locationList = this.locations.map(l => {
      const w = this.warehouses.find(w => w.warehouseId == l.warehouseId);
      if (w) {
        return { locationId: l.locationId, warehouseId: w.warehouseId, locationDescription: l.description, warehouseAbbrev: w.warehouseAbbrev };
      }
      return { locationId: l.locationId, warehouseId: 0, locationDescription: l.description, warehouseAbbrev: '' }
    }).sort((a, b) => {
      if (a.warehouseAbbrev < b.warehouseAbbrev) return -1;
      if (a.warehouseAbbrev > b.warehouseAbbrev) return 1;
      if (a.locationDescription < b.locationDescription) return -1
      return 1;
    });

    this.parts = await Parts.Instance().get(accessToken);
  }


  getURL = () => '/api/v1/bin';
  emptyFormObject = () => {
    return {
      binId: '',
      binIdentifier: '',
      description: '',
      locationId: '',
      serialized: 'false',
      partId: '',
    }
  }
  primaryId = (bin: IBin) => bin.binId;
  renderListItem = (b: IBin) => {
    // TODO: Fix for locaiton/warehouse
    const { isLoading, getAccessTokenSilently } = useAuth0();
    const [location, setLocation] = useState<ILocation>();
    const [warehouse, setWarehouse] = useState<IWarehouse>();
    const l = Locations.Instance().getItem(getAccessTokenSilently(), b.locationId);
    const p = this.parts.find(p => p.partId == b.partId);

    if (isLoading) { return <Spinner /> }
    return <Fragment>
      {b.binIdentifier}
      {p && <span className="uk-text-muted">{`: ${p.partNumber}`}</span>}
      <br />
      <span className="uk-text-small uk-text-muted">
        {/*
        {warehouse?.warehouseAbbrev} : {location?.description}

        */}
        <PromiseText field="warehouseAbbrev">
          {l.then(loc => Warehouses.Instance().getItem(getAccessTokenSilently(), loc?.warehouseId ?? 0) )}
        </PromiseText>
        : <PromiseText field="description">{l}</PromiseText> 
        </span>
    </Fragment>
  }
  renderForm = (formData: IFormData<IBin>,
    formChange: any,
    validation: fieldNames<IBin>[],
    setValidation: any) => {

    return <form>
      <FormSelect
        label={"Location"}
        name={"locationId"}
        data={formData}
        blankChoice="(Select Location)"
        displayFn={() => this.locationList.map(l => {
          return {
            value: `${l.locationId}`, key: `${l.locationId}`,
            text: `${l.warehouseAbbrev}: ${l.locationDescription}`
          }
        })}
        changeFn={formChange}
        validation={validation}
        disabled={+formData.binId > 0}
      />
      <FormInput
        label="Identifier"
        name="binIdentifier"
        data={formData}
        changeFn={formChange}
        validation={validation}
        disabled={+formData.binId > 0}
      />
      {this.parts.length ?
        <FormSelect
          label="Part Id: (optional)"
          name="partId"
          data={formData}
          blankChoice="(Select Part)"
          displayFn={() => this.parts.map(p => {
            return {
              value: `${p.partId}`, key: `${p.partId}`,
              text: `${p.name} (${p.partCategoryId})`
            }
          })}
          changeFn={formChange}
          validation={validation}
          disabled={+formData.binId > 0}
        />
        : <span>Add parts to create specific bins</span>
      }
      {this.parts.findIndex(p => p.partId == +(formData.partId ?? 0) && p.serialized) >= 0 ?
        <FormSelect
          label="Serialized: (optional)"
          name="serialized"
          data={formData}
          changeFn={formChange}
          displayFn={() => [{ value: 'false', key: 'false', text: 'False' },
          { value: 'true', key: 'true', text: 'True' }]}
          validation={validation}
          disabled={+formData.binId > 0}
        />
        : <Fragment />}
      <FormInput
        label="Description"
        name="description"
        data={formData}
        changeFn={formChange}
        validation={validation}
      />
    </form>
  };

  toFormData = (b: IBin) => {
    return {
      binId: `${b.binId}`,
      binIdentifier: b.binIdentifier,
      locationId: `${b.locationId}`,
      description: b.description,
      partId: `${b.partId}`,
      serialized: b.serialized ? "true" : "false",
    }
  }

  fromFormData = (b: IFormData<IBin>) => {
    const bin: IBin = {
      binId: +b.binId,
      binIdentifier: b.binIdentifier,
      locationId: +b.locationId,
      description: b.description,
      serialized: b.serialized == "true",
    }
    if (b.partId && +b.partId > 0) {
      bin.partId = +b.partId;
    }
    return bin;
  }

  initialAddEditContext: IAddEditContext<IBin> = { setEditDataFn: () => { } };
  AddEditContext = React.createContext(this.initialAddEditContext);

  listName = "Bins";
  iconName = "location" as SectionIconName;
  primaryIdField = "binId";
  requiredFields = ['binIdentifier', 'locationId'];

  // MoveBin
  // Returns a promise to the MoveBin API call
  // promise resolves to success or err with err.message
  public static MoveBin(accessToken: string | Promise<string>, sourceBinId: number, targetLocationId: number) {
    return API.post(accessToken, `/api/v1/bin/${sourceBinId}/move/${targetLocationId}`, { timeout: 8000 })
  }
}
