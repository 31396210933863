import React, { useEffect, useState, useRef } from 'react';
import {Link } from 'react-router-dom';


import ListHeading from 'src/components/ListHeading';
import { useNavigate } from 'react-router-dom';
import { ScanSomething } from './ScanSomething';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from 'src/components/Spinner';

export function InventoryPage() {
  const {isLoading, isAuthenticated} = useAuth0();
  const nav = useNavigate();

  const partOps = [
    { icon: 'plus', path: '/addItems', text: 'Add Items' },
    { icon: 'move', path: '/moveItems', text: 'Move Items' },
    { icon: 'trash', path: '/removeItems', text: 'Dispose Items' },
  ];
  const binOps = [
    { icon: 'forward', path: '/moveBin', text: 'Move Bin' },
    { icon: 'bag', path: '/moveBinContents', text: 'Move Bin Contents'},
    { icon: 'check', path: '/reconcile', text: 'Reconcile Bin'},
  ];
//  const ops = [...partOps, ...binOps];

  if (isLoading) {
    return <Spinner />
  }
  if (!isAuthenticated) {
    return <h1>Not Authorized</h1>
  }
  return <div id="inventoryPage">

    <ListHeading listName="Inventory" iconName={'part'} />

    <nav className="uk-flex uk-flex-top uk-flex-wrap uk-padding-none"
        data-uk-toggle="cls: uk-flex-nowrap; mode: media; media: @s">

    <ul className='uk-navbar-nav uk-flex uk-flex-top'
        >
    {partOps.map( l=> l.icon && <li>
           <Link className="uk-text-nowrap" data-uk-icon={`icon: ${l.icon}`} to={l.path}>{l.text}</Link>
      </li>
      )}
    </ul>
    <ul className='uk-navbar-nav uk-flex uk-flex-top'
        >
    {binOps.map( l=> l.icon && <li>
           <Link className="uk-text-nowrap" data-uk-icon={`icon: ${l.icon}`} to={l.path}>{l.text}</Link>
      </li>
      )}
    </ul>
    </nav>
      <div className="uk-card uk-card-default">
        <ScanSomething />
      </div>
  </div>
}