import React, { useEffect, useState, useRef, Fragment, useMemo } from 'react';
import API from '@sesame/web-api';

import { ILoadState, IOrganization, IOrganizationSummary } from 'src/types';

import { useNavigate } from 'react-router-dom';
import { Spinner } from 'src/components/Spinner';
import { useAuth0 } from '@auth0/auth0-react';
import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import { setPriority } from 'os';
import { sum } from 'src/widgets/Table/Table/tableHookUtils';

export function OrganizationStatistics() {
  const { isAuthenticated, isLoading, user, getAccessTokenSilently } = useAuth0();
  const [organizations, setOrganizations] = useState<IOrganization[]>([])
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW)

  const getOrganizations = () => {
    if (!user) { return }
    setDisplayState(ILoadState.LOADING);
    getAccessTokenSilently()
      .then(auth => {
        API.get(auth, `/api/v1/organization`, {timeout: 6000})
          .then(res => {
            setOrganizations((res.data as IOrganization[])
              .sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
            setDisplayState(ILoadState.READY);
          })
          .catch(err => {
            Alert(AlertTypes.ERROR, err.message);
            setDisplayState(ILoadState.ERROR);
          })
      })
  }
  useEffect(() => {
    getOrganizations();
  }, [getAccessTokenSilently])

  if (isLoading) {
    return <div className="uk-container"><br /><Spinner /></div>
  }
  if (displayState == ILoadState.NEW || displayState == ILoadState.LOADING) {
    return <div className="uk-container"><br /><Spinner /></div>
  }

  if (displayState == ILoadState.ERROR) {
    return <div className="uk-container">
      <h2>Error</h2>
      <p>Something went wrong.  Please try a different operation</p>
    </div>
  }

  return <div className="uk-container">
    <h2>Organizations</h2>
    <ul className="uk-list">
      {organizations.map(o => <li>
        <div className="uk-box-shadow-medium"><h4>{o.name}</h4></div>
        <OrgSummary organizationId={o.organizationId} />
      </li>
      )}
    </ul>
  </div>
}

interface IOrgSummaryProps {
  organizationId: number;
}

const OrgSummary = ({ organizationId }: IOrgSummaryProps) => {

  const { isAuthenticated, isLoading, user, getAccessTokenSilently } = useAuth0();
  const [summary, setSummary] = useState<IOrganizationSummary>()
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW)


  const getOrganizationSummary = () => {
    setDisplayState(ILoadState.LOADING);
    getAccessTokenSilently()
      .then(auth => {
        API.get(auth, `/api/v1/organization/${organizationId}/summary`, {timeout: 6000})
          .then(res => {
            setSummary(res.data);
            setDisplayState(ILoadState.READY);
          })
          .catch(err => {
            setDisplayState(ILoadState.ERROR);

          })
      })
  }

  useEffect(() => {
    getOrganizationSummary();
  }, [getAccessTokenSilently])


  if (isLoading) {
    return <Spinner />
  }
  if (isLoading) {
    return <Spinner />
  }
  if (displayState == ILoadState.NEW || displayState == ILoadState.LOADING) {
    return <Spinner />
  }

  if (displayState == ILoadState.ERROR) {
    return <div>unable to load organization details</div>
  }

  return <div>
    <table className="uk-table uk-width-auto">
      <thead>
        <tr>
          <td>Users</td>
          <td>Parts</td>
          <td>Bins</td>
          <td>Total Items in Inventory</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{summary?.userCount}</td>
          <td>{summary?.partCount}</td>
          <td>{summary?.binCount}</td>
          <td>{summary?.totalItems}</td>
        </tr>
      </tbody>
    </table>
  </div>

}