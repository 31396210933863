import React, { useEffect, useState, useRef, Fragment } from 'react';
import API from '@sesame/web-api';

import { ILoadState } from 'src/types';

import { useNavigate } from 'react-router-dom';
import { Spinner } from 'src/components/Spinner';
import { useAuth0 } from '@auth0/auth0-react';
import { FormInput } from 'src/components/FormInput';
import { IOrganization } from 'src/types';
import { IFormData, fieldNames } from 'src/components/FormTypes';
import LoginLink from 'src/components/LoginLink';
import Button from 'src/components/Button';
import { FormCheckbox } from 'src/components/FormCheckbox';

import TandC from '../../../asset/resource/termsAndConditions.html';

let tAndCDoc = {__html: TandC}


interface INewOrganization {
  organizationName: string,
  warehouseName: string,
  warehouseAbbrev: string,
  userIdentity: string,
  tAndC: boolean,
}
type PageStates = 'getdata' | 'savingdata' | 'saved' | 'failed';

export function NewOrganization() {
  const { isAuthenticated, isLoading, user, getAccessTokenSilently } = useAuth0();
  const [validation, setValidation] = useState<fieldNames<INewOrganization>[]>([]);
  const [formData, setFormData] = useState<IFormData<INewOrganization>>({
    organizationName: '',
    warehouseName: '',
    warehouseAbbrev: '',
    userIdentity: '',
    tAndC: 'false',
  });
  const [displayState, setDisplayState] = useState<PageStates>('getdata')
  const navigate = useNavigate();

  const formChange = (fieldName: fieldNames<INewOrganization>,
    event: React.FormEvent<HTMLInputElement>) => {
    const newFormData: IFormData<INewOrganization> = { ...formData };
    if (event.currentTarget.type == 'checkbox') {
      newFormData[fieldName] = newFormData[fieldName] == 'true' ? '' : 'true';
    } else {
      newFormData[fieldName] = event.currentTarget.value;
    }
    setFormData(newFormData);
  }

  const validate = () => {
    const valid: fieldNames<INewOrganization>[] = [];
    const reqFields: fieldNames<INewOrganization>[] = ['organizationName', 'warehouseName', 'warehouseAbbrev', 'tAndC'];
    reqFields.forEach(d => { if (formData[d].trim() == '') { valid.push(d) } });

    setValidation(valid);
    return valid.length;
  }

  const saveOrg = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (validate()) {
      return;
    }
    if (!user || !user.sub) {
      return;
    }
    setDisplayState('savingdata');
    formData.userIdentity = user.sub;

    getAccessTokenSilently()
      .then((accessToken) => {
        return API.post(accessToken, '/api/v1/users/createOrganization', formData)
      })
      .then(result => {
        setDisplayState('saved');
      })
      .catch(err => {
        setDisplayState('failed');
        console.log(JSON.stringify(err));
      })
  }

  if (isLoading) {
    return <Spinner />;
  }
  
  if (user?.app_metadata?.organization_id) {
    navigate('/');
  }
  
  if (displayState == 'failed') {
    return <div className="uk-card uk-card-default uk-card-body">
      <h2>Error creating account</h2>
      Please try again later.
    </div>
  }
  if (displayState == 'savingdata') {
    return <div className="uk-card uk-card-default uk-card-body">
      <h2>Saving Data</h2>
      <Spinner />
      </div>
  }
  if (displayState == 'saved') {
    return <div className="uk-card uk-card-default uk-card-body">
      <h2>Account created</h2>
      <p>Your account has been created.   The organziation data you provide can be changed at any time.  You may also access the user section 
      to invite coworkers.
      </p>
      <LoginLink><Button>Continue</Button></LoginLink>
      </div>
  }
  
  if (!isAuthenticated) {
    return <div className="uk-card uk-card-default uk-card-body">
      <h2>Create Account</h2>
      <p>
      To get started, first create an account with the "Create Account" button.  Once created you'll be able to 
      set up your organization and start managing your inventory.
      </p>
      <LoginLink create={true} ><Button>Create Account</Button></LoginLink>
      </div>
  }
  
  return <div className="uk-container">
    <div className="uk-card uk-card-default uk-card-body">
      <h3>Organization Details</h3>
      <h4 className="alpha-alert">NOTICE:  This is ALPHA software and subject to change without notice.</h4>
      <p className="alpha-alert">This is intended to be a usable service, but no guarantee of availability or 
      data preervation is provided.  Future access may be limited or subject to payment.   Use at your own risk.   </p>
      <p>
        To get started, we need a few details about your organization.   We'll create some sample data.  Don't worry,
        you can always change this later.
      </p>
      <p>
        Let's walk you through it!
      </p>
      <form >
        <p>Select a name for your organization</p>
        <FormInput
          label="Organization Name"
          name="organizationName"
          maxLen={40}
          inline={false}
          data={formData}
          validation={validation}
          changeFn={formChange}
        />
        {formData.organizationName.length > 3 && <div className="uk-animation-fade">
          <p>
            Enter the name for your first warehouse.
          </p>
          <FormInput
            label="Warehouse Name"
            name="warehouseName"
            inline={false}
            data={formData}
            validation={validation}
            changeFn={formChange}
          />
        </div>}
        {formData.warehouseName.length > 3 && <div className="uk-animation-fade">
          <p>Select a short (3 or 4 character) abbreviation we can use to reference the warehouse.</p>
          <FormInput
            label="Warehouse Abbreviation"
            inline={false}
            maxLen={10}
            name="warehouseAbbrev"
            data={formData}
            validation={validation}
            changeFn={formChange}
          />
        </div>}
      </form>
      {formData.warehouseAbbrev.length > 2 &&
        <Fragment>
        <div>
        <FormCheckbox
          label=""
          inline={true}
          name="tAndC"
          data={formData}
          validation={validation}
          changeFn={formChange}
          />
          I accept the <a data-uk-toggle="target: #tandc">terms and conditions</a>
        </div>
        <div id="tandc" data-uk-modal>
          <div className="uk-modal-dialog uk-modal-body"> 
          <h2>Terms and Conditions</h2>
          <div  dangerouslySetInnerHTML={tAndCDoc} />
          <button className="uk-modal-close uk-button-primary" type="button">Ok</button>
          </div>
        </div>
        <br />
        <button
          className="uk-button uk-button-primary uk-button-small uk-animation-fade"
          disabled={formData.tAndC != 'true'}
          onClick={e => saveOrg(e)} >Create</button>
        </Fragment>
      }
    </div>
  </div>
}