import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import sesameLogo from '../assets/images/flax-logo.svg';

// import SVG from 'react-inlinesvg';


const SesameLogo = (props: React.HTMLAttributes<HTMLImageElement>) =>  {
  const navigate = useNavigate();
  return <a onClick={() => navigate('/')}><img src={sesameLogo} alt="Sesame Logo" width="180px"
    className="uk-margin-left uk-margin-botton" /></a>
}

export default SesameLogo;
