
import { string } from 'prop-types';
import * as React from 'react';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

interface IPromiseTextProps {
  children: Promise<string> | string | Promise<object> | object;
  field?: string
}

const PromiseText = ({ children, field }: IPromiseTextProps) => {

  const [text, setText] = useState<string | undefined>('...');

  useEffect(() => {
    Promise.resolve(children).then( child => {
      if (typeof child == 'string') {
        setText(child);
      } else {
        if (field && child.hasOwnProperty(field)) {
          setText((child as any)[field])
        } else {
          setText('Key?');
        }
      }
    })
  }, [children]);

  return <Fragment>{text}</Fragment>;
}

export default PromiseText;
