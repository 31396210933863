import React, { Fragment } from 'react';

interface FormSelectProps<D> {
  label: string;
  name: fieldNames<D>;
  data: D & { [key: string]: string }
  inline?: boolean;
  blankChoice?: string;
  displayFn: () => Array<{ value: string, key: string, text: string }>;
  changeFn: (d: fieldNames<D>, a: any) => void
  validation: fieldNames<D>[];
  disabled?: boolean;
}

type fieldNames<F> = React.ReactText;

export function FormSelect<D>({ label, name, data, inline, blankChoice, displayFn, changeFn, validation, disabled=false }: FormSelectProps<D>) {
  if (inline) {
    return <select
      value={data[name]}
      className={"uk-input uk-form-small" + (validation.includes(name) ? " uk-form-danger" : "")}
      onChange={(e) => changeFn(name, e)}
      disabled={disabled}
      >
      {blankChoice !== undefined ? <option value="">{blankChoice}</option> : <Fragment />}
      {displayFn().map(({ value, key, text }) => <option value={value} key={key}>{text}</option>)}
    </select>
  }
  return <p>
    <label className={validation.includes(name) ? 'uk-text-danger' : ''}>{label}:</label>
    <select className="uk-input"
      value={data[name]}
      onChange={(e) => changeFn(name, e)}
      disabled={disabled}
      >
      {blankChoice !== undefined ? <option value="">{blankChoice}</option> : <Fragment />}
      {displayFn().map(({ value, key, text }) => <option value={value} key={key}>{text}</option>)}
    </select>
  </p>
}
