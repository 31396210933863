import React, { useEffect, useState, useRef } from 'react';

import ImgCover1 from '../assets/images/barcode-scan-tbr.jpg';
import ImgCover2 from '../assets/images/inventory-aisle.jpg';
import ImgCover3 from '../assets/images/woman-scanning-tbr.png';
import ImgCover4 from '../assets/images/woman-warehouse-1-tbr.jpg';

export default function Index() {
  
  return <React.Fragment>
    <div className="uk-position-relative uk-visible-toggle uk-light uk-position-cover"
        tabIndex={-1}
        data-uk-slideshow="autoplay: true; autoplay-interval: 4000; pause-on-hover: true; ratio: 7:3" >

    <ul className="uk-slideshow-items">
        <li>
            <img src={ImgCover1} alt="" data-uk-cover />
            <div className="uk-position-center uk-padding-large uk-text-large uk-overlay">
            <h1>Sail Ho!</h1>
            Row scuttle parrel provost shrouds spirits
            boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter
            crow's nest nipperkin grog yardarm hempen halter furl. Swab barque
            interloper chantey doubloon starboard grog black jack gangway rutters.
            </div>
        </li>
        <li>
            <img src={ImgCover2} alt="" data-uk-cover />
            <div className="uk-position-center">
            <h1>Direct Revenue Enhancment</h1>
            A straight line may be the shortest distance between two
            points, but it is by no means the most interesting. 
            </div>
        </li>
        <li>
            <img src={ImgCover3} alt="" data-uk-cover />
            <div className="uk-position-center">
            <h1>All sorts of parts</h1>
              In Craven omni memoria patriae zombieland clairvius narcisse
              religionis sunt diri undead historiarum.
            </div>
        </li>
        <li>
            <img src={ImgCover4} alt="" data-uk-cover />
            <div className="uk-position-center">
            <h1>Look to the stars</h1>
            The only home we’ve ever known preserve and cherish that
            pale blue dot. Cosmic fugue, circumnavigated descended from
            astronomers decipherment
            </div>
        </li>
    </ul>
    <ul className="uk-slideshow-nav uk-dotnav uk-flex-center uk-position-bottom-center uk-margin" />
</div>
<div className="uk-background-primary uk-light uk-padding">
  Inventory Systems copyright Flax Advisors 2023
  <br />
</div>
</React.Fragment>

}
