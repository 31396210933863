import * as React from 'react';
import  {SectionIcon, SectionIconName } from './SectionIcon';

interface IOverviewCardProps {
    iconName: SectionIconName;
    children: JSX.Element;
}

export function OverviewCard({ iconName, children }: IOverviewCardProps) {
    return <div>
        <div className="uk-card uk-card-default uk-card-body">
            <div className="uk-flex uk-flex-left">
                <div className="uk-margin-medium-left uk-margin-medium-right">
                    <SectionIcon iconName={iconName} width={100} height={100} />
                </div>
                <div className="uk-margin-medium-left uk-margin-medium-right">
                    {children}
                </div>
            </div>
        </div>
    </div>

}

