import { maxHeaderSize } from 'http'
import React, { Fragment, PropsWithChildren, ReactElement, useCallback } from 'react'
import type { TableInstance } from 'react-table'

//TOFIX: const useStyles = makeStyles()((theme: Theme) => ({
/*
const useStyles = makeStyles()( () => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}))
*/

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

function TablePaginationActions(props: TablePaginationActionsProps) {
//  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className="reportPagination">
      <button
        data-uk-icon="chevron-double-left"
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page' />
      <button
        data-uk-icon="chevron-left"
        onClick={handleBackButtonClick}
        disabled={page === 0}
       aria-label='previous page' />

      <span className="paginationPage">
      {page * rowsPerPage + 1} - {Math.min(count, (page + 1) * rowsPerPage)} of {count}
      </span>

      <button
        data-uk-icon="chevron-right"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      />
      <button
        data-uk-icon="chevron-double-right"
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      />
    </div>
  )
}

export function TablePagination<T extends Record<string, unknown>>({
  instance,
}: PropsWithChildren<{ instance: TableInstance<T> }>): ReactElement | null {
  const {
    state: { pageIndex, pageSize, rowCount = instance.rows.length },
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = instance

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
      if (newPage === pageIndex + 1) {
        nextPage()
      } else if (newPage === pageIndex - 1) {
        previousPage()
      } else {
        gotoPage(newPage)
      }
    },
    [gotoPage, nextPage, pageIndex, previousPage]
  )

  const rowsPerPageOptions = [25, 100, 250, 1000];
  
  return rowCount ? (<div className="reportPagination">
    <div>
    Rows per page: {pageSize} <div className="pageSizeButton" data-uk-icon="triangle-down" />
    <ul className="pageSizeList" data-uk-dropdown="mode: click">
      {rowsPerPageOptions.map(  p => (
        <li className={`uk-nav ${p == pageSize ? "selected" : ""}`} onClick={() => setPageSize(p)} data-uk-toggle="target: .pageSizeList">{p}</li>
      ))}
    </ul>
    </div>
    <TablePaginationActions
        count={rowCount}
        page={pageIndex}
        rowsPerPage={pageSize}
        onPageChange={handleChangePage}
    />
  </div>
  )
 : ( <Fragment />)
}