import { useAuth0 } from "@auth0/auth0-react";
import API from "@sesame/web-api";
import React, { Fragment, useEffect, useState } from "react";
import Alert, { AlertTypes } from "src/components/AlertDisplay";
import Button from "src/components/Button";
import ListHeading from "src/components/ListHeading";
import { Spinner } from "src/components/Spinner";
import { ILoadState, IUser, IUserRoles } from "src/types";

interface IUserListProps {
  role: keyof IUserRoles;
}

const roleData: IUserRoles = {
  adminUser: { name: "Organization Admin" },
  inventoryUser: { name: "User" },
  billingUser: { name: "Billing Managers" },
}

export const UserList = ({ role }: IUserListProps) => {

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [userList, setUserList] = useState<IUser[]>([]);
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW);

  const roleName = roleData[role];

  const getUserList = () => {
    getAccessTokenSilently()
      .then(auth => {
        API.get(auth, `/api/v1/users?role=${role}`, {timeout: 20000})
          .then(data => {
            const users = data.data as IUser[];
            setUserList(users);
            setDisplayState(ILoadState.READY);
          })
          .catch(err => {
            setDisplayState(ILoadState.ERROR);
            Alert(AlertTypes.ERROR, err.message);
          })
      })
  }

  useEffect(() => {
    setDisplayState(ILoadState.LOADING);
    getUserList();
  }, [isAuthenticated, getAccessTokenSilently]);

  if (isLoading) {
    return <Spinner />
  }
  if (!isAuthenticated) {
    return <Fragment />
  }
  return <div>
    <h2>{roleData[role].name}</h2>
    {displayState == ILoadState.ERROR && <div>Error loading users</div> }
    {displayState == ILoadState.LOADING && <Spinner />}
    {displayState == ILoadState.READY &&
    <ul className="uk-list">
      {userList.sort((a, b) => a.email < b.email? -1 : 1).map(u => (
        <li key={u.userId}>
          <div className="uk-grid uk-card uk-card-hover">
            <div className="uk-width-expand">
              {u.email}
            </div>
            <div className="uk-width-auto">
              <ul className="uk-iconnav">
                <li><a data-uk-icon="mail" data-uk-tooltip="Resend" /></li>
                <li><a data-uk-icon="trash" data-uk-tooltip="Delete" /></li>
              </ul>
            </div>
          </div>
        </li>
      ))}
    </ul>
    }
  </div>
}


