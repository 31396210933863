import React, { Fragment } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink } from 'react-router-dom';
import { hideDropDown } from 'src/util/UIUtil';


const PrimaryNav = () => {
  const { isAuthenticated } = useAuth0();
  if (! isAuthenticated) {
    return <Fragment />
  }
  
  return (
    <Fragment>
      <nav className="uk-navbar-container uk-flex-column uk-flex-top uk-padding-none" data-uk-navbar data-uk-toggle="media: @s; cls: uk-flex-row uk-flex-top; mode: media">
        <button data-uk-toggle="target: .navbar-collapse; cls: uk-visible@s uk-padding-remove"
          className="uk-navbar-toggle uk-padding uk-padding-remove-vertical uk-hidden@s" data-uk-navbar-toggle-icon></button>
        <div className="navbar-collapse uk-visible@s">
          <div className="uk-navbar-left" >
            &nbsp;
            <ul data-uk-toggle="media: @s; cls: uk-navbar-nav uk-padding-remove; mode: media" className="uk-nav uk-navbar-nav uk-padding-remove uk-navbar-dropdown-nav uk-text-uppercase">
              <li>
                <NavLink to="/inventory" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Inventory</NavLink>
              </li>
              <li>
                <NavLink to="/reports" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Reports</NavLink>
              </li>
              <li>
                <NavLink to="/parts" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Parts</NavLink>
              </li>
              <li>
                <NavLink to="/bins" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Bins</NavLink>
              </li>
              <li>
                <NavLink to="/locations" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Locations</NavLink>
              </li>
              <li>
                <NavLink to="/warehouses" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Warehouses</NavLink>
              </li>
              { /* <NavItem name="Reports" path="/reports" /> */}
              <li className={'uk-parent' + (window.location.pathname.match(/\/Admin/) && ' uk-active')}>
                <a href="#">Admin <span data-uk-navbar-parent-icon></span></a>
                <div id="settingsDropdown" uk-dropdown="pos: bottom-left; duration: 10"
                  className="uk-margin-remove-top uk-padding-remove-top uk-navbar-dropdown-nav uk-text-uppercase">
                  <ul className="uk-nav-sub">
                    <li onClick={() => hideDropDown('settingsDropdown')}>
                      <NavLink to="/PartCategories" className={({ isActive }) => isActive ? "uk-active" : undefined}>Part Categories</NavLink>
                    </li>
                    <li onClick={() => hideDropDown('settingsDropdown')}>
                      <NavLink to="/PartAttributes" className={({ isActive }) => isActive ? "uk-active" : undefined}>Part Attributes</NavLink>
                    </li>
                    <li onClick={() => hideDropDown('settingsDropdown')}>
                      <NavLink to="/manufacturers" className={({ isActive }) => isActive ? "uk-active" : undefined}>Manufacturers</NavLink>
                    </li>
                    <li onClick={() => hideDropDown('settingsDropdown')}>
                      <NavLink to="/movementReasons" className={({ isActive }) => isActive ? "uk-active" : undefined}>Movement Reasons</NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>

    </Fragment >
  );
};

export default PrimaryNav;
