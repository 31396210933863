import React, { Fragment, useContext, useEffect, useState } from 'react';
import * as UIkit from '../../assets/uikit/js/uikit';

import API from '@sesame/web-api';

import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import { Bins } from '../Bins/Bin';
import { Parts } from '../Parts/Parts';
import { SelectBin } from './widgets/SelectBin';
import { IBin, IInventoryAction, IInventoryActionDispose, IInventoryLocation, IPart, MovementReasonType } from 'src/types';
import { SelectMovementReason } from './widgets/SelectMovementReason';
import { ScanActionPanel } from './ScanActionPanel';
import { useAuth0 } from '@auth0/auth0-react';

interface SerialNumberActionProps {
  inventoryItem: IInventoryLocation;
  onChange: () => void;
}

export function SerialNumberAction({ inventoryItem, onChange }: SerialNumberActionProps) {

  const [part, setPart] = useState<IPart>();
  const [bin, setBin] = useState<IBin>();
  const [toBin, setToBin] = useState<IBin>();
  const [movementReasonId, setMovementReasonId] = useState(0);
  //  const [movementReasons, setMovementReasons] = useState<IMovementReason[]>([]);
  //

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  if (isLoading || !isAuthenticated) { return <Fragment /> }

  useEffect(() => {
    Parts.Instance().get(getAccessTokenSilently())
      .then(parts => {
        const foundPart = parts.find(p => p.partId == inventoryItem.partId)
        setPart(foundPart);
      });
    Bins.Instance().get(getAccessTokenSilently())
      .then(bins => {
        const foundBin = bins.find(b => b.binId == inventoryItem.binId);
        setBin(foundBin);
      });
    //MovementReasons.Instance().get(getAccessTokenSilently())
    //  .then(reasons => setMovementReasons(reasons))
  }, [inventoryItem.serialNumber])

  const handleMove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!toBin) {
      Alert(AlertTypes.ERROR, 'No bin specified');
      return;
    }
    const payload: IInventoryAction = {
      partId: inventoryItem.partId,
      fromBin: inventoryItem.binId,
      toBin: toBin.binId,
      serialNumber: inventoryItem.serialNumber,
    }
    if (movementReasonId != 0) {
      payload.reasonId = movementReasonId;
    }
    API.post(getAccessTokenSilently(), '/api/v1/inventory/transfer', payload)
      .then(result => {
        Alert(AlertTypes.SUCCESS, 'Moved');
        const el = document.getElementById('movePane');
        (UIkit as any).drop(el).hide();
        onChange();
      })
      .catch(err => {
        Alert(AlertTypes.ERROR, err.message);
      })
  }

  const handleDispose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const payload: IInventoryActionDispose = {
      partId: inventoryItem.partId,
      fromBin: inventoryItem.binId,
      serialNumber: inventoryItem.serialNumber,
    }
    if (movementReasonId != 0) {
      payload.reasonId = movementReasonId;
    }
    API.post(getAccessTokenSilently(), '/api/v1/inventory/dispose', payload)
      .then(result => {
        Alert(AlertTypes.SUCCESS, 'Removed');
        const el = document.getElementById('removePane');
        (UIkit as any).drop(el).hide();
        onChange();
      })
      .catch(err => {
        Alert(AlertTypes.ERROR, err.message);
      })
  }

  return <div className="uk-card uk-card-default">
    <div className="uk-inline uk-padding">
      <ScanActionPanel id="move" onConfirm={handleMove} >
        <SelectBin partId={part?.partId} bin={toBin} onSet={setToBin} label="New Bin" />
        <br />
        <SelectMovementReason movementReasonId={movementReasonId} type={MovementReasonType.Transfer} onSet={setMovementReasonId} />
      </ScanActionPanel>

      <ScanActionPanel id="dispose" onConfirm={handleDispose} danger={true} >
        <SelectMovementReason movementReasonId={movementReasonId} type={MovementReasonType.Remove} onSet={setMovementReasonId} />
      </ScanActionPanel>

      <dl className="uk-list">
        <dd>
          Part:</dd><dt> {part?.partNumber ?? "unknown"}
        </dt>
        <dd>
          Serial Number:</dd><dt> {inventoryItem.serialNumber}
        </dt>
        <dd>
          Bin:</dd><dt> {bin?.binIdentifier ?? "unknown"}
        </dt>
      </dl>
    </div>
  </div>
}