import { useAuth0 } from '@auth0/auth0-react';
import API from '@sesame/web-api';
import React, {useState, Fragment, useEffect} from 'react';
import { Spinner } from 'src/components/Spinner';

import { Parts } from 'src/pages/Parts/Parts';
import { IInventoryLocation, ILoadState } from 'src/types';

interface IInventoryListProps {
  binIdentifier?: string;
  setParts?: (partIds: number[]) => void;
  reloadKey?: number;
}
interface IInventoryListItem extends IInventoryLocation {
  partNumber: string;
  revisionNumber: string;
  checked: boolean;
}

export const InventoryList = ({ binIdentifier, setParts, reloadKey = 0 }: IInventoryListProps) => {
  const [displayState, setDisplaySate] = useState<ILoadState>(ILoadState.NEW);
  const [inventory, setInventory] = useState<IInventoryListItem[]>([]);

    const {isLoading, isAuthenticated, getAccessTokenSilently} = useAuth0();
  if (isLoading || !isAuthenticated) { return <Fragment /> }

  const updateParts = () => {
    if (!setParts) {
      return;
    }
    const partIds = inventory.filter(i => i.checked).map(i => i.partId);
    setParts(partIds);
  }

  useEffect(() => {
    if (!binIdentifier) {
      return;
    }
    API.get(getAccessTokenSilently(), `/api/v1/inventory?binIdentifier=${binIdentifier}`)
      .then(async (result) => {
        const invResult = result.data.inventory as IInventoryLocation[];
        const inv: IInventoryListItem[] = [];
        const parts = await Parts.Instance().get(getAccessTokenSilently());
        invResult.map(i => {
          const p = parts.find(p => p.partId == i.partId);
          const ii = inv.find(il => il.partId == i.partId)
          if (ii) {
            ii.quantity += i.quantity;
          } else {
            inv.push({ ...i, partNumber: p?.partNumber ?? '', revisionNumber: p?.revisionNumber ?? '', checked: false });
          }
        });
        setInventory(inv);
        setDisplaySate(ILoadState.READY);
      })
    setDisplaySate(ILoadState.LOADING);
  }, [binIdentifier, reloadKey]);

  if (!binIdentifier)  {
    return <Fragment />
  }
  if (displayState == ILoadState.NEW || displayState == ILoadState.LOADING) {
    return <Spinner />
  }
  if (displayState == ILoadState.ERROR) {
    return <p>Error loading inventory data</p>
  }

  return <Fragment>
    <form>
      <table className="uk-table uk-table-small">
        <thead>
          <tr>
            {setParts ? <th></th> : <Fragment />}
            <th>Part</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {inventory.map(i => <tr>
            {setParts ? <td><input type="checkbox" className="uk-checkbox"
              checked={i.checked} onClick={e => { i.checked = !i.checked; updateParts() }} /></td> :
              <Fragment />}
            <td>
              {i.partNumber}:{i.revisionNumber}
            </td>
            <td className="uk-text-right">
              {i.quantity}
            </td>
          </tr>)}
        </tbody>
      </table>
    </form>
  </Fragment>
}