import { getEventListeners } from 'events';
import React, { Fragment, ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import * as UIkit from '../assets/uikit/js/uikit';

interface IModalDialogProps {
  children?: ReactNode;
  id: string;
  buttonText: string;
  title: string;
  icon?: string;
  clickFn?: (isOpen: boolean) => void;
}

const ModalDialog = (props: IModalDialogProps) => {
  const divId = `${props.id}-div`;

  const onClickFn = (isOpen: boolean) => {
    props.clickFn ? props.clickFn(isOpen) : (x: boolean) => { };
  }
  


  return <span id="modalspan">
    {props.icon ?
      <a id={props.id}
        className="" data-uk-icon={props.icon}
        data-uk-toggle={`target: #${divId}`} data-type="button">
      </a>
      :
      <button id={props.id}
        className="uk-button uk-button-primary " data-uk-toggle={`target: #${divId}`} data-type="button"
          onClick={(e) => { e.preventDefault(); onClickFn(true) }}>
        {props.buttonText}
      </button>
    }
      <ModalPortal divId={divId}> 
        <div id={`${divId}-div`} className="uk-modal-dialog uk-modal-body">
          <button className="uk-modal-close-outside uk-align-right" data-uk-close data-uk-toggle={`target: #${divId}`} 
          onClick={(e) => { e.preventDefault(); onClickFn(false) }}/>

          <h2 className="uk-modal-title">{props.title}</h2>
          {props.children}
        </div>
      </ModalPortal> 
  </span>
}

export const modalClose = (id: string) => {
    const el = document.getElementById(`${id}-div`);
    (UIkit as any).modal(el).hide();
}

const ModalPortal = (props: {
  divId: string,
  children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined;
}) => {

  const [instancekey, _] = useState(Math.round(Math.random()*10000))

  let modalRoot = document.getElementById(props.divId);
  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.setAttribute('uk-modal', 'bg-close: false');
    modalRoot.id = props.divId;
  }

  useEffect(() => {
    if (!modalRoot) {
      console.log("!! No root");
      return;
    }
    document.body.appendChild(modalRoot);

    /*
    const el = document.getElementById(`${props.divId}-div`);
    if (el) {
      for (const key in el) {
        if (/^on/.test(key)) {
          const eventType = key.substr(2);
          el.addEventListener(eventType, (e) => console.log(`!M ${eventType}`))
        }
      }
      el.addEventListener('keydown', event => {
        console.log('!!keydown event');
      });
      el.addEventListener('close', (e) => {
        console.log('M!! close: ' + instancekey )
      })
      el.addEventListener('change', (e) => e.stopPropagation());
    }
    */
    return () => {
      if (modalRoot) {
        document.body.removeChild(modalRoot);
      }
    }
  }) 

  return ReactDOM.createPortal(props.children, modalRoot);
}

export default ModalDialog;
