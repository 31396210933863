import React, { Fragment, useEffect, useImperativeHandle, useState } from 'react';

import API from '@sesame/web-api';

import { IBin, ILoadState, MovementReasonType } from 'src/types';
import { IInventoryRecon, ReconRefObject } from './types';
import { Parts } from 'src/pages/Parts/Parts';
import { Spinner } from 'src/components/Spinner';
import { SelectMovementReason } from '../widgets/SelectMovementReason';
import { useAuth0 } from '@auth0/auth0-react';



interface ReconCommitProps {
  bin: IBin;
  qtyParts: IInventoryRecon[],
  serParts: IInventoryRecon[],
  onComplete: (full: boolean) => void;
}


export const ReconCommit = React.forwardRef(({ bin, qtyParts, serParts, onComplete }: ReconCommitProps,
  ref: React.Ref<ReconRefObject>) => {

  const [addReason, setAddReason] = useState(0);
  const [removeReason, SetRemoveReason] = useState(0);
  const [addParts, setAddParts] = useState<Map<string, number>>();
  const [subParts, setSubParts] = useState<Map<string, number>>();
  const [displayState, setDisplaySate] = useState<ILoadState>(ILoadState.NEW);
  const [saveState, setSaveState] = useState<ILoadState>(ILoadState.NEW);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();


  // Provide completion state to caller to do context aware refresh
  useImperativeHandle(ref, () => ({ getSaveState }));
  function getSaveState() { return saveState }

  const doCommit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // TODO - case where serialized item was moved w/o being recorded.  Correct to remove it frmo here, but 

    if (saveState == ILoadState.NEW) {
      setSaveState(ILoadState.LOADING);
      let ok = true;
      const accessToken = await getAccessTokenSilently();
      for (const p of qtyParts) {
        if (p.serialNumber) {
          if (p.reconQty > p.quantity) {
            await API.post(accessToken, '/api/v1/inventory/add', {
              partId: p.partId,
              toBin: bin.binId,
              quantity: 1,
              serialNumber: p.serialNumber,
              reasonId: addReason > 0 ? addReason : undefined,
              comment: 'Reconcile Event',
            })
              .catch(err => { ok = false; setSaveState(ILoadState.ERROR) });
          } else if (p.reconQty < p.quantity) {
            await API.post(accessToken, '/api/v1/inventory/dispose', {
              partId: p.partId,
              fromBin: bin.binId,
              quantity: 1,
              serialNumber: p.serialNumber,
              reasonId: removeReason > 0 ? addReason : undefined,
              comment: 'Reconcile Event',
            })
              .catch(err => { ok = false; setSaveState(ILoadState.ERROR) });
          }
        } else {
          if (p.reconQty > p.quantity) {
            await API.post(accessToken, '/api/v1/inventory/add', {
              partId: p.partId,
              toBin: bin.binId,
              quantity: p.reconQty - p.quantity,
              reasonId: addReason > 0 ? addReason : undefined,
              comment: 'Reconcile Event',
            })
              .catch(err => { ok = false; setSaveState(ILoadState.ERROR) });
          } else if (p.reconQty < p.quantity) {
            await API.post(accessToken, '/api/v1/inventory/dispose', {
              partId: p.partId,
              fromBin: bin.binId,
              quantity: p.quantity - p.reconQty,
              reasonId: removeReason > 0 ? addReason : undefined,
              comment: 'Reconcile Event',
            })
              .catch(err => { ok = false; setSaveState(ILoadState.ERROR) });
          }
        }
      }
      if (ok) {
        setSaveState(ILoadState.READY);
      }
    }
  }

  if (isLoading || !isAuthenticated) {
    return <Fragment />;
  }

  const loadData = async () => {

    let addPartTotals = new Map<number, number>();
    [...qtyParts, ...serParts].filter(qp => qp.reconQty > qp.quantity)
      .map(qp => addPartTotals.set(qp.partId, (addPartTotals.get(qp.partId) ?? 0) + qp.reconQty - qp.quantity))
    let addDisplay = new Map<string, number>();
    const accessToken = await getAccessTokenSilently();
    for (const t of addPartTotals.keys()) {
      const part = await Parts.Instance().getItem(accessToken, t);
      if (part) {
        addDisplay.set(part.partNumber, (addPartTotals.get(t) ?? 0) +
          (addDisplay.get(part.partNumber) ?? 0))
      }
    }
    setAddParts(addDisplay);

    let subPartTotals = new Map<number, number>();
    [...qtyParts, ...serParts].filter(qp => qp.reconQty < qp.quantity)
      .map(qp => subPartTotals.set(qp.partId, (subPartTotals.get(qp.partId) ?? 0) + qp.quantity - qp.reconQty))
    let subDisplay = new Map<string, number>();
    for (const t of subPartTotals.keys()) {
      const part = await Parts.Instance().getItem(accessToken, t);
      if (part) {
        subDisplay.set(part.partNumber, (subPartTotals.get(t) ?? 0) +
          (subDisplay.get(part.partNumber) ?? 0))
      }
    }
    setSubParts(subDisplay);


    setDisplaySate(ILoadState.READY);
  }

  useEffect(() => {
    setDisplaySate(ILoadState.LOADING);
    setSaveState(ILoadState.NEW);
    loadData();
  }, [qtyParts, serParts, isAuthenticated, isLoading])

  if (displayState == ILoadState.NEW || displayState == ILoadState.LOADING) {
    return <Spinner />
  }
  if (saveState != ILoadState.NEW) {
    return <div>
      <h3>Recording Updates</h3>
      <br />
      {saveState == ILoadState.LOADING && <Spinner />}
      {saveState == ILoadState.READY && <div><h3 className="uk-text-success uk-text-center">Complete!</h3>
        <button className="uk-button uk-button-primary" onClick={e => { e.preventDefault(); onComplete(true) }}>
          Ok
        </button></div>}
      {saveState == ILoadState.ERROR && <div><h3 className="uk-text-danger uk-text-center">Error updating bin</h3></div>}
    </div>
  }

  return <form className="inventory-form">
    <h3>Additions</h3>
    {addParts && addParts.size > 0 && <div>
      <SelectMovementReason
        movementReasonId={addReason}
        type={MovementReasonType.Add}
        onSet={setAddReason}
      />
      <br />

      {[...addParts.entries()].map(ap => <span>{ap[0]} - {ap[1]}<br /></span>)}

      <br />
    </div>}
    {(!addParts || addParts.size == 0) && <div>None<br /></div>}

    <h3>Removals</h3>
    {subParts && subParts.size > 0 && <div>
      <SelectMovementReason
        movementReasonId={removeReason}
        type={MovementReasonType.Remove}
        onSet={SetRemoveReason}
      />
      <br />
      {[...subParts.entries()].map(sp => <span>{sp[0]} - {sp[1]}<br /></span>)}
      <br />
    </div>}
    {(!subParts || subParts.size == 0) && <div>None<br /><br /></div>}
    <br />
    {((addParts && addParts.size > 0) || (subParts && subParts.size > 0)) && <button onClick={e => doCommit(e)}>Record</button>}
  </form>
});
