import * as React from 'react';

interface IButtonProps {
  style?: ('primary' | 'secondary' | 'danger' | 'default');
  className?: string;
  children?: React.ReactNode;
}

const Button = ({ children, style, className }: IButtonProps) => {
  style ??= 'default';
  const styleText = `${className} uk-button uk-button-${style}`;
  return <button className={styleText}>{children}</button>
};
export default Button
