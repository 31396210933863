
import React, { Fragment, useEffect, useRef, useState } from "react";

import Alert, { AlertTypes } from "src/components/AlertDisplay";
import ModalDialog from "src/components/ModalDialog";
import PromiseText from "src/components/PromiseText";
import { SelectPart } from "../widgets/SelectPart";
import { Parts } from "src/pages/Parts/Parts";
import { IBin, ILoadState, IPart } from "src/types";

import { IInventoryExist, IInventoryRecon, ReconRefObject } from "./types";
import { ReconCommit } from "./ReconCommit";
import { ReconQty } from "./ReconQty";
import { ReconStateIcon } from "./ReconStateIcon";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "src/components/Spinner";


interface ReconItemListProps {
  bin: IBin;
  binParts: IInventoryExist[];
  qtyParts: IInventoryRecon[];
  serParts: IInventoryRecon[];
  setQtyParts: (l: IInventoryRecon[]) => void;
  setSerParts: (l: IInventoryRecon[]) => void;
  onComplete: (full: boolean) => void;
}

export const ReconItemList = ({ bin, binParts, qtyParts, serParts, setQtyParts, setSerParts, onComplete }: ReconItemListProps) => {

  const [newSerial, setNewSerial] = useState('');
  const [newPart, setNewPart] = useState<IPart>();
  const [newPartQuantity, setNewPartQuantity] = useState(1);
  const reconRef = useRef<ReconRefObject>(null);
  const {isLoading, isAuthenticated, getAccessTokenSilently} = useAuth0();

  const onSetNewSerial = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewSerial(e.target.value);
  }

  const scanSerial = (e: React.MouseEvent<HTMLButtonElement>) => {

    e.preventDefault();
    // see if this seria, exist in the bin, then see if it was already scanned. 
    // TODO: differentiate differnet parts with same serial
    if (newSerial.trim() == '') {
      return;
    }
    // if we get an unrecognized SN and we don't have a part, help the user out.
    let needsPart = false;

    const scannedPart = serParts.find(sp => sp.serialNumber == newSerial);
    if (scannedPart) {
      if (scannedPart.state == 'unmatched') {
        scannedPart.state = 'matched';
        scannedPart.reconQty = 1;
        setSerParts([...serParts]);
      } else {
        Alert(AlertTypes.WARNING, `${newSerial} already scanned`);
      }
    } else {
      if (newPart?.partId) {
        getAccessTokenSilently().then( accessToken => {
        Parts.Instance().getItem(accessToken, newPart.partId)
          .then(part => {
            if (part?.serialized) {
              const addPart: IInventoryRecon = {
                ...part,
                state: 'new',
                quantity: 0,
                reconQty: 1,
                serialNumber: newSerial,
                binId: bin.binId,
                locationId: 0, //TODO: get loc id,
                warehouseId: 0,
              };
              // If the user scanned a SN of a part that's in the bin, then we
              // need to reflect that in totals.  So we'll reduce the non-serialized 
              // count by 1 even though it's the "original", but not negative.
              //
              // e.g. the bin has 3 items w SN and another qty 4 w/o.  A new SN for
              // the part will create a 'new' serPart and decrement the qty from 4 to 3, 
              // assuming the user just scanned one of the items in the bin.
              // If the user scans 5 "new" items, then we have the 3 original, 4 of the qty 
              // that are now scanned and one more scanned that wasn't recorded in the system
              // even though it was in the bin.  The "extra" then does not change the bin qty.    
              const existQpart = qtyParts.find(qp => qp.partId == addPart.partId);
              if (existQpart && existQpart.quantity > 0) {
                existQpart.quantity--;
                addPart.quantity = 0;
              }
              setSerParts([...serParts, addPart]);
            } else {
              Alert(AlertTypes.ERROR, 'Invalid part');
            }
          });
          })
      } else {
        // we have a serial, but no specific part.  Add on the part ID button
        needsPart = true;
      }
    }
    if (needsPart) {
      document.getElementById('newPart')?.focus();
    } else {
      setNewSerial('');
      document.getElementById('newSerial')?.focus();
    }
  }

  const addPart = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (newSerial) {
      //
    } else {
      // Set on Quantity set
    }
  }

  const cleanupAfterRecon = (opening: boolean) => {
    if (!opening) {
      if (reconRef.current) {
        const saveState = reconRef.current.getSaveState();
        if (saveState == ILoadState.READY) {
          onComplete(true);
        } else if (saveState == ILoadState.ERROR) {
          // TODO: This should update the exiting qtys w/o changing the entered quantities so 
          // the user can see progress
          onComplete(false);
        }
      }
    }
  }
    

  useEffect(() => {
    if (isLoading ||  !isAuthenticated) {
      return;
    }
    if (bin.partId) {
      getAccessTokenSilently().then( accessToken => {
      Parts.Instance().getItem(accessToken, bin.partId!)
        .then(p => setNewPart(p));
      });
    } else {
      setNewPart(undefined);
    }
  }, [bin, isLoading]
  )


  if (!isAuthenticated) { return <Fragment /> }
  if (isLoading) { return <Spinner /> }
  // quantity parts bin total
  const qtotal = +qtyParts.reduce((total, qp) => total + qp.quantity, 0);
  // quantity parts serial total
  const stotal = +serParts.reduce((total, sp) => total + sp.quantity, 0)
  // total of all items reconciled
  const rtotal = +qtyParts.reduce((total, qp) => total + qp.reconQty, 0) +
    serParts.reduce((total, sp) => total + sp.reconQty, 0);

  return <div>
    <h2>Progress
      <span style={{ float: "right" }}>
        <ModalDialog
          id="PerformReconcile"
          buttonText="Record"
          title="Record Adjustments"
          clickFn={(b) => cleanupAfterRecon(b)}
        >
          <ReconCommit ref={reconRef} bin={bin} qtyParts={qtyParts} serParts={serParts} onComplete={onComplete} />
        </ModalDialog>
      </span>
    </h2>
    <form className="inventory-form">
      <label>Next Serial</label>
      <br />
      <input id="newSerial" type="text" value={newSerial} onChange={(e) => onSetNewSerial(e)} autoFocus={true} />
      <button onClick={e => scanSerial(e)}>Add</button>
      <br />
      <SelectPart id="newPart" part={newPart} onSet={setNewPart} disabled={bin.partId != undefined} />
      <button onClick={e => addPart(e)} disabled={bin.partId != undefined}>Set</button>
      <br />
      { /*
      <label>Quantity</label>
      <br />
      <input type="text" value={newPartQuantity} onChange={(e) => onSetPartQuantity(e)} />
      <button onClick={e => onSetNewPartQuantity(e)}>Add</button>
     */}
      <br />

      <table className="uk-table uk-table-small">
        <thead>
          <tr>
            <th></th>
            <th>Part</th>
            <th>Bin Qty</th>
            <th>Recon Qty</th>
          </tr>
          <tr>
            <th></th>
            <th>Total</th>
            <th>{qtotal + stotal}</th>
            <th>{rtotal}</th>
          </tr>
        </thead>
        <tbody>
          {qtyParts.map(qp => <tr>
            <td>
              {qp.quantity < qp.reconQty ? <ReconStateIcon state='new' /> :
                qp.quantity == qp.reconQty ? <ReconStateIcon state='matched' /> :
                  <ReconStateIcon state="unmatched" />}
            </td>
            <td>
              <PromiseText field="partNumber" >{Parts.Instance().getItem(getAccessTokenSilently(), qp.partId)}</PromiseText>
            </td>
            <td>
              {qp.quantity}
            </td>
            <td>
              <ReconQty part={qp} updateQty={() => setQtyParts([...qtyParts])} />
            </td>
          </tr>)}
          {serParts.map(sp => <tr>
            <td>
              <ReconStateIcon state={sp.state} />
            </td>
            <td>
              <PromiseText field="partNumber">{Parts.Instance().getItem(getAccessTokenSilently(), sp.partId)}</PromiseText>
            </td>
            <td>
              Serial: {sp.serialNumber}
            </td>
          </tr>)}
        </tbody>
      </table>

    </form>
  </div>
}
