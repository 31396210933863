//import { atom, useAtom } from 'jotai'
import React, { Suspense, useState } from 'react'
// import { makeStyles } from 'tss-react/mui'

import { Loader } from '../Loader'

//const ReactJson = React.lazy(() => import('react-json-view'))
// TOFIX: remove jotai
// const debugIsOpen = atom<boolean>(false)
// export const useDebugIsOpen = () => useAtom(debugIsOpen)

//TOFIX: const useStyles = makeStyles()((theme: Theme) => ({
/*
const useStyles = makeStyles()(() => ({
  button: {
    marginLeft: -2,
    '& svg': {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
}))
*/

export const TableDebugButton: React.FC<{ enabled: boolean; instance: any }> = ({ enabled, instance }) => {
  // const { classes, cx } = useStyles()
// TOFIX: needs to match the debug below const [, setOpen] = useDebugIsOpen()
//        className={cx({ [classes.button]: instance?.rows?.length })}
  const [, setOpen] = useState(false);
  return enabled ? (
    <div data-uk-tooltip='Debug'>
      <div style={{ position: 'relative' }}>
        <button  data-uk-icon="lifesaver"
          onClick={() => setOpen((old) => !old)}
        >
        </button>
      </div>
    </div>
  ) : null
}

export const TableDebug: React.FC<{
  enabled: boolean
  instance: any
}> = ({ enabled, instance }) => {
  const [isOpen] = useState()
  return enabled && isOpen ? (
    <>
      <br />
      <br />
      <Suspense fallback={<Loader />}>
        {/*<ReactJson src={{ ...instance }} collapsed={1} indentWidth={2} enableClipboard={false} sortKeys /> */}
        <div>JSON.stringify({...instance})</div>
      </Suspense>
    </>
  ) : null
}
