import * as React from 'react';
import LoginLink from './LoginLink';
import Button from './Button';
import { useAuth0 } from '@auth0/auth0-react';
import { Fragment } from 'react';

// At some point, the unauth case should be able to pass an override
// The children of the login element are showin insetead of "login".
// Use the <ProtectedRoute> component to limit access bsased on authentication

export const SesameLogin = (
  props: React.HTMLAttributes<HTMLImageElement>,
  authServer: string,
) => {

  const { isLoading, isAuthenticated } = useAuth0();


  if (isLoading) {
    return <Fragment />
  }
  if (isAuthenticated) {
    return (<div>{props.children}</div>);
  } else {
    return (
      <div>
        <LoginLink>
          <Button style='secondary' className="uk-margin-right">
            Login
          </Button>
        </LoginLink>
        <LoginLink create={true}>
          <Button style='primary'>
            Create Account
          </Button>
        </LoginLink>
      </div>
    );
  }
};

export default SesameLogin;
