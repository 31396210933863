
import React, { Fragment, useState } from 'react';
import API from '@sesame/web-api';

import { Bins } from '../Bins/Bin';
import { Parts } from '../Parts/Parts';
import { IBin, IInventoryLocation, IInventoryResultList, ILoadState, IPart } from 'src/types';
import { SerialNumberAction } from './SerialNumberAction';
import { PartNumberAction } from './PartNumberAction';
import { BinAction } from './BinAction';
import { Spinner } from 'src/components/Spinner';
import { useAuth0 } from '@auth0/auth0-react';


export function ScanSomething() {

  const [scanVal, setScanVal] = useState('');
  const [serial, setSerial] = useState<IInventoryLocation>();
  const [part, setPart] = useState<IPart>();
  const [bin, setBin] = useState<IBin>();
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW);

  const {isLoading, isAuthenticated, getAccessTokenSilently} = useAuth0();

  if (isLoading || !isAuthenticated) { return <Fragment /> }

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await doSearch();
  }

  const doSearch = async () => {
    setBin(undefined);
    setPart(undefined);
    setSerial(undefined);
    setDisplayState(ILoadState.LOADING);

    //TODO:  change to a promise all structure
    API.get(getAccessTokenSilently(), `/api/v1/inventory?serialNumber=${scanVal}`)
      .then(response => {
        const foundSerial = response.data as IInventoryResultList;
        if (foundSerial.inventory.length == 1) {
          setSerial(foundSerial.inventory[0]);
          setDisplayState(ILoadState.READY);
        } else {
          setSerial(undefined);
          setDisplayState(ILoadState.READY);
        }

      })
    Parts.Instance().get(getAccessTokenSilently())
      .then(parts => {
        const foundPart = parts.find(p => p.partNumber == scanVal);
        setPart(foundPart);
        setDisplayState(ILoadState.READY);
      })
    Bins.Instance().get(getAccessTokenSilently())
      .then(bins => {
        const foundBin = bins.find(b => b.binIdentifier == scanVal);
        setBin(foundBin);
        setDisplayState(ILoadState.READY);
      })
    //const foundSerial = (await API.get(getAccessTokenSilently(), `/api/v1/inventory?serialNumber=${scanVal}`)).data as IInventoryResultList;
    //const foundPart = (await Parts.Instance().get(getAccessTokenSilently())).find(p => p.partNumber == scanVal);
    //const foundBin = (await Bins.Instance().get(getAccessTokenSilently())).find(b => b.binIdentifier == scanVal);

    //    setPart(foundPart);
    //   setBin(foundBin);
    //    if (foundBin || foundPart || foundSerial) {
    //     setDisplayState(ILoadState.READY);
    //  }

  }

  return <form>
    <div className="uk-padding">
      <label><h2>Scan Something</h2>
      </label>
      <input autoFocus className="form-inline-input" value={scanVal} onChange={(e) => setScanVal(e.target.value)} />
      <button className="form-inline-button" onClick={e => onSubmit(e)}>Check</button>
      <br />
      {displayState == ILoadState.LOADING && <div><br /><Spinner /></div>}
      {serial && <SerialNumberAction inventoryItem={serial} onChange={doSearch} />}
      {part && <PartNumberAction part={part} onChange={doSearch} />}
      {bin && <BinAction bin={bin} onChange={doSearch} />}
    </div>
  </form>
}


