/*************************************
 * Collection of UI Utility functions
 *
 *
 **************************************/

// hideDropDown
// Call this from an element onClick to hide a UIkit dropdown
export const hideDropDown = (elementId: string) => {
  const dropDown: any = document.getElementById(elementId);
  if (dropDown) {
    (window as any).UIkit.dropdown(dropDown).hide(0);
//                (UIkit as any).drop(el).hide();
  }
};


export function wait(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}