import * as React from 'react';
import { SectionIcon, SectionIconName } from './SectionIcon';

interface IListHeadingProps {
    listName: string;
    iconName: SectionIconName;
    children?: React.ReactNode;
}

export default function ListHeading( {listName, iconName, children}: IListHeadingProps) {
    return <h1><SectionIcon
        iconName={iconName}
        width={35}
        height={35}
        padding={true} />
        {listName}{children}</h1>
}