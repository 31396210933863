import React, { useEffect, useState, useContext, Fragment } from 'react';

import { IAddEditContext, ILoadState, IPartAttributeDef, IPartCategory, PartAttrDataType, _get } from 'src/types';

import { IFormData, FormInput, fieldNames, FormSelect, FormCheckbox } from 'src/components/Form';
import { BaseObject } from 'src/types';
import { SectionIconName } from 'src/components/SectionIcon';
import { ItemPage } from '../ItemPage';
import API from '@sesame/web-api';
import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import { PartCategories } from '../PartCategories/PartCategories';



export const PartAttributesPage = () => {
  const pa = PartAttributes.Instance();

  return <ItemPage<PartAttributes, IPartAttributeDef> itemClass={pa} />
}

export class PartAttributes implements BaseObject<IPartAttributeDef> {

  private static _instance: PartAttributes;
  private static _partAttributes: IPartAttributeDef[];
  private static _loadStatus: ILoadState = ILoadState.NEW;

  private partCategories: IPartCategory[] = [];
  private reqVals = ['true', 'false'];
  private dataTypes = Object.keys(PartAttrDataType).sort((a, b) => a < b ? -1 : 1);

  private constructor() { };

  static Instance() {
    if (!PartAttributes._instance) {
      PartAttributes._instance = new PartAttributes();
    }
    return PartAttributes._instance;
  }

  setLoadState = (newState: ILoadState) => { PartAttributes._loadStatus = newState }
  getLoadState = () => PartAttributes._loadStatus;
  onDataLoad = (data: IPartAttributeDef[]) => { PartAttributes._partAttributes = data };

  public get = async (accessToken: string) => {
    await _get(accessToken, PartAttributes.Instance());
    return PartAttributes._partAttributes;
  }
  public getItem = async(accessToken: string | Promise<string>, partAttrDefId: number) => {
    const token = await Promise.resolve(accessToken);
    const partAttributes = await PartAttributes.Instance().get(token);
    const partAttribute = partAttributes.find( pa => pa.partAttrDefId == partAttrDefId);
    return partAttribute;
  }


  getURL = () => '/api/v1/partattrdef';
  emptyFormObject = () => {
    const r: IFormData<IPartAttributeDef> = {
      partAttrDefId: '',
      name: '',
      description: '',
      dataType: '',
      unitName: '',
    }
    return r;
  }

  primaryId = (partAttributeDef: IPartAttributeDef) => partAttributeDef.partAttrDefId;
  renderListItem = (pa: IPartAttributeDef) => {
    return <Fragment>
      {pa.name}
      <br />
      <span className="uk-text-small uk-text-muted">{pa.description}</span>
    </Fragment>
  };
  renderForm = (formData: IFormData<IPartAttributeDef>,
    formChange: any, // TODO tighten this
    validation: fieldNames<IPartAttributeDef>[],
    setValidation: any) => {
    return <Fragment>
      <FormInput
        label="Name"
        name="name"
        data={formData}
        changeFn={formChange}
        validation={validation} />
      <FormInput
        label="Description"
        name="description"
        data={formData}
        changeFn={formChange}
        validation={validation} />
      <FormSelect
        label={"Data Type"}
        name={"dataType"}
        data={formData}
        disabled={formData.partAttrDefId != ''}
        blankChoice="(Select Data Type)"
        displayFn={() => this.dataTypes.map(dt => {
          return {
            value: `${dt}`, key: `${dt}`,
            text: `${dt}`
          }
        })}
        changeFn={formChange}
        validation={validation} />
      <FormInput
        label="Unit Name"
        name="unitName"
        data={formData}
        changeFn={formChange}
        validation={validation} />
    </Fragment>
  };

  toFormData = (pa: IPartAttributeDef) => {
    return {
      partAttrDefId: `${pa.partAttrDefId}`,
      name: pa.name,
      description: pa.description,
      dataType: Object.keys(PartAttrDataType)[Object.values(PartAttrDataType).indexOf(pa.dataType)],
      unitName: pa.unitName,
    }
  }

  dtFromVal = (val: string): PartAttrDataType => {
    if (val == PartAttrDataType.Character) {
      return PartAttrDataType.Character;
    }
    if (val == PartAttrDataType.Integer) {
      return PartAttrDataType.Integer;
    }
    if (val == PartAttrDataType.Number) {
      return PartAttrDataType.Number;
    }
    return PartAttrDataType.String;
  }

  fromFormData = (f: IFormData<IPartAttributeDef>) => {
    return {
      partAttrDefId: +f.partAttrDefId,
      name: f.name,
      description: f.description,
      dataType: this.dtFromVal(f.dataType),
      unitName: f.unitName,
    }
  }
  loadDependentData = async (accessToken: string) => {
    if (this.partCategories.length) {
      return;
    }
    this.partCategories = await PartCategories.Instance().get(accessToken);
  }

  initialAddEditContext: IAddEditContext<IPartAttributeDef> = { setEditDataFn: () => { } };
  AddEditContext = React.createContext(this.initialAddEditContext);

  listName = "Part Attributes";
  iconName = "settings" as SectionIconName;
  primaryIdField = "partAttributeId"; // as keyof IPartCategory;
  requiredFields = ['name', 'description', 'unitName', 'dataType'];
}
