import React, { useEffect, useState, useContext, Fragment } from 'react';
import API from '@sesame/web-api';

import { IAddEditContext, ILoadState, ILocation, IWarehouse, _get } from 'src/types';

import { IFormData, FormInput, fieldNames } from 'src/components/Form';
import { BaseObject } from 'src/types';
import { SectionIcon, SectionIconName } from 'src/components/SectionIcon';
import { ItemPage } from '../ItemPage';


export const WarehousePage = () => {
  const w = Warehouses.Instance();

  return <ItemPage<Warehouses, IWarehouse> itemClass={w} />

}

export class Warehouses implements BaseObject<IWarehouse> {
  private static _instance: Warehouses;
  private static _warehouses: IWarehouse[];
  private static _loadStatus: ILoadState = ILoadState.NEW;

  private constructor() { };

  static Instance() {
    if (!Warehouses._instance) {
      Warehouses._instance = new Warehouses();
    }
    return Warehouses._instance;
  }

  setLoadState = (newState: ILoadState) => { Warehouses._loadStatus = newState }
  getLoadState = () => Warehouses._loadStatus;
  onDataLoad = (data: IWarehouse[]) => { Warehouses._warehouses = data };

  public get = async (accessToken: string | Promise<string>) => {
    await _get(accessToken, Warehouses.Instance());
    return Warehouses._warehouses;
  }
  public getItem = async (accessToken: string | Promise<string>, warehouseId: number) => {
    const token = await Promise.resolve(accessToken);
    const warehouses = await Warehouses.Instance().get(token);
    const warehouse = warehouses.find(w => w.warehouseId == warehouseId);
    return warehouse;
  }
  getURL = () => '/api/v1/warehouse';
  emptyFormObject = () => {
    return {
      warehouseId: '',
      warehouseAbbrev: '',
      name: '',
      description: '',
    }
  }
  primaryId = (warehouse: IWarehouse) => warehouse.warehouseId;
  renderListItem = (w: IWarehouse) => {
    return <Fragment>
      {w.warehouseAbbrev} : {w.name}
      <br />
      <span className="uk-text-small uk-text-muted">{w.description}</span>
    </Fragment>
  };
  renderForm = (formData: IFormData<IWarehouse>,
    formChange: any, // TODO tighten this
    validation: fieldNames<IWarehouse>[],
    setValidation: any) => {
      return <Fragment>
        <FormInput
          label="Abbreviation"
          name="warehouseAbbrev"
          data={formData}
          changeFn={formChange}
          validation={validation} />
        <FormInput
          label="Name"
          name="name"
          data={formData}
          changeFn={formChange}
          validation={validation} />
        <FormInput
          label="Description"
          name="description"
          data={formData}
          changeFn={formChange}
          validation={validation} />
      </Fragment>
  };

  toFormData = (w: IWarehouse) => {
    return {
      warehouseId: `${w.warehouseId}`,
      warehouseAbbrev: w.warehouseAbbrev,
      name: w.name,
      description: w.description,
    }
  }

  fromFormData = (f: IFormData<IWarehouse>) => {
    return {
      warehouseId: +f.warehouseId,
      warehouseAbbrev: f.warehouseAbbrev,
      name: f.name,
      description: f.description,
    }
  }

  initialAddEditContext: IAddEditContext<IWarehouse> = { setEditDataFn: () => { } };
  AddEditContext = React.createContext(this.initialAddEditContext);

  listName = "Warehouses";
  iconName = "location" as SectionIconName;
  primaryIdField = "warehouseId"; // as keyof IWarehouse;
  requiredFields = ['name', 'warehouseAbbrev', 'description'];
}
