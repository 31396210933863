import React, { useEffect, useState } from 'react';
import API from '@sesame/web-api';

import { IBin, IInventoryAction, IMovementReason, IPart, MovementReasonType } from 'src/types';

import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import ListHeading from 'src/components/ListHeading';

import { SelectPart } from './widgets/SelectPart';
import { SelectBin } from './widgets/SelectBin';
import { MovementReasons } from '../Movements/MovementReason';
import { useAuth0 } from '@auth0/auth0-react';

export function MoveItems() {
    const {isLoading, isAuthenticated, getAccessTokenSilently} = useAuth0();
    const [part, setPart] = useState<IPart>();
    const [serialNumber, setSerialNumber] = useState('');
    const [fromBin, setFromBin] = useState<IBin>();
    const [toBin, setToBin] = useState<IBin>();
    const [reasonId, setReasonId] = useState(0);
    const [reasons, setReasons] = useState<IMovementReason[]>([]);
    const [qty, setQty] = useState('');

    const onPartSet = (part?: IPart) => {
        setPart(part);
        setFromBin(undefined);
        setToBin(undefined);
    }

    const onSerialChange = (event: React.FormEvent<HTMLInputElement>) => {
        const serial = event.currentTarget.value.trim();
        if (serial != '') {
            setQty('1');
        }
        setSerialNumber(event.currentTarget.value.trim());
    }

    const onReasonChange = (event: React.FormEvent<HTMLSelectElement>) => {
        setReasonId(+event.currentTarget.value);
    }

    const onQtyChange = (event: React.FormEvent<HTMLInputElement>) => {
        const q = event.currentTarget.value;
        if (q  == '' || parseInt(q) == +q) {
            setQty(q);
        }
    }

    const getMovementReasons = async () => {
        if (isLoading || !isAuthenticated) {return}
        const allReasons = await MovementReasons.Instance().get(getAccessTokenSilently());
        const reasons = allReasons.filter(r => r.type == MovementReasonType.Transfer);
        setReasons(reasons);
    }

    const doAdd = async (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (!fromBin || !toBin || !part) {
            return;
        }
        const payload: IInventoryAction = {
            partId: part.partId,
            fromBin: fromBin.binId,
            toBin: toBin.binId,
        }
        if (serialNumber != '') {
            payload.serialNumber = serialNumber;
        } else {
            payload.quantity = +qty;
        }

        if (reasonId) {
            payload.reasonId = reasonId;
        }
        const result = await API.post(getAccessTokenSilently(), `/api/v1/inventory/transfer`, payload)
            .then ( result => {
                Alert(AlertTypes.SUCCESS, 'Moved');
                setSerialNumber('');
                setQty('1');
                setFromBin(undefined);
                setToBin(undefined);
            })
            .catch(err => {
                if (err.statusCode >= 400 && err.statusCode < 500) {
                    Alert(AlertTypes.ERROR, err.message);
                } else {
                    Alert(AlertTypes.ERROR, err)
                }
                return;
            });
    }

    useEffect(() => {
        getMovementReasons();
    }, []);

    const qtyDisabled = !(fromBin && toBin) || (part?.serialized && serialNumber != '');
    const submitOk = (part?.partId ?? 0 ) > 0  &&
                    fromBin != undefined &&
                    toBin != undefined &&
                    (serialNumber || !qtyDisabled && +qty > 0)

    return <div>
        <ListHeading listName="Inventory: Move Items" iconName={'part'} />

        <form className="inventory-form">
            
            <SelectPart onSet={onPartSet} />

            <br />
            <label className={qtyDisabled ? "uk-text-muted" : ""}>Serial Number (optional)</label>
            <br />
            <input
                width="10"
                disabled={!part?.serialized}
                value={serialNumber}
                onChange={(e) => onSerialChange(e)}
            />

            <br />
            <SelectBin
              partId={part?.partId}
              bin={fromBin}
              onSet={setFromBin}
              label="Old Bin"
              disabled={part == undefined}
              />
            <br />
            <SelectBin
              partId={part?.partId}
              bin={toBin}
              onSet={setToBin}
              label="New Bin"
              disabled={part == undefined}
               />
            <br />

            <label className={qtyDisabled ? "uk-text-muted" : ""}>Quantity</label>
            <br />
            <input
                width="10"
                value={qty}
                disabled={qtyDisabled}
                onChange={(e) => onQtyChange(e)}
            />
            <br />
            <label>Reason (optional):</label>
            <br />
            <select value={reasonId}
                onChange={(e) => onReasonChange(e)}>
                <option>(Select Reason)</option>
                {reasons.map(r => (<option
                    selected={r.movementReasonId == reasonId}
                    value={r.movementReasonId}
                >{r.reason}</option>))
                }
            </select>

            <button
                disabled={!submitOk}
                onClick={(e) => doAdd(e)}>Move</button>

        </form>
    </div>
}
