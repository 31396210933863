import React, { useEffect, useState } from "react";

import { IInventoryRecon } from "./types";

interface ReconQtyProps {
  part: IInventoryRecon;
  updateQty: () => void;
}

export const ReconQty = ({ part, updateQty }: ReconQtyProps) => {
  //  const [qty, setQty] = useState(part.reconQty);
  const [qty, setQty] = useState(0);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;

    if (val.match(/^\d*$/)) {
      setQty(+val);
      part.reconQty = +val;
      updateQty();
    }
  }
  useEffect(() => {
    setQty(0);
  }, [part]);

  return <input type="text"
    value={qty}
    onChange={e => onChange(e)} />
}