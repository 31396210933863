import React, { ReactElement } from 'react'
import { ColumnInstance } from 'react-table'

export const ResizeHandle = <T extends Record<string, unknown>>({
  column,
}: {
  column: ColumnInstance<T>
}): ReactElement => {
  return (
    <div
      {...column.getResizerProps()}
      style={{ cursor: 'col-resize' }} // override the useResizeColumns default
      className="tableResizeHandle"
    />
  )
}

      //TOFIX:  Resizer handle style
      /*
      className={cx({
        [classes.resizeHandle]: true,
        handleActive: column.isResizing,
        */