import React, { FC, Fragment, Suspense, lazy, useContext, useEffect, useState } from 'react';
import * as UIkit from '../../assets/uikit/js/uikit';

import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import { SelectBin } from './widgets/SelectBin';
import { IBin, ILoadState, ILocation, IWarehouse, } from 'src/types';
import { ScanActionPanel } from './ScanActionPanel';
import { SelectWarehouse } from './widgets/SelectWarehouse';
import { Warehouses } from '../Warehouses/Warehouses';
import { Locations } from '../Locations';
import { SelectLocation } from './widgets/SelectLocation';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Bins } from '../Bins/Bin';
import { InventoryList } from './widgets/InventoryList';
import { moveBinContents } from '.';
import { useAuth0 } from '@auth0/auth0-react';

interface BinActionProps {
  bin: IBin;
  onChange: () => void;
}

export function BinAction({ bin, onChange }: BinActionProps) {

  const [warehouse, setWarehouse] = useState<IWarehouse>();
  const [targetBin, setTargetBin] = useState<IBin>();
  const [location, setLocation] = useState<ILocation>();
  const [currentLocation, setCurrentLocation] = useState<ILocation>();
  const [detailDisplayState, setDetailDisplayState] = useState<ILoadState>(ILoadState.NEW);
  const [invPart, setInvPart] = useState<number[]>([]);
  const {isLoading, isAuthenticated, getAccessTokenSilently} = useAuth0();

  const navigate = useNavigate();

  if (!isAuthenticated) { return <Fragment /> }


  const handleMove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    if (!location) {
      return;
    }
    getAccessTokenSilently().then( accessToken => {
    Bins.MoveBin(accessToken, bin.binId, location.locationId)
      .then(result => {
        Alert(AlertTypes.SUCCESS, 'Moved');
        const el = document.getElementById(`movePane`);
        (UIkit as any).drop(el).hide();
        //TODO: should update through data invalidatin
        setCurrentLocation(location);
        onChange();
      })
      .catch(err => {
        Alert(AlertTypes.ERROR, 'Error moving bin: ' + err.message);
      })
    })
  }

  const onSetWarehouse = (w?: IWarehouse) => {
    setLocation(undefined);
    setWarehouse(w);
  }

  const handleMoveContents = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!targetBin) { return }
    getAccessTokenSilently().then( accessToken => {
    moveBinContents(accessToken, bin, targetBin, invPart)
      .then(result => {
        if (result.status == 'SUCCESS') {
          Alert(AlertTypes.SUCCESS, 'Bin Contents moved');
        } else if (result.status == 'PARTIAL') {
          Alert(AlertTypes.WARNING, `${result.successCount} of ${result.total} items moved`);
        } else {
          Alert(AlertTypes.ERROR, 'Failed to move items');
        }
        if (result.status != 'FAILED') {
          const el = document.getElementById(`moveContentsPane`);
          (UIkit as any).drop(el).hide();
          onChange();
        }
      })
    })
  }

  const goReconcile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault;
    navigate({
      pathname: "/reconcile",
      search: `?${createSearchParams({
        binIdentifier: bin.binIdentifier
      })}`
    });
  }

  const initData = () => {
    if (isLoading || !isAuthenticated) { return }
    getAccessTokenSilently().then( accessToken => {
    Locations.Instance().get(accessToken)
      .then(locations => {
        const l = locations.find(l => l.locationId == bin.locationId);
        setCurrentLocation(l);
        return l;
      }).then((l) => {
        if (!l) { return }
        Warehouses.Instance().get(accessToken)
          .then(warehouses => {
            setWarehouse(warehouses.find(w => w.warehouseId == l.warehouseId))
            setDetailDisplayState(ILoadState.READY)
          })
      })
    })
  }
  useEffect(() => {
    initData();
  }, [bin, isLoading])

  return <div className="uk-card uk-card-default">
    <div className="uk-inline uk-padding">

      <ScanActionPanel
        id="move"
        onConfirm={handleMove}
        okConfirm={() => warehouse != undefined && location != undefined} >

        <SelectWarehouse warehouse={warehouse} onSet={onSetWarehouse} />
        <br />
        <SelectLocation
          warehouseId={warehouse?.warehouseId ?? 0}
          location={location}
          excludeLocationId={bin.locationId}
          onSet={setLocation} />
      </ScanActionPanel>


      <ScanActionPanel
        id="moveContents"
        label="Move Contents"
        onConfirm={handleMoveContents}
        okConfirm={ () => (targetBin != undefined) && invPart.length > 0 } >

        <SelectBin
          bin={targetBin}
          excludeBinId={bin.binId}
          filter="multiple"
          onSet={setTargetBin}
        />
        <InventoryList
          binIdentifier={bin.binIdentifier}
          setParts={setInvPart}
        />
      </ScanActionPanel>

      <button type="button" className="scan-action-button" onClick={e => goReconcile(e)}>Reconcile</button>

      <div>
        <h2>{bin.binIdentifier}</h2>
        {detailDisplayState == ILoadState.READY ?
          <Fragment>{warehouse?.warehouseAbbrev}:{currentLocation?.description}</Fragment>
          : <Fragment></Fragment>
        }
        <br />
        <InventoryList binIdentifier={bin.binIdentifier} />
      </div>
    </div>
  </div>
}
