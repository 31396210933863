import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { CellProps, FilterProps, FilterValue, IdType, Row, TableInstance } from 'react-table'

import dayjs from 'dayjs';


import API from "@sesame/web-api";
import Alert, { AlertTypes } from "src/components/AlertDisplay";
import { IBin, IInventoryLocation, IInventoryResultList, ILoadState, IMovement, IPart, IWarehouse, MovementReasonType } from "src/types";

import { Table } from 'src/widgets/Table/Table'
import { Spinner } from 'src/components/Spinner';
import { useAuth0 } from '@auth0/auth0-react';

type MovementRow = {
  movementId: number;
  userIdentity: string;
  date: Date;
  type: MovementReasonType;
  movementReasonText?: string;
  sourceBinId?: number;
  targetBinId?: number;
  sourceBinIdentifier?: string;
  targetBinIdentifier?: string;
  partId: number;
  partNumber?: string;
  serialNumber?: string;
  quantity: number;
  comment?: string
};


type MovementData = MovementRow & {
  subRows?: MovementRow[];
}

// This is a custom aggregator that
// takes in an array of values and
// returns the rounded median
function roundedMedian(values: any[]) {
  let min = values[0] || ''
  let max = values[0] || ''

  values.forEach((value) => {
    min = Math.min(min, value)
    max = Math.max(max, value)
  })

  return Math.round((min + max) / 2)
}

function filterGreaterThan(rows: Array<Row<any>>, id: Array<IdType<any>>, filterValue: FilterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id[0]]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val: any) => typeof val !== 'number'

function TypeColumnFilter({
  column: { filterValue, render, setFilter, preFilteredRows, id },
}: FilterProps<MovementRow>) {

  return (<>
      <label>{render('Header')}</label>
      <select
        value={filterValue || ''}
        className="uk-select"
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value={''}>All</option>
        <option value="A">Add</option>
        <option value="T">Transfer</option>
        <option value="R">Dispose</option>
      </select>
  </>
  )
}
  
function SelectColumnFilter({
  column: { filterValue, render, setFilter, preFilteredRows, id },
}: FilterProps<MovementRow>) {
  const options = React.useMemo(() => {
    const options = new Set<any>()
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return [...Array.from(options.values())]
  }, [id, preFilteredRows])

  return (
    <Fragment>
      <label>{render('Header')}</label>
      <select
        value={filterValue || ''}
        className="uk-select"
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value={''}>All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </Fragment>
  )
}

const getMinMax = (rows: Row<MovementRow>[], id: IdType<MovementRow>) => {
  let min = rows.length ? rows[0].values[id] : 0
  let max = rows.length ? rows[0].values[id] : 0
  rows.forEach((row) => {
    min = Math.min(row.values[id], min)
    max = Math.max(row.values[id], max)
  })
  return [min, max]
}

function SliderColumnFilter({
  column: { render, filterValue, setFilter, preFilteredRows, id },
}: FilterProps<MovementRow>) {
  const [min, max] = React.useMemo(() => getMinMax(preFilteredRows, id), [id, preFilteredRows])

  return (
    <div>
      <label>{render('Header')}</label>
      <input
        name={id}
        type='range'
        className="uk-range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10))
        }}
      />
      <input type="button" className="uk-button uk-button-primary" onClick={() => setFilter(undefined)}>
        Off
      </input>
    </div>
  )
}

const useActiveElement = () => {
  const [active, setActive] = React.useState(document.activeElement)

  const handleFocusIn = () => {
    setActive(document.activeElement)
  }

  React.useEffect(() => {
    document.addEventListener('focusin', handleFocusIn)
    return () => {
      document.removeEventListener('focusin', handleFocusIn)
    }
  }, [])

  return active
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], render, preFilteredRows, setFilter, id },
}: FilterProps<MovementRow>) {
  const [min, max] = React.useMemo(() => getMinMax(preFilteredRows, id), [id, preFilteredRows])
  const focusedElement = useActiveElement()
  const hasFocus = focusedElement && (focusedElement.id === `${id}_1` || focusedElement.id === `${id}_2`)
  return (
    <Fragment>
      {/*
      <InputLabel htmlFor={id} shrink focused={!!hasFocus}>
        {render('Header')}
      </InputLabel>
      */}
      <label>{render('Header')}</label>
      <div className="reportRangeFilter" >
        <input
          id={`${id}_1`}
          value={filterValue[0] || ''}
          type='number'
          onChange={(e) => {
            const val = e.target.value
            setFilter((old: any[] = []) => [val ? parseInt(val, 10) : undefined, old[1]])
          }}
          placeholder={`Min (${min})`}
        />
        to
        <input
          id={`${id}_2`}
          value={filterValue[1] || ''}
          type='number'
          onChange={(e) => {
            const val = e.target.value
            setFilter((old: any[] = []) => [old[0], val ? parseInt(val, 10) : undefined])
          }}
          placeholder={`Max (${max})`}
        />
      </div>
    </Fragment>
  )
}

const columns = [
  {
    Header: 'Group 1',
    columns: [
      {
        Header: 'Date',
        accessor: 'date',
//        aggregate: 'count',
        disableGroupBy: true,
        Cell: ({value}: CellProps<MovementRow>) => dayjs(value).format('MM/DD/YYYY HH:mm'),
        filter: 'fuzzytext',
//       Aggregated: ({ cell: { value } }: CellProps<MovementRow> `${value} Parts`,
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({value}: CellProps<MovementRow>) => { 
            switch(value) { case 'T': return "Transfer"; case "A": return "Add"; case "R": return "Dispose"; default: return "" }},
               Width: 100,
        //       minWidth: 100,
        //        align: 'right',
        Filter: TypeColumnFilter,
        filter: 'includes',
        //      aggregate: 'count',
        //        disableGroupBy: false,
        //        defaultCanSort: false,
        //        disableSortBy: false,
        //     Aggregated: ({ cell: { value } }: CellProps<MovementRow>) => `${value} (avg)`,
      },
      {
        Header: 'Reason',
        accessor: 'movementReasonText',
        //width: 50,
        //minWidth: 50,
        //        align: 'right',
        //        Filter: NumberRangeColumnFilter,
        filter: 'fuzzytext',
        //       aggregate: 'count',
        //       Aggregated: ({ cell: { value } }: CellProps<MovementRow>) => `${value} (total)`,
      },
      {
        Header: 'Part',
        accessor: 'partNumber',
        //        Filter: SelectColumnFilter,
        filter: 'fuzzytext',
        aggregate: 'count',
        Aggregated: ({ cell: { value } }: CellProps<MovementRow>) => `${value} (count)`,
      },
      {
        Header: 'Serial Number',
        accessor: 'serialNumber',
        //width: 140,
        //minWidth: 140,
        disableGroupBy: true,
        filter: 'fuzzytext',
//        aggregate: 'count',
//        Aggregated: ({ cell: { value } }: CellProps<MovementRow>) => `${value} (count)`,
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        width: 100,
        align: 'right',
        filter: 'between',
        aggregate: 'sum',
        Filter: NumberRangeColumnFilter,
        Aggregated: ({ cell: { value } }: CellProps<MovementRow>) => `${value} (total)`,
      },
      {
        Header: 'Source',
        accessor: 'sourceBinIdentifier',
        filter: 'fuzzytext',
      },
      {
        Header: 'Destination',
        accessor: 'targetBinIdentifier',
        filter: 'fuzzytext',
      }

    ]
  }
].flatMap((c: any) => c.columns) // remove comment to drop header groups


export const RecentMovements: React.FC = () => {
  const [data, setData] = useState<MovementRow[]>([]);
  const [parts, setParts] = useState<IPart[]>([]);
  const [bins, setBins] = useState<IBin[]>([]);
  const [warehouses, setWarehouses] = useState<IWarehouse[]>([]);
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW);


  const {isLoading, isAuthenticated, getAccessTokenSilently} = useAuth0();

  const dummy = useCallback(
    (instance: TableInstance<MovementRow>) => () => {
      console.log(
        'Selected',
        instance.selectedFlatRows.map((v) => `'${v.original.movementId}'`).join(', ')
      )
    },
    []
  )

  const getServerData = (url: string, setFn: (data: any) => void) => {
    API.get(getAccessTokenSilently(), url)
      .then(result => {
        setFn(result.data);
      })
      .catch(err => {
        Alert(AlertTypes.ERROR, err.message);
        setDisplayState(ILoadState.ERROR);
      })
  }

  const getMovements = () => {
    const current = data ?? { moreResults: true, inventory: [] };
    API.get(getAccessTokenSilently(), '/api/v1/movement')
      .then(result => {
        const movementResult = result.data as IMovement[];
        data.push(...movementResult.map(m => {
          return {
            ...m,
            partNumber: parts.find(p => p.partId == m.partId)?.partNumber ?? '',
            /*
            binIdentifier: bins.find(b => b.binId == i.binId)?.binIdentifier ?? '',
            warehouseAbbrev: warehouses.find(p => p.warehouseId == i.warehouseId)?.warehouseAbbrev ?? '',
            */
          }
        }));
        setData(data);
        setDisplayState(ILoadState.READY)
      })
      .catch(err => {
        Alert(AlertTypes.ERROR, err.message);
        setDisplayState(ILoadState.ERROR);
      });
    setDisplayState(ILoadState.LOADING);
  }

  useEffect(() => {
    if (parts.length > 0 && bins.length > 0 && warehouses.length > 0) {
      getMovements();
    }
  }, [parts, bins, warehouses]);

  useEffect(() => {
    getServerData('/api/v1/part', setParts)
    getServerData('/api/v1/bin', setBins)
    getServerData('/api/v1/warehouse', setWarehouses)
  }, []);

  if (displayState == ILoadState.NEW || displayState == ILoadState.LOADING) {
    return <Spinner />
  }

  return (
    <Table<MovementData>
      name={'Inventory Activity'}
      columns={columns}
      data={data}
    />
  )
}