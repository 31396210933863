import React, { useEffect, useState } from 'react';
import API from '@sesame/web-api';

import { IBin, IInventoryActionAdd, IInventoryActionDispose, IMovementReason, IPart, MovementReasonType } from 'src/types';
import { SelectPart } from './widgets/SelectPart';
import { SelectBin } from './widgets/SelectBin';

import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import ListHeading from 'src/components/ListHeading';
import { useAuth0 } from '@auth0/auth0-react';

interface AddRemoveItemsProps {
    action: 'add' | 'remove';
}

type addRemoveType = {
    add: string,
    remove: string,
}
type actionKeysType = {
    urlAction: addRemoveType;
    pageTitle: addRemoveType;
    successMessage: addRemoveType;
}

const actionKeys: actionKeysType = {
    urlAction: {
        add: 'add',
        remove: 'dispose',
    },
    pageTitle: {
        add: 'Add Items',
        remove: 'Remove Items',
    },
    successMessage: {
        add: 'Added',
        remove: 'Removed'
    }
}

export function AddRemoveItems({ action }: AddRemoveItemsProps) {


    const {isLoading, isAuthenticated, getAccessTokenSilently} = useAuth0();
    const [part, setPart] = useState<IPart>();
    const [serialNumber, setSerialNumber] = useState('');
    const [bin, setBin] = useState<IBin>();
    const [reasonId, setReasonId] = useState(0);
    const [reasons, setReasons] = useState<IMovementReason[]>([]);
    const [qty, setQty] = useState('1');

    const setBinX = (bin?: IBin)  => {
      setBin(bin);
    }
    const onPartSet = (part?: IPart) => {
        setPart(part);
        setBinX(undefined);
    }
    const onSerialChange = (event: React.FormEvent<HTMLInputElement>) => {
        const serial = event.currentTarget.value.trim();
        if (serial != '') {
            setQty('1');
        }
        setSerialNumber(event.currentTarget.value.trim());
    }

    const onReasonChange = (event: React.FormEvent<HTMLSelectElement>) => {
        setReasonId(+event.currentTarget.value);
    }
    const onQtyChange = (event: React.FormEvent<HTMLInputElement>) => {
        const q = event.currentTarget.value;
        if (q == '' || parseInt(q) == +q) {
            setQty(q);
        }
    }

    const getMovementReasons = async () => {
        const result = await API.get(getAccessTokenSilently(), '/api/v1/movementReason')
            .catch(err => {
                Alert(AlertTypes.ERROR, "Failed to load resaons");
            })
        const reasons = (result.data as IMovementReason[]).filter(r => action == 'add' ?
              r.type == MovementReasonType.Add :
              r.type == MovementReasonType.Remove);
        setReasons(reasons);
    }

    const doAdd = async (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (!bin || !part) {
            return;
        }

        let payload: IInventoryActionAdd | IInventoryActionDispose = action == 'add' ? {
            partId: part.partId,
            toBin: bin.binId,
        } : {
            partId: part.partId,
            fromBin: bin.binId,
        }

        if (serialNumber != '') {
            payload.serialNumber = serialNumber;
        } else {
            payload.quantity = +qty;
        }
        if (reasonId) {
            payload.reasonId = reasonId;
        }
        const result = await API.post(getAccessTokenSilently(), `/api/v1/inventory/${actionKeys.urlAction[action]}`, payload)
            .then(err => {
                Alert(AlertTypes.SUCCESS, actionKeys.successMessage[action]);
                setQty('1');
                setSerialNumber('');
//                setBinX(undefined);
            })
            .catch(err => {
                if (err.statusCode >= 400 && err.statusCode < 500) {
                    Alert(AlertTypes.ERROR, err.message);
                } else {
                    Alert(AlertTypes.ERROR, err)
                }
                return;
            });
    }

    useEffect(() => {
        getMovementReasons();
    }, [isAuthenticated, isLoading]);

    const qtyDisabled = bin == undefined || (part?.serialized && serialNumber != '');

    const submitOk = (part?.partId ?? 0) > 0 &&
        bin != undefined &&
        (serialNumber || (!qtyDisabled && +qty > 0))

    return <div>
        <ListHeading listName={`Inventory: ${actionKeys.pageTitle[action]}`} iconName={'part'} />

        <form className="inventory-form">

            <SelectPart part={part} onSet={onPartSet} />

            <br />
            <label className={part?.serialized ? "uk-text-muted" : ""}>Serial Number (optional)</label>
            <br />
            <input
                width="10"
                disabled={!part?.serialized}
                value={serialNumber}
                onChange={(e) => onSerialChange(e)}
            />
            <br />
            <SelectBin partId={part?.partId} bin={bin} onSet={setBinX} disabled={part == undefined} />

            <br />
            <label className={qtyDisabled ? "uk-text-muted" : ""}>Quantity</label>
            <br />
            <input
                width="10"
                value={qty}
                disabled={qtyDisabled}
                onChange={(e) => onQtyChange(e)}
            />
            <br />
            <label>Reason (optional):</label>
            <br />
            <select value={reasonId}
                onChange={(e) => onReasonChange(e)}>
                <option>(Select Reason)</option>
                {reasons.map(r => (<option
                    selected={r.movementReasonId == reasonId}
                    value={r.movementReasonId}
                >{r.reason}</option>))
                }
            </select>

            <button
                disabled={!submitOk}
                onClick={(e) => doAdd(e)}>{actionKeys.pageTitle[action]}</button>

        </form>
    </div>
}
