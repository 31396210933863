import React, { useReducer } from 'react';

import { IBin, ILocation, IWarehouse } from 'src/types';
import { SelectBin } from './widgets/SelectBin';

import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import ListHeading from 'src/components/ListHeading';
import { SelectLocation } from './widgets/SelectLocation';
import { SelectWarehouse } from './widgets/SelectWarehouse';
import { Locations } from '../Locations';
import { Warehouses } from '../Warehouses/Warehouses';
import { Bins } from '../Bins/Bin';
import { useAuth0 } from '@auth0/auth0-react';

interface IBinLoc {
  bin?: IBin;
  warehouse?: IWarehouse;
  location?: ILocation;
}
interface IBinLocUpdate {
  type: 'SET' | 'CLEAR';
  data?: IBinLoc;
}


const reducer = (state: IBinLoc, action: IBinLocUpdate): IBinLoc => {
  if (action.type == 'SET') {
    const newState = {
      bin: action.data?.bin ?? state.bin,
      location: action.data?.location ?? state.location,
      warehouse: action.data?.warehouse ?? state.warehouse,
    };
    // allow the location to be cleared explicitly.  could do this for all fields, but not needed.
    if ( action.data?.hasOwnProperty('location') && action.data.location == undefined) {
      newState.location = undefined;
    }
    return newState;
  } else {
    return {
      bin: undefined,
      location: undefined,
      warehouse: undefined,
    };
  }
}

export function MoveBinPage() {
  const {isLoading, isAuthenticated, getAccessTokenSilently} = useAuth0();

  const [binLoc, dispatch] = useReducer(reducer, {
    bin: undefined,
    warehouse: undefined,
    location: undefined
  });


  const onBinSet = (bin?: IBin) => {
    if (isLoading || !isAuthenticated) {
      return;
    }
    if (bin) {
      Locations.Instance().getItem(getAccessTokenSilently(), bin.locationId)
        .then(currentLocation => {
          if (currentLocation) {
            Warehouses.Instance().getItem(getAccessTokenSilently(), currentLocation.warehouseId)
              .then(currentWarehouse => {
                dispatch({
                  type: 'SET', data:
                  {
                    bin: bin,
                    location: currentLocation,
                    warehouse: currentWarehouse
                  }
                })
              })
          }
        });
    } else {
      dispatch({ type: 'CLEAR' })
    }
  }

  const onWarehouseSet = (warehouse?: IWarehouse) => {
    if (isLoading || !isAuthenticated) {
      return;
    }
    if (warehouse) {
      dispatch({ type: 'SET', data: { location: undefined, warehouse } })
    }
  }

  const moveBin = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!isAuthenticated || !binLoc.bin || !binLoc.location) {
      return;
    }

    Bins.MoveBin(getAccessTokenSilently(), binLoc.bin.binId, binLoc.location.locationId)
      .then(result => {
        Alert(AlertTypes.SUCCESS, "Moved");
        dispatch({ type: 'CLEAR' })
      })
      .catch(err => {
        Alert(AlertTypes.ERROR, err.message);
      })
  }

  return <div>
    <ListHeading listName={`Inventory: Move Bin`} iconName={'part'} />

    <form className="inventory-form">

      <SelectBin bin={binLoc.bin} onSet={onBinSet} />
      <br />
      <SelectWarehouse warehouse={binLoc.warehouse} onSet={onWarehouseSet} />
      <br />
      <SelectLocation location={binLoc.location}
        onSet={(location) => dispatch( { type: 'SET', data: { location } })}
        warehouseId={(binLoc.warehouse?.warehouseId) ?? 0} />
      <br />
      <br />
      <button
        disabled={!(binLoc.bin && binLoc.location)}
        onClick={(e) => moveBin(e)}>Move</button>

    </form>
  </div>
}

