import React, { Fragment, useEffect, useState } from 'react';
import * as UIkit from '../../assets/uikit/js/uikit';

import API from '@sesame/web-api';

import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import { SelectBin } from './widgets/SelectBin';
import { IBin, IInventoryAction, IInventoryActionAdd, IInventoryActionDispose, IPart, MovementReasonType } from 'src/types';
import { SelectMovementReason } from './widgets/SelectMovementReason';
import { ScanActionPanel } from './ScanActionPanel';
import { useAuth0 } from '@auth0/auth0-react';

interface PartNumberActionProps {
  part: IPart;
  onChange: () => void;
}

export function PartNumberAction({ part, onChange }: PartNumberActionProps) {

  const [fromBin, setFromBin] = useState<IBin>();
  const [toBin, setToBin] = useState<IBin>();
  const [qty, setQty] = useState('1');
  const [movementReasonId, setMovementReasonId] = useState(0);

    const {isLoading, isAuthenticated, getAccessTokenSilently} = useAuth0();

  if (isLoading || !isAuthenticated) { return <Fragment /> }

  useEffect(() => {
  }, [part.partNumber])

  const handleQtyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value;
    setQty(v.replace(/\D/g, ''));
  }

  const callApi = (payload: IInventoryAction|IInventoryActionAdd|IInventoryActionDispose,
        action: 'transfer'|'add'|'dispose',
        ) => {

    if (movementReasonId != 0) {
      payload.reasonId = movementReasonId;
    }
    API.post(getAccessTokenSilently(), `/api/v1/inventory/${action}`, payload)
      .then(result => {
        Alert(AlertTypes.SUCCESS, 'Success');
        const el = document.getElementById(`${action}Pane`);
        (UIkit as any).drop(el).hide();
        onChange();
      })
      .catch(err => {
        Alert(AlertTypes.ERROR, err.message);
      });
  }

  const handleMove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!toBin || !fromBin) {
      Alert(AlertTypes.ERROR, 'No bin specified');
      return;
    }
    const payload: IInventoryAction = {
      partId: part.partId,
      fromBin: fromBin.binId,
      toBin: toBin.binId,
      quantity: +qty,
    }
    callApi(payload, 'transfer');
  }

  const handleAdd = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!toBin) {
      Alert(AlertTypes.ERROR, 'No bin specified');
      return;
    }
    const payload: IInventoryActionAdd = {
      partId: part.partId,
      toBin: toBin.binId,
      quantity: +qty,
    }
    callApi(payload, 'add');
  }

  const handleDispose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!fromBin) {
      Alert(AlertTypes.ERROR, 'No bin specified');
      return;
    }
    const payload: IInventoryActionDispose = {
      partId: part.partId,
      fromBin: fromBin.binId,
      quantity: +qty,
    }
    callApi(payload, 'dispose');
  }

  return <div className="uk-card uk-card-default">
    <div className="uk-inline uk-padding">
      <ScanActionPanel id="transfer" onConfirm={handleMove} >
          <SelectBin key="from" partId={part?.partId} filter='contains' bin={fromBin} onSet={setFromBin} label="From Bin" />
          <br />
          <SelectBin key="to" partId={part?.partId} filter='possible' bin={toBin} onSet={setToBin} label="New Bin" />
          <br />
          <label>Quantity</label>
          <br />
          <input type="uk-input" value={qty} onChange={(e) => handleQtyChange(e)} />
          <br />
          <SelectMovementReason movementReasonId={movementReasonId} type={MovementReasonType.Add} onSet={setMovementReasonId} />
      </ScanActionPanel>

      <ScanActionPanel id="add" onConfirm={handleAdd} >
          <SelectBin key="2" partId={part?.partId} bin={toBin} onSet={setToBin} label="Bin" />
          <br />
          <label>Quantity</label>
          <br />
          <input type="uk-input" value={qty} onChange={(e) => handleQtyChange(e)} />
          <br />
          <SelectMovementReason movementReasonId={movementReasonId} type={MovementReasonType.Transfer} onSet={setMovementReasonId} />
      </ScanActionPanel>

      <ScanActionPanel id="dispose" onConfirm={handleDispose} danger={true} >
          <SelectBin partId={part?.partId} bin={fromBin} onSet={setFromBin} label="Bin" />
          <br />
          <label>Quantity</label>
          <br />
          <input type="uk-input" value={qty} onChange={(e) => handleQtyChange(e)} />
          <br />
          <SelectMovementReason movementReasonId={movementReasonId} type={MovementReasonType.Remove} onSet={setMovementReasonId} />
      </ScanActionPanel>

      <dl className="uk-list">
        <dd>
          Part:</dd><dt> {part.partNumber ?? "unknown"}
        </dt>
      </dl>
    </div>
  </div>
}