import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, Outlet, RouteProps, NavLink, Link, useNavigate } from 'react-router-dom';

import Index from 'src/pages/Index';
import PageHeader from 'src/widgets/PageHeader';
import PrimaryNav from 'src/widgets/PrimaryNav';
import Overview from 'src/pages/Overview';
import { PartPage } from 'src/pages/Parts/Parts';
import { PartCategoriesPage } from 'src/pages/PartCategories/PartCategories';
import { BinPage } from 'src/pages/Bins/Bin';
import { InventoryPage, AddRemoveItems, MoveItems, } from './pages/Inventory';
import { LocationPage } from 'src/pages/Locations';
import { ManufacturerPage } from './pages/Manufacturers/Manufacturers';
import { MoveBinContentsPage } from './pages/Inventory/MoveBinContents';
import { MoveBinPage } from './pages/Inventory/MoveBin';
import { MovementReasonsPage } from './pages/Movements/MovementReason';
import { NewOrganization } from './pages/Onboard/NewOrganization';
import { NewUser } from './pages/Onboard/NewUser';
import { OrganizationStatistics } from './pages/Admin/OrgStats';
import { PartAttributesPage } from './pages/PartAttributes/PartAttributes';
import { ReconcileBin } from './pages/Inventory/Reconcile';
import { ReportsPage } from './pages/Reports/Reports';
import { WarehousePage } from 'src/pages/Warehouses/Warehouses';

import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from './components/Spinner';

import Hedgehog from  './assets/images/errorhedgehog.png';
import { config } from './config';
import { UsersPage } from './pages/Users/Users';

export default function App() {

  const {isLoading, isAuthenticated, user} = useAuth0();
  const navigate = useNavigate();
  
  if (isLoading) {
    return <div className="uk-align-center">
      <Spinner />
      </div>
  }
  
  if (isAuthenticated && !user) {
    return <div>User error: Authenticated without user data</div>
  }

  const claimNamespace = config.AUTH0_CLAIM_NAMESPACE;
  if (isAuthenticated &&
    !(user![`${claimNamespace}/organization_id`] > 0 && user!.roles_assigned)) {
    if ( !['/NewUser','/NewOrganization'].includes(window.location.pathname) ) {
      navigate('/NewUser');
      return <Spinner />;
    }
  }

  return (
      <Routes>
        <Route element={<PageLayout />}>
          <Route path="/" element={<IndexRoute />} />
          <Route path="/NewUser" element={<NewUser />}/>
          <Route path="/NewOrganization" element={<NewOrganization />}/>
          <Route path="/AdminOrganizations" element={
            <RequireAuth>
              <OrganizationStatistics />
            </RequireAuth>
          } />
          <Route path="/bins" element={
            <RequireAuth>
              <BinPage />
            </RequireAuth>
          } />
          <Route path="/locations" element={
            <RequireAuth>
              <LocationPage />
            </RequireAuth>
          } />
          <Route path="/inventory" element={
            <RequireAuth>
              <InventoryPage />
            </RequireAuth>
          } />
          <Route path="/addItems" element={
            <RequireAuth>
              <AddRemoveItems action="add" />
            </RequireAuth>
          } />
          <Route path="/moveItems" element={
            <RequireAuth>
              <MoveItems />
            </RequireAuth>
          } />
          <Route path="/removeItems" element={
            <RequireAuth>
              <AddRemoveItems action="remove" />
            </RequireAuth>
          } />
          <Route path="/manufacturers" element={
            <RequireAuth>
              <ManufacturerPage />
            </RequireAuth>
          } />
          <Route path="/moveBin" element={
            <RequireAuth>
              <MoveBinPage />
            </RequireAuth>
          } />
          <Route path="/moveBinContents" element={
            <RequireAuth>
              <MoveBinContentsPage />
            </RequireAuth>
          } />
          <Route path="/movementReasons" element={
            <RequireAuth>
              <MovementReasonsPage />
            </RequireAuth>
          } />
          <Route path="/reconcile" element={
            <RequireAuth>
              <ReconcileBin />
            </RequireAuth>
          } />
          <Route path="/reports" element={
            <RequireAuth>
              <ReportsPage />
            </RequireAuth>
          } />
          <Route path="/parts" element={
            <RequireAuth>
              <PartPage />
            </RequireAuth>
          } />
          <Route path="/partCategories" element={
            <RequireAuth>
              <PartCategoriesPage />
            </RequireAuth>
          } />
          <Route path="/partAttributes" element={
            <RequireAuth>
              <PartAttributesPage />
            </RequireAuth>
          } />
          <Route path="/users" element={
            <RequireAuth>
              <UsersPage />
            </RequireAuth>
          } />
          <Route path="/warehouses" element={
            <RequireAuth>
              <WarehousePage />
            </RequireAuth>
          } />
          <Route path="/*" element={<NotFoundPage />} />
        </Route>
      </Routes>
  );
}

function PageLayout() {
  const { isAuthenticated, user } = useAuth0();
  
  const showNav = isAuthenticated && user && user[`${config.AUTH0_CLAIM_NAMESPACE}/organization_id`];

  return <div className="App">
    <section className="uk-section uk-padding-remove-top uk-padding-remove-bottom">
      <PageHeader />
      { showNav && <PrimaryNav /> }
    </section>
    <section className="uk-section uk-padding-remove-top uk-padding-remove-bottom">
      <Outlet />
    </section>
  </div >
}

function ContentContainer(props: RouteProps) {
  return <div className="uk-container">
  </div>
}

function IndexRoute() {
  const { isAuthenticated, user, isLoading } = useAuth0();

  if (isLoading) {
    return <Spinner />
  }
  
  /*
  const [ userStatus, setUserStatus] = useState();
  useEffect( () => {
    if (isAuthenticated && user) {
      const accessToken = '123456';
      API.get(accessToken, `/api/v1/user/${user.sub}`)
      .then( result => {
        const userData = result.data;
        if (userData.organizationId) {
          // everything is good to go...
          
        } else {
          // see if there's an invite, if so ask if they want to join or 
          // start their own org
          if (userData.role == 'none') {
            navigate('/NewUser');
          }
          API.get(accessToken, `/api/v1/user/invitation/${user.email}`)
          .then ( result => {
            
          })
        }
        
      })
    }

  }, [isAuthenticated, user]);
  */
  
  const isNewUser = true;

  // Note: in-process registration handled prior to routing
  if (isAuthenticated) {
    if (user && user.roles_assigned && user[`${config.AUTH0_CLAIM_NAMESPACE}/organization_id`] > 0) {
      return <div className="uk-container"><Overview /></div>
    } else {
      return <NewUser />
    }
  }
  return <Index />
}

function RequireAuth(props: RouteProps) {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <Spinner />
  }
  if (!isAuthenticated) {
    return <Navigate to="/" replace />
  }
  return <div className="uk-container uk-container-expand">{props.children}</div>;
}

////////////////////////////////
////////////////////////////////

interface INavItemProps {
  name: string;
  path: string;
  children?: JSX.Element;
}

const NavItem = ({ name, path, children }: INavItemProps) => (
  <li className={'uk-parent' + (window.location.pathname === path && ' uk-active')}>
    <NavLink to={path} className={({ isActive }) => isActive ? "uk-active" : undefined}>{name}</NavLink>
  </li>
)


const NotFoundPage = () => {
  return <div className="uk-container">
    <h2>Hmm.... </h2>
    <p>
      We know where a lot of things are, but not whatever you just tried to find.  Try the <Link to="/">Dashboard</Link>.
      <img src={Hedgehog} />
    </p>

  </div>
}