import React  from 'react';
import { Logout } from 'src/components/Logout';
import { SesameLogin } from 'src/components/SesameLogin';

import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from 'src/components/Spinner';

const UserProfile = (props: React.HTMLAttributes<HTMLElement>) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  if (isLoading) {
    return <Spinner />
  }

  return (
    <SesameLogin>
      {isAuthenticated && <div>
        <a >{user?.nickname ?? user?.name}</a>
        <ul id="profileList" className="uk-background-secondary uk-light uk-list" uk-dropdown="mode: click">
          <li><img src={user?.picture} alt={user?.name} /></li>
          <li>
            <Logout />
          </li>
        </ul>
      </div>
      }
      {!isAuthenticated && <div>Not logged in</div>}
    </SesameLogin>
  );
};

export default UserProfile;
