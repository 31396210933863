import React, { useEffect, useState, useRef, Fragment } from 'react';
import Downshift from 'downshift';

import { IPart } from 'src/types';

import { Parts } from '../../Parts/Parts';
import { useAuth0 } from '@auth0/auth0-react';


interface ISelectPartProps {
    part?: IPart;
    id?: string;
    onSet: (part?: IPart) => void;
    disabled?: boolean;
}

export const SelectPart = ({ part, onSet, id, disabled=false }: ISelectPartProps) => {

    const {isLoading, isAuthenticated, getAccessTokenSilently} = useAuth0();
    const [parts, setParts] = useState<IPart[]>([]);

    const onPartChange = (selection?: IPart) => {
        onSet(selection);
    }

    const getParts = async () => {
        setParts(await Parts.Instance().get(getAccessTokenSilently()));
    }

    useEffect(() => {
        getParts();
    }, [isLoading, isAuthenticated]);

    return <Downshift
        onChange={ (selection) => onPartChange(selection ? selection : undefined)}
        itemToString={ (item) =>
            (item ? `${item.partNumber}:${item.revisionNumber}`
            : '')}
        selectedItem={part}
    >
        {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            isOpen,
            inputValue,
            highlightedIndex,
            selectedItem,
            getRootProps,
        }) => (
            <div>
                <label {...getLabelProps()}>Part Number</label>
                <br />
                <div
                    style={{ display: 'inline-block' }}
                    {...getRootProps({}, { suppressRefError: true })}
                >
                    <input {...getInputProps()} id={id} disabled={disabled} />
                </div>
                <ul className="autocomplete-list" {...getMenuProps()}>
                    {isOpen ? parts
                        .filter(part => !inputValue || `${part.partNumber} ${part.name}`.toLowerCase().includes(inputValue.toLowerCase()))
                        .map((item, index) => (
                            <li
                                {...getItemProps({
                                    key: item.partId,
                                    index,
                                    item,
                                    style: {
                                        backgroundColor:
                                            highlightedIndex === index ? 'lightgray' : 'white',
                                        fontWeight: selectedItem === item ? 'bold' : 'normal',
                                    },
                                })}
                            >
                                {item.partNumber}:{item.revisionNumber} {item.name}
                            </li>
                        ))
                        : null}
                </ul>
            </div>
        )}
    </Downshift>
}
